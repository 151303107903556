import React from 'react';
import { func, string } from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Button,
  SectionHeader,
  Gutter,
  Grid,
  GridRow,
  GridColumn,
} from '@axiom/ui';

import { PreloadedUserStore } from '../../stores/preloaded-user-store';

export const UserAccountModal = ({ onClose, user, userName }) => {
  return (
    <Modal name="MYACCOUNTMODAL" size="large">
      <ModalHeader onClose={onClose}>My Account</ModalHeader>
      <ModalSection>
        <SectionHeader>{`${userName}`}</SectionHeader>
        <Gutter bottom="16px" />
        <Grid>
          <GridRow gutterBottom="8px">
            <GridColumn width={4}>User Type</GridColumn>
            <GridColumn width={6}>{user.roles.join(', ')}</GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={4}>User ID</GridColumn>
            <GridColumn width={6}>{user.id}</GridColumn>
          </GridRow>
        </Grid>
      </ModalSection>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

UserAccountModal.defaultProps = {
  onClose: () => {},
  userName: '',
};

UserAccountModal.propTypes = {
  user: PreloadedUserStore.getDataShape().isRequired,
  userName: string,
  onClose: func,
};

export default UserAccountModal;
