import React from 'react';
import { string } from 'prop-types';
import { Gutter, Header3, NoteIcon, Text } from '@axiom/ui';

import { formatDataTestId } from '../../utils/data-test-util';

import { EmptyDescriptionWrapper } from './EmptyDescriptionStyles';

const EmptyDescription = ({ header, body }) => (
  <EmptyDescriptionWrapper data-test={formatDataTestId('EmptyDescription')}>
    <NoteIcon />
    <Gutter top="0.5rem" bottom="0.25rem">
      <Header3>{header}</Header3>
    </Gutter>
    <Text>{body}</Text>
  </EmptyDescriptionWrapper>
);

EmptyDescription.propTypes = {
  body: string,
  header: string,
};

EmptyDescription.defaultProps = {
  header: 'No Description',
  body: 'Click the edit icon to enter a description',
};

export default EmptyDescription;
