import { Api } from '@axiom/ui';
import {
  Candidate,
  GroupedExperiences,
  ProfileStatusValues,
} from '@axiom/validation';
import { z } from 'zod';

import { EnvUtil } from '../utils/env-util';
import { CandidateFiltersForm } from '../schema/candidate-filters-schema';

export const CandidatesFilters = z.object({
  profileStatus: z.array(z.enum(ProfileStatusValues)).nullish(),
  page: z.number().nullish(),
  sort: z.string().nullish(),
});

export type readCandidatesResponseType = {
  data: Array<Candidate>;
  meta: {
    resultCount: number;
    currentPage: number;
    pageCount: number;
  };
};

class CandidateApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  readCandidates(
    filterMeta: {
      filters?: CandidateFiltersForm;
      sort?: string;
      page?: number;
    } = {
      page: 1,
    }
  ) {
    return super.read<readCandidatesResponseType>({
      endpoint: '/candidates/search',
      method: 'POST',
      body: { ...filterMeta, search: filterMeta.filters?.search },
    });
  }

  readCandidateGroupedExperiences(
    candidateId: Candidate['id'],
    query?: { isAnonymous: boolean }
  ) {
    return super.read<{ data: GroupedExperiences[] }>({
      endpoint: `/candidates/${candidateId}/groupedExperiences`,
      query,
      method: 'GET',
    });
  }

  refreshCandidates() {
    return super.invalidate('/candidates/search');
  }

  refreshCandidate(candidateId: Candidate['id']) {
    return super.invalidate(`/candidates/${candidateId}`);
  }
}

export const CandidateApi = new CandidateApiClass();
