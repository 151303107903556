import {
  TALENT_LOADED,
  TALENT_ERROR,
  TALENT_SAVE_ERROR,
  TALENT_SAVE_SUCCESS,
  TALENT_OPPORTUNITIES_SAVE,
  TALENT_OPPORTUNITIES_EDIT,
  TALENT_OPPORTUNITIES_EDIT_ERROR,
  TALENT_OPPORTUNITIES_EDIT_SUCCESS,
  GET_DECORATED_TALENT,
  SINGLE_TALENT_LOAD,
  SINGLE_TALENT_LOADED,
  UPDATE_TALENT,
} from '../constants';
import { BenchStore } from '../../stores/bench-store';
import { CandidateApi } from '../../api/candidate-api';

export const getDecoratedTalent = talentID => ({
  type: GET_DECORATED_TALENT,
  payload: { talentID },
});

export const talentLoaded = payload => ({
  type: TALENT_LOADED,
  payload,
});

export const talentError = payload => ({
  type: TALENT_ERROR,
  error: true,
  payload,
});

export const talentSaveSuccess = payload => ({
  type: TALENT_SAVE_SUCCESS,
  payload,
});

export const talentSaveError = payload => ({
  type: TALENT_SAVE_ERROR,
  error: true,
  payload,
});

export const talentOpportunitiesSave = payload => ({
  type: TALENT_OPPORTUNITIES_SAVE,
  payload,
});

export const talentOpportunitiesEdit = payload => ({
  type: TALENT_OPPORTUNITIES_EDIT,
  payload,
});

export const talentOpportunitiesEditSuccess = payload => ({
  type: TALENT_OPPORTUNITIES_EDIT_SUCCESS,
  payload,
});

export const talentOpportunitiesEditError = payload => ({
  type: TALENT_OPPORTUNITIES_EDIT_ERROR,
  error: true,
  payload,
});

export const getSingleTalent = payload => ({
  type: SINGLE_TALENT_LOAD,
  payload,
});

export const singleTalentLoaded = payload => ({
  type: SINGLE_TALENT_LOADED,
  payload,
});

export const setTalentIsFlagged =
  ({ candidateId, isFlagged, page }) =>
  async dispatch => {
    // using setTimeout since the grid takes a moment to update because of ElasticSearch.
    setTimeout(() => {
      if (page.includes('/talent/')) {
        CandidateApi.refreshCandidates();
      }
      if (page.includes('/bench/')) {
        BenchStore.loadByConfig();
      }
    }, 2000);
    dispatch({
      type: UPDATE_TALENT,
      payload: { candidateId, props: { isFlagged } },
    });
  };
