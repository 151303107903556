export const selectifyObject = (options, sort) => {
  const selectOptions = Array.isArray(options)
    ? options.map(key => ({
        label: key,
        value: key,
      }))
    : Object.keys(options).map(key => ({
        label: options[key],
        value: key,
      }));
  return typeof sort === 'function' ? selectOptions.sort(sort) : selectOptions;
};

const selectifyUtil = ({ groupLabel, group, options, sort }) => {
  const selectOptions = Array.isArray(options)
    ? options.map(key => ({
        group,
        groupLabel,
        label: key,
        value: key,
      }))
    : Object.keys(options).map(key => ({
        group,
        groupLabel,
        label: options[key],
        value: key,
      }));
  return typeof sort === 'function' ? selectOptions.sort(sort) : selectOptions;
};

export default selectifyUtil;
