import React, { FC } from 'react';
import {
  AccordionsItem,
  AccordionsItemHeader,
  AccordionsItemSection,
  Badge,
  Card,
  CardHeader,
  CardSection,
  Layout,
  SmallHeader,
} from '@axiom/ui';
import { Opportunity, OpportunityCandidate } from '@axiom/validation';

import { OpportunityTalentParentPositionList } from './OpportunityTalentParentPositionList';
import { OpportunityTalentParentPositionEmpty } from './OpportunityTalentParentPositionEmpty';
import { OTScrollable } from './OpportunityTalentParentPositionStyles';

interface OpportunityTalentParentRemovedTalentProps {
  opportunity: Opportunity;
  candidates: Array<OpportunityCandidate>;
}

export const OpportunityTalentParentRemovedTalent: FC<
  OpportunityTalentParentRemovedTalentProps
> = ({ opportunity, candidates }) => {
  return (
    <AccordionsItem name="removed-talent">
      <Card>
        <CardHeader>
          <AccordionsItemHeader>
            <Layout
              direction="horizontal"
              horizontalGutter="8px"
              position="middle"
            >
              <Badge name="removed-talent-count" background="gray">
                {candidates.length}
              </Badge>
              <SmallHeader maxLines={1}>Removed Talent</SmallHeader>
            </Layout>
          </AccordionsItemHeader>
        </CardHeader>
        <OTScrollable>
          <AccordionsItemSection>
            <CardSection>
              {candidates.length > 0 ? (
                <OpportunityTalentParentPositionList
                  opportunity={opportunity}
                  candidates={candidates}
                />
              ) : (
                <OpportunityTalentParentPositionEmpty isRemovedTalent />
              )}
            </CardSection>
          </AccordionsItemSection>
        </OTScrollable>
      </Card>
    </AccordionsItem>
  );
};
