import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Text } from '@axiom/ui';

import UserManagementList from '../UserManagementList/UserManagementList';
import UserManagementForm from '../UserManagementForm/UserManagementForm';
import { FormUserManagementStore } from '../../stores/form-user-management-store';
import { DropdownOptionsShape } from '../../models/dropdown-options';
import {
  USER_FIELD_IS_ACTIVE,
  USER_FIELD_ROLES,
} from '../../models/user-fields-const';
import { USER_TYPE_USER } from '../../models/user';
import {
  groupsByUserIdSelector,
  groupsDropdownOptionsSelector,
} from '../../redux/selectors/groups';
import { usersSelectorActiveEnvoyUsers } from '../../redux/selectors/users';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { formatDataTestId } from '../../utils/data-test-util';

import { Root, List, Form, Empty } from './AdminModalGroupManagementUserStyles';

// takes data and makes it ready for the form. Shouldn't really be necessary or should happen in form.
const transformSelected = (selected, groupsByUserId) => {
  const formDefaultValues = {
    [USER_FIELD_IS_ACTIVE]: true,
    [USER_FIELD_ROLES]: USER_TYPE_USER,
    team: null,
  };

  return {
    ...formDefaultValues,
    ...selected,
    ...(Array.isArray(selected.roles) ? { roles: selected.roles[0] } : {}),
    team: (groupsByUserId[selected.id] || []).map(group => group.id),
  };
};

export class UserManagementViewComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
    };
  }

  handleSelectedChange = (selected = null) => {
    this.setState({ selected });
    if (selected) {
      FormUserManagementStore.load(
        transformSelected(selected, this.props.groupsByUserId)
      );
    }
  };

  render() {
    const { groupOptions, users } = this.props;
    const { selected } = this.state;

    return (
      <Root data-test={formatDataTestId('user management')}>
        <List>
          <UserManagementList
            onSelectedChange={this.handleSelectedChange}
            selected={selected}
            users={users}
          />
        </List>
        <Form>
          {selected ? (
            <UserManagementForm groupOptions={groupOptions} user={selected} />
          ) : (
            <Empty>
              <Text>Select a search result.</Text>
            </Empty>
          )}
        </Form>
      </Root>
    );
  }
}

UserManagementViewComponent.defaultProps = {
  groupsByUserId: {},
  groupOptions: [],
  users: [],
};

UserManagementViewComponent.propTypes = {
  groupOptions: DropdownOptionsShape,
  groupsByUserId: PropTypes.shape({}),
  users: PropTypes.arrayOf(PreloadedUserStore.getDataShape()),
};

const mapStateToProps = createStructuredSelector({
  groupsByUserId: groupsByUserIdSelector,
  groupOptions: groupsDropdownOptionsSelector,
  users: usersSelectorActiveEnvoyUsers,
});

export default connect(mapStateToProps, null)(UserManagementViewComponent);
