import React from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { ContextMenu, ContextMenuItem, IconButton } from '@axiom/ui';

import { PreloadedFeatureFlagsStore } from '../../stores/preloaded-feature-flags-store';

const OpportunityProfilePositionContextMenuComponent = ({
  showEditNameOverlay,
  showDeletePositionModal,
  isFulfillmentActive,
  featureFlags,
}) => {
  return (
    <ContextMenu
      name="POSITION_CONTEXT_MENU"
      direction="left"
      anchor={
        <IconButton
          icon="vertical-ellipsis"
          inverse
          pattern="secondary"
          variation="minimal"
        />
      }
    >
      <ContextMenuItem
        name="edit-name"
        onClick={() => {
          showEditNameOverlay();
        }}
      >
        Edit Position Name
      </ContextMenuItem>
      {PreloadedFeatureFlagsStore.isDeletePositionEnabled(featureFlags) && (
        <ContextMenuItem
          name="delete-position"
          onClick={() => {
            showDeletePositionModal();
          }}
          disabled={!isFulfillmentActive}
        >
          Delete
        </ContextMenuItem>
      )}
    </ContextMenu>
  );
};

OpportunityProfilePositionContextMenuComponent.propTypes = {
  featureFlags: PreloadedFeatureFlagsStore.getDataShape().isRequired,
  isFulfillmentActive: bool.isRequired,
  showDeletePositionModal: func,
  showEditNameOverlay: func,
};

OpportunityProfilePositionContextMenuComponent.defaultProps = {
  showEditNameOverlay: () => {},
  showDeletePositionModal: () => {},
};

export const OpportunityProfilePositionContextMenu = connect(state => ({
  featureFlags: PreloadedFeatureFlagsStore.select(state),
}))(OpportunityProfilePositionContextMenuComponent);
