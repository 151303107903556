import { SearchDto } from '@axiom/ui';

import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('search');

export const getCandidateSearchesByUserId = async userId => {
  const response = await api.GET(`/users/${userId}/searches`, {
    type: 'candidate',
  });
  return response.data;
};

export const getOpportunitySearchesByUserId = async userId => {
  const response = await api.GET(`/users/${userId}/searches`, {
    type: 'opportunity',
  });
  return response.data;
};

export const saveSearch = async (
  userId,
  formValues = { id: null, name: null, search: null, type: null }
) => {
  const method = formValues.id ? 'PATCH' : 'POST';
  const endpoint = formValues.id
    ? `/users/${userId}/searches/${formValues.id}`
    : `/users/${userId}/searches`;

  const requestBody = new SearchDto(formValues);
  const { data } = await api[method](endpoint, requestBody.export());
  return data;
};

export default {
  saveSearch,
};
