import React from 'react';
import { string, node, arrayOf, oneOfType, bool, element } from 'prop-types';
import { Text } from '@axiom/ui';

import { formatDataTestId } from '../../utils/data-test-util';

import {
  UnorderedListWrapper,
  ULAftIcon,
  ULTitle,
  List,
} from './UnorderedListStyles';

const PLACEHOLDER = '--';

const getMixedValue = values =>
  (typeof values === 'string' && values.trim().length > 0) ||
  typeof values === 'number' ||
  React.isValidElement(values)
    ? values
    : PLACEHOLDER;

const UnorderedList = ({
  showAftIcon,
  dataValue,
  values,
  title,
  className,
  showBullets,
  footNote,
  dataTestId,
  candidateStatus,
}) => (
  <UnorderedListWrapper className={className}>
    <ULTitle candidateStatus={candidateStatus}>
      {title}
      {!!showAftIcon && <ULAftIcon />}
    </ULTitle>
    <List showBullets={showBullets}>
      {Array.isArray(values) && values.length > 0 ? (
        values.map((value, index) => (
          <li
            key={`${value}.${index.toString()}`}
            data-test={formatDataTestId(dataTestId || title || 'display value')}
            data-value={dataValue}
          >
            {getMixedValue(value)}
          </li>
        ))
      ) : (
        <li
          data-test={formatDataTestId(dataTestId || title || 'display value')}
          data-value={dataValue}
        >
          {getMixedValue(values)}
        </li>
      )}
    </List>
    {!!footNote && (
      <Text size="small" name="footer note" className="font-style-italic">
        {footNote}
      </Text>
    )}
  </UnorderedListWrapper>
);

UnorderedList.defaultProps = {
  showAftIcon: false,
  className: null,
  showBullets: false,
  footNote: null,
  values: undefined,
  dataValue: null,
  dataTestId: null,
  candidateStatus: undefined,
};

UnorderedList.propTypes = {
  candidateStatus: string,
  className: string,
  dataTestId: string,
  dataValue: string,
  footNote: string,
  showAftIcon: bool,
  showBullets: bool,
  title: oneOfType([string, element]).isRequired,
  values: oneOfType([arrayOf(node), node]),
};

export default UnorderedList;
