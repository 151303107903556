import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('collaborator');

export const getCollaboratorsByOpportunityId = async (
  opportunityId,
  body = { only: 'collaborator' }
) => {
  const response = await api.GET(`/opportunities/${opportunityId}/users`, body);
  return {
    data: response.data,
    id: opportunityId,
  };
};

export const putCollaboratorsByOpportunityId = async (
  opportunityId,
  collaborators
) => {
  const response = await api.PUT(
    `/opportunities/${opportunityId}/users`,
    collaborators
  );
  return {
    opportunityId: response.data,
    id: opportunityId,
  };
};

export default {
  getCollaboratorsByOpportunityId,
  putCollaboratorsByOpportunityId,
};
