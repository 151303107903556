import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('education');

export const createEducation = async (candidateId, body) => {
  const { data } = await api.POST(`/candidates/${candidateId}/degrees`, body);
  return { data };
};

export const deleteEducation = async educationId => {
  const { data } = await api.DELETE(`/education/${educationId}`);
  return { data };
};

export const updateEducation = async (educationId, body) => {
  const { data } = await api.PATCH(`/education/${educationId}`, body);
  return { data };
};
