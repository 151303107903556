import React from 'react';
import { Grid, GridColumn, GridRow, Gutter } from '@axiom/ui';

import DefaultLayout from '../layouts/default';
import { WorkspaceTabs } from '../components/Workspace/WorkspaceTabs';
import { Page } from '../components/Page/Page';
import { WorkspaceTopTasks } from '../components/Workspace/WorkspaceTopTasks';

export const WorkspacePage = () => {
  return (
    <Page>
      <DefaultLayout>
        <Grid columns={12} stretched>
          <GridRow>
            <GridColumn
              widescreenWidth={2}
              desktopWidth={3}
              smallScreenWidth={3}
            >
              <WorkspaceTopTasks />
            </GridColumn>
            <GridColumn
              widescreenWidth={10}
              desktopWidth={9}
              smallScreenWidth={9}
            >
              <WorkspaceTabs />
            </GridColumn>
          </GridRow>
        </Grid>
        <Gutter bottom="24px" />
      </DefaultLayout>
    </Page>
  );
};
