import url from 'url';

import qs from 'qs';
import {
  getFormValues,
  startSubmit,
  setSubmitSucceeded,
  setSubmitFailed,
  stopSubmit,
} from 'redux-form';
import { call, put, takeLatest, select, delay } from 'redux-saga/effects';

import { window } from '../../utils/global-util';
import { saveSearch } from '../../api/searches-api';
import { BENCH_FORM } from '../../api/constants';
import { SEARCHES_SAVE, SEARCHES_SUBMIT } from '../constants';
import { saveSearchSuccess, saveSearchError } from '../actions/searches';
import { addApiError } from '../actions/app';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';

import { getUserTalentWithFiltersSaga } from './user-talent';

export function* searchesSave(form) {
  try {
    const formValues = yield select(getFormValues(form));
    const userId = yield select(state => PreloadedUserStore.select(state).id);
    const searches = yield call(saveSearch, userId, formValues);
    yield put(saveSearchSuccess({ searches, type: form.type }));
  } catch (e) {
    yield put(saveSearchError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* searchesSubmit(action) {
  const {
    payload: { form, shouldResetPageCount, shouldDebounce },
  } = action;

  try {
    if (shouldDebounce) yield delay(500);

    yield put(startSubmit(form));

    if (form === BENCH_FORM) {
      yield call(getUserTalentWithFiltersSaga, null, shouldResetPageCount);
    }

    yield put(setSubmitSucceeded(form));
    yield put(stopSubmit(form));

    const { search, filters = {} } = yield select(getFormValues(form));
    const basepath = url.parse(window.location.href).pathname;
    const paramString = qs.stringify({ search, filters });
    const newpath =
      paramString.length > 0 ? `${basepath}?${paramString}` : basepath;

    window.history.pushState({}, '', newpath);
  } catch (e) {
    const fields = e.applicationError ? Object.keys(e.applicationError) : [];
    yield put(setSubmitFailed(form, ...fields));
    yield put(stopSubmit(form, e.applicationError));
    yield put(addApiError(e.applicationError));
  }
}

function* searchesSaga() {
  yield takeLatest(SEARCHES_SAVE, searchesSave);
  yield takeLatest(SEARCHES_SUBMIT, searchesSubmit);
}

export default searchesSaga;
