import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  GET_DECORATED_OPPORTUNITY,
  DECORATED_OPPORTUNITY_LOADED,
  DECORATED_OPPORTUNITY_ERROR,
} from '../constants';
import {
  getJob as getOpportunity,
  getRelatedJobs as getRelatedOpportunities,
} from '../../api/jobs-api';
import { getActivitiesByJobId as getActivitiesByOpportunityId } from '../../api/job-activities-api';
import { getCandidatesByJobId as getCandidatesByOpportunityId } from '../../api/opportunity-talent-api';
import { getCollaboratorsByOpportunityId } from '../../api/opportunity-collaborators-api';
import { getLanguagesByOpportunityId } from '../../api/opportunity-languages-api';
import { addApiError } from '../actions/app';
import { relatedOpportunitiesLoaded } from '../actions/opportunities';
import { OpportunityStore } from '../../stores/opportunity-store';

export function* decorateOpportunitySaga({ payload: { opportunityId } }) {
  try {
    OpportunityStore.load(opportunityId);
    const [
      opportunity,
      activities,
      candidates,
      collaborators,
      languages,
      relations,
    ] = yield all([
      call(getOpportunity, opportunityId),
      call(getActivitiesByOpportunityId, opportunityId),
      call(getCandidatesByOpportunityId, opportunityId),
      call(getCollaboratorsByOpportunityId, opportunityId),
      call(getLanguagesByOpportunityId, opportunityId),
      call(getRelatedOpportunities, opportunityId),
    ]);
    yield put({
      type: DECORATED_OPPORTUNITY_LOADED,
      payload: {
        opportunityId,
        opportunity,
        activities,
        candidates,
        collaborators,
        languages,
      },
    });

    yield put(relatedOpportunitiesLoaded(relations));
  } catch (e) {
    const { applicationError } = e;

    yield put(addApiError(applicationError));

    yield put({ type: DECORATED_OPPORTUNITY_ERROR });
  }
}

function* opportunityDecorationSaga() {
  yield takeEvery(GET_DECORATED_OPPORTUNITY, decorateOpportunitySaga);
}

export default opportunityDecorationSaga;
