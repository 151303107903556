import styled from 'styled-components';

import { getThemeProp } from '../../themes';
import { GENERAL_STYLES_MAP } from '../../themes/constants';
// eslint-disable-next-line boundaries/element-types
import { DynamicScrollingItem as UMLDynamicScrollingItem } from '../UserManagementList/UserManagementListStyles';

export const AreasOfStudyManagementListDynamicScrollingItem = styled(
  UMLDynamicScrollingItem
)`
  display: flex;
  max-height: 32em;
  overflow: hidden;

  ul {
    width: 100%;
    max-height: 24em;
    margin-right: 0;
    overflow-y: auto;
  }
`;

export const AreasOfStudyManagementListNewAreaOfStudyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export {
  Root as AreasOfStudyManagementListRoot,
  StaticItem as AreasOfStudyManagementListStaticItem,
  UserList as AOSList,
  UserListItem as AreasOfStudyManagementListItem,
} from '../UserManagementList/UserManagementListStyles';

export const AreasOfStudyManagementFormFormHeader = styled.div`
  background: ${getThemeProp('appBackground')};
  border-bottom: 1px solid ${GENERAL_STYLES_MAP.DISABLED.background};
  margin: 0;
  padding: 24px 32px;
`;
