import React from 'react';
import { Grid, GridColumn, GridRow, Gutter, Header4 } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import UnorderedList from '../UnorderedList/UnorderedList';
import { DateUtil } from '../../utils/date-util';

export const TalentProfileDetailAvailability = ({
  candidate,
}: {
  candidate: Candidate;
}) => {
  return (
    <>
      <Gutter bottom="16px">
        <Header4 name="Availability_Preferences">
          Work and Availability Preferences
        </Header4>
      </Gutter>
      <Grid columns={2}>
        <GridRow gutterBottom="16px">
          <GridColumn>
            <UnorderedList
              title="Days Since Last Update"
              values={
                (candidate.availabilityPreferencesUpdatedAt ?? null) === null
                  ? null
                  : DateUtil.getDaysSinceNow(
                      candidate.availabilityPreferencesUpdatedAt
                    )
              }
            />
          </GridColumn>
          <GridColumn>
            <UnorderedList
              title="Days In Profile Status"
              values={candidate.daysSinceStatusChange}
            />
          </GridColumn>
        </GridRow>
        <GridRow gutterBottom="16px">
          <GridColumn>
            <UnorderedList
              title="Preferred Weekly Hours"
              values={
                !!(candidate.desiredWeeklyMaxHours || candidate.maxHours) && (
                  <div className="text-align-middle">
                    {candidate.desiredWeeklyMaxHours ?? candidate.maxHours} hrs
                  </div>
                )
              }
            />
          </GridColumn>
          <GridColumn>
            <UnorderedList
              title="Required Minimum Weekly Hours"
              values={
                <div className="text-align-middle">
                  {candidate.minWeeklyHoursPerPosition === null
                    ? '--'
                    : `${candidate.minWeeklyHoursPerPosition} ${
                        candidate.minWeeklyHoursPerPosition === 1 ? 'hr' : 'hrs'
                      }`}
                </div>
              }
            />
          </GridColumn>
        </GridRow>
        <GridRow gutterBottom="16px">
          <GridColumn>
            <UnorderedList
              title="Additional Client"
              values={candidate.isOpenToMultipleClients ? 'Yes' : 'No'}
            />
          </GridColumn>
          <GridColumn>
            <UnorderedList
              title="Other Opportunities"
              values={candidate.isOpenToOtherWork ? 'Yes' : 'No'}
            />
          </GridColumn>
        </GridRow>
        <GridRow gutterBottom="16px">
          <GridColumn>
            <UnorderedList
              title="Notice Period"
              values={candidate.noticePeriod}
            />
          </GridColumn>
        </GridRow>
        <GridRow gutterBottom="16px">
          <GridColumn width={12}>
            <UnorderedList
              title="Work Preferences"
              values={[
                candidate.openToSecondment ? 'Open to Secondments' : null,
                candidate.openToAdviceAndCounsel
                  ? 'Open to Axiom Advice & Counsel opportunities'
                  : null,
                candidate.openToVariableHourlyProjects
                  ? 'Open to Variable Hourly opportunities'
                  : null,
                candidate.openToLargeProjects
                  ? 'Open to Large Projects opportunities'
                  : null,
                candidate.openToPermanentPlacement
                  ? 'Open to Permanent Placement'
                  : null,
              ]
                .filter(preference => preference !== null)
                .join(', ')}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </>
  );
};
