import React from 'react';
import { number } from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CenteredSpinner } from '../Spinner/Spinner';
import NoContentPlaceholder from '../NoContentPlaceholder/NoContentPlaceholder';
import EngagementsItemPlaceholder from '../EngagementsItemPlaceholderIcon/EngagementsItemPlaceholderIcon';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { opportunityShape } from '../../models/opportunities-const';
import { EngagementsUtils } from '../../utils/engagements-util';
import { window } from '../../utils/global-util';
import { OpportunityEngagementsStore } from '../../stores/opportunity-engagements-store';
import { formatDataTestId } from '../../utils/data-test-util';
import { WindowUtil } from '../../utils/window-util';

import OpportunityEngagementsItem from './OpportunityEngagementsItem';
import { OEWrapper } from './OpportunityEngagementsStyles';

const OpportunityEngagements = props => {
  const navigate = useNavigate();
  const viewOpportunity = id => {
    const route = window.location.pathname.includes('/opportunities/')
      ? WindowUtil.appendQueryParams(`/opportunities/${id}`)
      : `/opportunity-detail/${id}`;

    navigate(route);
  };

  const { engagementsCount, opportunity, opportunityEngagementsState } = props;

  if (!opportunity) return <CenteredSpinner />;

  return (
    <StoreStateTreatment
      storeState={opportunityEngagementsState}
      renderLoadedView={({ data: related }) => (
        <OEWrapper data-test={formatDataTestId('opportunityEngagements')}>
          {engagementsCount > 0 ? (
            EngagementsUtils.buildEngagements(opportunity, related).map(
              engagement =>
                engagement.candidates.map(candidate => (
                  <OpportunityEngagementsItem
                    candidate={candidate}
                    opportunity={engagement}
                    key={candidate.id}
                    onClick={viewOpportunity}
                  />
                ))
            )
          ) : (
            <NoContentPlaceholder
              image={<EngagementsItemPlaceholder />}
              title="No Engagements"
              text={null}
            />
          )}
        </OEWrapper>
      )}
    />
  );
};

OpportunityEngagements.defaultProps = {
  opportunity: null,
};

OpportunityEngagements.propTypes = {
  engagementsCount: number.isRequired,
  opportunity: opportunityShape,
  opportunityEngagementsState:
    OpportunityEngagementsStore.getStateShape().isRequired,
};

const mapStateToProps = state => ({
  opportunityEngagementsState: OpportunityEngagementsStore.select(state),
});

export default connect(mapStateToProps)(OpportunityEngagements);
