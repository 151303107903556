import { EnvUtil } from '../utils/env-util';
import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('user opportunity');

export const getOpportunitiesByUserId = async sendMeta => {
  const { userId, ...rest } = sendMeta;
  const { data, _meta } = await api.POST(
    `${EnvUtil.apiEnvoyUrl}/users/${userId}/opportunities/search`,
    rest
  );
  return {
    data,
    meta: _meta,
  };
};
