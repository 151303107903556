import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  GET_DECORATED_TALENT,
  DECORATED_TALENT_LOADED,
  DECORATED_TALENT_ERROR,
} from '../constants';
import { getTalent } from '../../api/talent-api';
import { getActivitiesByTalentId } from '../../api/talent-activities-api';
import { getLanguagesByTalentId } from '../../api/talent-languages-api';
import { getOpportunitiesByTalentId } from '../../api/talent-opportunities-api';
import { getTagsByTalentId } from '../../api/talent-tags-api';
import { getBarredLocationsByTalentId } from '../../api/talent-barred-locations-api';
import { addApiError } from '../actions/app';
import { EventsStore, EventSubjectConst } from '../../stores/events-store';
import { CandidateStore } from '../../stores/candidate-store';
import { CandidateOpportunitiesStore } from '../../stores/candidate-opportunities-store';
import { CandidateCertificationsStore } from '../../stores/candidate-certifications-store';
import { CandidateCollaboratorsStore } from '../../stores/candidate-collaborators-store';
import { FormTalentAvailabilityNotesStore } from '../../stores/form-talent-availability-notes';
import { FormTalentProfileAvailabilityEditStore } from '../../stores/form-talent-profile-availability-edit-store';

export function* decorateTalentSaga({ payload: { talentID } }) {
  try {
    const [
      talent,
      activities,
      languages,
      opportunities,
      tags,
      barredLocations,
    ] = yield all([
      call(getTalent, [talentID]),
      call(getActivitiesByTalentId, [talentID]),
      call(getLanguagesByTalentId, talentID),
      call(getOpportunitiesByTalentId, [talentID]),
      call(getTagsByTalentId, talentID),
      call(getBarredLocationsByTalentId, talentID),
    ]);

    yield put({
      type: DECORATED_TALENT_LOADED,
      payload: {
        talentID,
        talent,
        activities,
        languages,
        opportunities,
        tags,
        barredLocations,
      },
    });

    EventsStore.load(EventSubjectConst.Candidate, talentID);
    CandidateStore.load(talent);
    FormTalentAvailabilityNotesStore.load(talent.data);
    FormTalentProfileAvailabilityEditStore.load(talent.data);
    CandidateCollaboratorsStore.load(talent.data);
    CandidateOpportunitiesStore.load(talent.data);
    CandidateCertificationsStore.load(
      talent.data ? talent.data.certifications : []
    );
  } catch (e) {
    const { applicationError } = e;

    yield put(addApiError(applicationError));

    yield put({ type: DECORATED_TALENT_ERROR });
  }
}

function* formsSaga() {
  yield takeEvery(GET_DECORATED_TALENT, decorateTalentSaga);
}

export default formsSaga;
