import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';

import { ContactStore } from '../../stores/contact-store';
import { EnvUtil } from '../../utils/env-util';

import { ManageContactFormContextMenuWrapper } from './ManageContactFormContextMenuStyles';

const ManageContactFormContextMenu = ({
  contactId,
  isActive,
  salesforceId,
  userId,
}) => {
  const [confirmToggleActivation, setConfirmToggleActivation] = useState(false);

  const toggleActivation = () => {
    setConfirmToggleActivation(!confirmToggleActivation);
  };

  const submitToggleActivation = () => {
    setConfirmToggleActivation(false);
    ContactStore.changeUserStatus(contactId, userId, !isActive);
  };

  return (
    <ManageContactFormContextMenuWrapper>
      <ContextMenu name="OPPORTUNITY_CONTEXT_MENU" direction="left">
        <ContextMenuItem
          onClick={() => {
            window.open(
              EnvUtil.sfdcContactUrl.replace(':salesforceId', salesforceId)
            );
          }}
          disabled={!salesforceId}
        >
          Open in SFDC
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            toggleActivation();
          }}
          disabled={!userId}
        >
          {isActive ? 'Deactivate' : 'Reactivate'}
        </ContextMenuItem>
      </ContextMenu>
      {!!confirmToggleActivation && (
        <Modal size="confirm">
          <ModalHeader onClose={toggleActivation}>
            {isActive ? 'Deactivate User' : 'Activate User'}
          </ModalHeader>
          <ModalSection>
            <Paragraph>
              Are you sure you would like to{' '}
              {isActive ? 'deactivate' : 'activate'} this user?
            </Paragraph>
          </ModalSection>
          <ModalFooter>
            <Button variation="outline" onClick={toggleActivation}>
              Cancel
            </Button>
            <Button onClick={submitToggleActivation}>
              {isActive ? 'Deactivate' : 'Activate'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ManageContactFormContextMenuWrapper>
  );
};

ManageContactFormContextMenu.defaultProps = {
  isActive: false,
  salesforceId: null,
};

ManageContactFormContextMenu.propTypes = {
  contactId: string.isRequired,
  isActive: bool,
  salesforceId: string,
  userId: string.isRequired,
};

export default ManageContactFormContextMenu;
