import {
  Button,
  DropdownTree,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Input,
  Layout,
} from '@axiom/ui';
import React from 'react';
import { z } from 'zod';

import { eventPurposeOptions } from '../../stores/app-event-item-modal-store';

type ActivityLogFilter = {
  activities?: string[];
  search?: string;
};
const options = eventPurposeOptions.map(option => option.value);
const activityLogSchema = z.object({
  activities: z.array(z.enum([options[0], ...options.slice(1)])).nullish(),
  search: z.string().nullish(),
});
export type FormSchema = z.infer<typeof activityLogSchema>;

export const ActivityLogFilter = ({
  onAddActivity,
  onFilterChange,
  showFilters,
}: {
  onAddActivity: () => void;
  onFilterChange: (values: ActivityLogFilter) => void;
  showFilters: boolean;
}) => {
  const activityDropdownOptions = [
    {
      label: 'All',
      value: 'All',
      choices: eventPurposeOptions,
    },
  ];
  const isDetailPage = (): boolean => {
    return (
      window.location.href.includes('talent-detail') ||
      window.location.href.includes('opportunity-detail')
    );
  };

  return (
    <Form
      name="activityLogFilter"
      onSubmit={(values: ActivityLogFilter) => {
        onFilterChange(values);
      }}
      submitOnChange
      schema={activityLogSchema}
    >
      {() => (
        <Gutter top="16px">
          <Layout direction="vertical">
            <Grid>
              <GridRow>
                <GridColumn width={isDetailPage() ? 4 : 6}>
                  {!!showFilters && (
                    <Input name="search" placeholder="Search" icon="search" />
                  )}
                </GridColumn>
                <GridColumn width={isDetailPage() ? 8 : 6}>
                  <Layout position="right">
                    <Button
                      name="add_activity_button"
                      pattern="secondary"
                      onClick={onAddActivity}
                    >
                      Add Activity
                    </Button>
                  </Layout>
                </GridColumn>
              </GridRow>
            </Grid>
            {!!showFilters && (
              <Gutter top="16px">
                <DropdownTree
                  displayKey="label"
                  label="Filter by Activity"
                  name="activities"
                  placeholder="Select an activity"
                  options={activityDropdownOptions}
                  valueKey="value"
                  nestedKey="choices"
                  direction="down"
                />
              </Gutter>
            )}
          </Layout>
        </Gutter>
      )}
    </Form>
  );
};
