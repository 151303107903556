import React, { Component } from 'react';
import { Certification } from '@axiom/const';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';

import { certificationShape } from '../../models/certification';
import UnorderedList from '../UnorderedList/UnorderedList';
import { formatDataTestId } from '../../utils/data-test-util';
import { FormCertificationItemStore } from '../../stores/form-certification-item-store';
import { CandidateCertificationsStore } from '../../stores/candidate-certifications-store';

import {
  CertificationItemWrapper,
  CICard,
  CICardBody,
  CICardRow,
  CICardRowLeft,
  CICardRowRight,
  CICardRowRightMenu,
} from './CertificationsItemStyles';

const qualificationAwards = Object.values(Certification.QUALIFICATION_AWARDS);

// TODO: Move into TalentUtil after AP-7289 is merged.
const isQualification = certification => {
  return qualificationAwards.includes(certification.award);
};

class CertificationsItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmingDelete: false,
    };
  }

  cancelDelete = () => {
    this.setState({ isConfirmingDelete: false });
  };

  confirmDelete = () => {
    CandidateCertificationsStore.delete(this.props.certification);
  };

  startEditing = () => {
    FormCertificationItemStore.load(this.props.certification);
  };

  render() {
    const { certification } = this.props;
    if (!certification || !certification.id) return null;

    const { isConfirmingDelete } = this.state;

    let line1 = null;
    let line2 = null;

    if (isQualification(certification)) {
      line1 = (
        <UnorderedList
          dataTestId="INSTITUTION"
          title="Institution"
          values={certification.institution}
        />
      );
      line2 = (
        <UnorderedList
          dataTestId="AWARD"
          title="Degree"
          values={certification.award}
        />
      );
    } else {
      line1 = (
        <UnorderedList
          dataTestId="AWARD"
          title="Certificate/Award Name"
          values={certification.award}
        />
      );
      line2 = (
        <UnorderedList
          dataTestId="INSTITUTION"
          title="Awarding Organization"
          values={certification.institution}
        />
      );
    }

    return (
      <CertificationItemWrapper
        data-test={formatDataTestId('certificationItem')}
      >
        <CICard>
          <CICardBody>
            <CICardRow>
              <CICardRowLeft>{line1}</CICardRowLeft>
              <CICardRowRight>
                <UnorderedList
                  dataTestId="YEAR"
                  title="Date"
                  values={certification.year}
                />
                <CICardRowRightMenu>
                  <ContextMenu direction="left">
                    <ContextMenuItem
                      onClick={() => {
                        this.startEditing();
                      }}
                    >
                      Edit
                    </ContextMenuItem>
                    <ContextMenuItem
                      onClick={() => {
                        this.setState({ isConfirmingDelete: true });
                      }}
                    >
                      Delete
                    </ContextMenuItem>
                  </ContextMenu>
                </CICardRowRightMenu>
              </CICardRowRight>
            </CICardRow>
            <CICardRow>{line2}</CICardRow>
          </CICardBody>
        </CICard>

        {isConfirmingDelete ? (
          <Modal size="confirm">
            <ModalHeader onClose={this.cancelDelete}>
              Delete Qualifications, Certifications, & Awards
            </ModalHeader>
            <ModalSection>
              <Paragraph>
                Are you sure you want to delete "{certification.award}" from "
                {certification.institution}"?
              </Paragraph>
            </ModalSection>
            <ModalFooter>
              <Button variation="outline" onClick={this.cancelDelete}>
                Cancel
              </Button>
              <Button onClick={this.confirmDelete}>Delete</Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </CertificationItemWrapper>
    );
  }
}

CertificationsItem.propTypes = {
  certification: certificationShape.isRequired,
};

export default CertificationsItem;
