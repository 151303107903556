import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import DetailLayout from '../layouts/detail';
import { TalentProfile } from '../components/TalentProfile/TalentProfile';
import { getDecoratedTalent } from '../redux/actions/talent';
import { makeTalentViewSelector } from '../redux/selectors/talent-profile-component-selector';
import { Page } from '../components/Page/Page';

const TalentDetailPageComponent = ({
  getDecoratedTalent: getDecoratedTalentAction,
}) => {
  const { id } = useParams();

  useEffect(() => {
    getDecoratedTalentAction(id);
  }, [id]);

  return (
    <Page>
      <DetailLayout>
        <TalentProfile
          expanded
          isDetail
          onCloseSidedrawer={() => {}}
          candidateId={id}
        />
      </DetailLayout>
    </Page>
  );
};

TalentDetailPageComponent.propTypes = {
  getDecoratedTalent: func.isRequired,
};

const mapDispatchToProps = {
  getDecoratedTalent,
};

export const TalentDetailPage = connect(
  makeTalentViewSelector,
  mapDispatchToProps
)(TalentDetailPageComponent);
