import FormStore from '../classes/form-store';
import {
  createGroup,
  deleteGroup,
  removeGroupUser,
  updateGroup,
} from '../api/groups-api';
import { AjvUtil } from '../utils/ajv-util';

import { PreloadedGroupsStore } from './preloaded-groups-store';
import { PreloadedUsersStore } from './preloaded-users-store';

const schema = AjvUtil.compileSchema({
  required: ['name'],
  properties: {
    name: {
      type: 'string',
      min: 1,
      max: 255,
    },
  },
});

class FormTeamsClass extends FormStore {
  load(data) {
    return this.setState(data);
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(schema, formData);
  }

  deleteGroup(id) {
    return this.clearState(deleteGroup(id)).then(() => {
      PreloadedGroupsStore.load();
      PreloadedUsersStore.load();
    });
  }

  deleteUser(groupId, userId) {
    return this.clearState(removeGroupUser(groupId, userId)).then(() => {
      PreloadedGroupsStore.load();
      PreloadedUsersStore.load();
    });
  }

  submit(changedFields, formData) {
    const payload = {
      ...changedFields,
    };

    if (formData.parentId !== 'root') {
      payload.parentId = formData.parentId;
    }

    const promise = formData.id
      ? updateGroup(formData.id, { name: payload.name })
      : createGroup(payload);

    return this.clearState(promise).then(() => {
      PreloadedGroupsStore.load();
      PreloadedUsersStore.load();
    });
  }
}

export const FormTeamsStore = new FormTeamsClass();
