import React, { FC, PropsWithChildren, useLayoutEffect, useState } from 'react';
import { ToastUtil } from '@axiom/ui';

import { SessionExpiryContext } from './SessionExpiryContext';
import {
  checkSessionExpiry,
  determineDelay,
  timeInSeconds,
} from './session-expiry-util';
import { SessionExpiryToast } from './SessionExpiryToast';
import { SessionExpiryRefreshedToast } from './SessionExpiryRefreshedToast';

export const SessionExpiryHandler: FC<PropsWithChildren> = ({ children }) => {
  const [expiresInSeconds, setExpiresInSeconds] = useState<number>(null);
  const [delay, setDelay] = useState<number>(1);
  const [toastId, setToastId] = useState<string>(null);

  const toggleToast = (show = false) => {
    setToastId(prevId => {
      if (show && !prevId) {
        return ToastUtil.add({
          dismissible: false,
          type: 'critical',
          children: <SessionExpiryToast />,
        });
      } else if (!show) {
        if (prevId) ToastUtil.remove({ addedOn: prevId });
        return null;
      }

      return prevId;
    });
  };

  useLayoutEffect(() => {
    const id = setInterval(() => {
      setExpiresInSeconds(prevExpSeconds => {
        const expSeconds = checkSessionExpiry();
        setDelay(determineDelay(expSeconds));

        if (typeof prevExpSeconds === 'number' && prevExpSeconds < expSeconds) {
          ToastUtil.add({ children: <SessionExpiryRefreshedToast /> });
        }

        toggleToast(expSeconds <= timeInSeconds.oneHour);

        return expSeconds;
      });
    }, delay * 1000);

    return () => clearInterval(id);
  }, [delay]);

  return (
    <SessionExpiryContext.Provider
      value={expiresInSeconds}
      data-toast-id={toastId}
    >
      {children}
    </SessionExpiryContext.Provider>
  );
};
