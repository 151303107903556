import React from 'react';
import debounce from 'lodash/debounce';
import { bool, shape, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { Header5, Gutter, Text } from '@axiom/ui';

import { FFAutoComplete } from '../FFAutoComplete/FFAutoComplete';
import { LawSchoolsStore } from '../../stores/law-schools-store';

const FFLawSchoolsAutoComplete = props => {
  const {
    input,
    isDisabled,
    meta,
    onValueChange,
    lawSchoolsData,
    placeholder,
  } = props;

  const { results = [] } = lawSchoolsData;
  const options = results.map(({ id: value, name: label }) => ({
    value,
    label,
  }));

  const onFilterChange = debounce(event => {
    const searchTerm = event.target.value;
    if (searchTerm?.length > 1) {
      LawSchoolsStore.load({ searchTerm });
    }
  }, 250);

  return (
    <FFAutoComplete
      input={input}
      isDisabled={isDisabled}
      label="Institution"
      meta={meta}
      textField="label"
      filterable
      options={options}
      onValueChange={onValueChange}
      onFilterChange={onFilterChange}
      placeholder={placeholder}
      listNoDataRender={() => (
        <Gutter vertical="64px" horizontal="16px" className="text-align-center">
          <Gutter gutterType="margin" bottom="16px">
            <Header5>School not listed?</Header5>
          </Gutter>
          <Text>
            Try a different spelling or reach out to an Axiom for HQ superuser
            if you need more help.
          </Text>
        </Gutter>
      )}
    />
  );
};

FFLawSchoolsAutoComplete.propTypes = {
  input: shape({}).isRequired,
  isDisabled: bool,
  lawSchoolsData: LawSchoolsStore.getDataShape().isRequired,
  meta: shape({}).isRequired,
  placeholder: string,
  onValueChange: func,
};

FFLawSchoolsAutoComplete.defaultProps = {
  isDisabled: false,
  placeholder: undefined,
  onValueChange: () => {},
};

export default connect(state => ({
  lawSchoolsData: LawSchoolsStore.selectData(state),
}))(FFLawSchoolsAutoComplete);
