import React from 'react';
import { Candidate } from '@axiom/validation';

import { Modal } from '../../element/Modal/Modal';
import { ModalHeader } from '../../element/Modal/ModalHeader';
import { ModalSection } from '../../element/Modal/ModalSection';
import { ModalFooter } from '../../element/Modal/ModalFooter';
import { Button } from '../../element/Button/Button';
import { Gutter } from '../../layout/Gutter/Gutter';
import { CondensedLarge } from '../../content/CondensedLarge/CondensedLarge';

export interface ProfileStatusWarningModalProps {
  onClose: () => void;
  onConfirm: () => void;
  profileStatus: Candidate['profileStatus'];
  isDownloadBioModal?: boolean;
}

export const BiosProfileStatusWarningModal = ({
  onClose,
  onConfirm,
  profileStatus,
  isDownloadBioModal,
}: ProfileStatusWarningModalProps) => {
  return (
    <Modal name="BIO_STATUS_WARNING_MODAL">
      <ModalHeader name="BIO_STATUS_WARNING_MODAL_HEADER" onClose={onClose}>
        Talent is not available to be engaged
      </ModalHeader>
      <ModalSection>
        <CondensedLarge name="BIO_STATUS_WARNING_MODAL_BODY_1">
          This talent is {profileStatus} and is not available to be engaged on
          any opportunity. Please acknowledge that you understand this talent is
          not fully employed by Axiom.
        </CondensedLarge>
        <Gutter bottom="8px" />
        <CondensedLarge>
          I understand that this talent is not available to be engaged on any
          opportunity.
        </CondensedLarge>
      </ModalSection>
      <ModalFooter>
        <Button
          name="CANCEL_MODAL_BUTTON"
          variation="outline"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button name="CONFIRM_MODAL_BUTTON" onClick={onConfirm}>
          {isDownloadBioModal ? 'Download' : 'Confirm'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
