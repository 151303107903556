import React from 'react';
import styled from 'styled-components';
import { Gutter, useApi } from '@axiom/ui';
import { CandidateYearsOfExperienceModel } from '@axiom/ui-models';
import { Candidate } from '@axiom/validation';

import { TaxonomyApi } from '../../api/taxonomy-api';

const CandidateExperienceSummaryWrapper = styled.div`
  .header {
    font-size: 0.75rem;
  }
`;

export const CandidateExperienceIndustrySummary = ({
  candidateIndustries,
}: {
  candidateIndustries: Candidate['industries'];
}) => {
  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());

  return (
    <CandidateExperienceSummaryWrapper data-test="INDUSTRY_EXPERIENCE_SUMMARY">
      <Gutter top="24px" right="16px" left="16px">
        <div className="header">Industry Experience Summary</div>
        {candidateIndustries.map(({ industryValue, yearsOfExperience }) => (
          <Gutter top="8px" className="font-weight-bold" key={industryValue}>
            <div data-test="INDUSTRY_SUMMARY_ITEM">
              <span data-test="NAME">
                {taxonomy.industries[industryValue].displayValue}
              </span>{' '}
              -{' '}
              <span data-test="YEARS_OF_EXPERIENCE">
                {new CandidateYearsOfExperienceModel(
                  yearsOfExperience
                ).formatYearsOfExperience()}
              </span>{' '}
              years
            </div>
          </Gutter>
        ))}
      </Gutter>
    </CandidateExperienceSummaryWrapper>
  );
};
