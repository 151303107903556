import React, { useState } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import {
  CandidateOpportunitiesConst,
  EventsConst,
  OpportunitiesConst,
} from '@axiom/const';
import {
  ApiError,
  ContextMenu,
  ContextMenuDivider,
  ContextMenuHeader,
  ContextMenuItem,
  IconButton,
  useApi,
} from '@axiom/ui';
import {
  CandidateOpportunityUtils,
  DEFAULT_ALLOWED_TRANSITIONS,
  candidateOpportunityStatusUtils,
} from '@axiom/utils';
import {
  Candidate,
  Opportunity,
  OpportunityCandidateOrMlRecommended,
  OpportunityPosition,
} from '@axiom/validation';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { CandidateOpportunityLegacyApi } from '../../api/candidate-opportunities-legacy-api';
import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';
import { LegacyTalentApi } from '../../api/talent-api';
import { UsersApi } from '../../api/users-api';
import { AppEventItemModalStore } from '../../stores/app-event-item-modal-store';
import { EditSubmissionStore } from '../../stores/edit-submission-store';
import { EventSubjectConst } from '../../stores/events-store';
import { AxiomForTalentUtil } from '../../utils/axiom-for-talent-util';
import { EnvUtil } from '../../utils/env-util';
import { PositionUtil } from '../../utils/position-util';
import { getDecoratedTalent } from '../../redux/actions/talent';
import { FormAutoCoolingModalStore } from '../../stores/form-auto-cooling-modal-store';
import { PreloadedAppErrorsStore } from '../../stores/preloaded-app-errors-store';
import { AutoCoolingModal } from '../AutoCoolingModal/AutoCoolingModal';
import { CandidateCalendarMenuContent } from '../CandidateCalendarMenuContent/CandidateCalendarMenuContent';
import { TalentInterviewAvailabilityModal } from '../TalentInterviewAvailabilityModal/TalentInterviewAvailabilityModal';
import { isSalesUser } from '../../utils/roles-util';
import { OpportunityStore } from '../../stores/opportunity-store';
import { RejectionModal } from '../RejectionModal/RejectionModal';

import {
  poolContextMenuStatusLabels,
  positionContextMenuStatusLabels,
} from './OpportunityTalentConst';
import { OpportunityTalentReassignPositionModal } from './OpportunityTalentReassignPositionModal';
import { OpportunityTalentWarmingModal } from './OpportunityTalentWarmingModal';

const { CandidateStatuses, OppCandMlRecStatuses } = CandidateOpportunitiesConst;

const PREVIEW_TRANSITIONS = {
  [CandidateStatuses.Cooled]: DEFAULT_ALLOWED_TRANSITIONS.Cooled,
};

export const OpportunityTalentCandidateContextMenu = ({
  candidate,
  opportunityCandidate,
  opportunity,
}: {
  candidate: Candidate;
  opportunityCandidate: OpportunityCandidateOrMlRecommended;
  opportunity: Opportunity;
}) => {
  const dispatch = useDispatch();
  const [{ data: positions }, { data: user }] = useApi(
    OpportunityLegacyApi.readOpportunityPositions(opportunity.id),
    UsersApi.getSessionUser()
  );

  const { isMlRecommendation = false } = opportunityCandidate ?? {};

  const talentPosition = positions.find(position =>
    position.candidates.find(c => c.candidateId === candidate.id)
  );

  // While another candidate is selected for the position, show possible status transitions available once the other
  // candidate is moved out of selected
  const previewStatusOptions =
    PREVIEW_TRANSITIONS[opportunityCandidate.candidateStatus] || [];

  const isAnotherCandidateSelected = (position: OpportunityPosition) => {
    return position?.candidates.some(
      ({ candidateStatus, candidateId }) =>
        candidate.id !== candidateId &&
        candidateStatus === CandidateStatuses.Selected
    );
  };

  const candidateStatusOptions = candidateOpportunityStatusUtils
    .getAllowedTransitions({
      currentCandidateStatus: opportunityCandidate.candidateStatus,
      opportunity,
      position: talentPosition,
      role: undefined,
    })
    .filter(
      (statusOption: string) =>
        // Removed to Cooled is not a valid transition in the UI
        !(opportunity.isClosed && statusOption === CandidateStatuses.Cooled) &&
        // Although WarmedUnsure is a valid transition from Warmed, it is not a selectable transition in AHQ
        statusOption !== CandidateStatuses.WarmedUnsure
    );

  const getCannotChangeStatusCopy = () => {
    if (
      isAnotherCandidateSelected(talentPosition) &&
      opportunity.isFulfillmentActive
    ) {
      return "Talent selected, can't change status";
    }

    if (!opportunity.isFulfillmentActive) {
      return opportunity.stage === OpportunitiesConst.Stages.ClosedWon ||
        opportunity.stage === OpportunitiesConst.Stages.ClosedLost
        ? `${opportunity.stage}, can't change status`
        : `Fulfilled, can't change status`;
    }

    return "Can't change status";
  };

  const openTableau = () => {
    window.open(
      `${EnvUtil.tableauSimilarTalentUrl}?${qs.stringify({
        TNP: candidate.calculatedDisplayName,
      })}`,
      '_blank'
    );
  };

  const setFlagged = () => {
    LegacyTalentApi.updateCandidate(candidate.id, {
      isFlagged: !candidate.isFlagged,
    }).then(() => {
      LegacyTalentApi.refreshCandidate(candidate.id);
      dispatch(getDecoratedTalent(candidate.id));
    });
  };

  const [showReassignConfirmModal, setShowReassignConfirmModal] =
    useState(false);
  const [reassignPositionId, setReassignPositionId] = useState(
    talentPosition?.id
  );

  const [showWarmingModal, setShowWarmingModal] = useState(false);

  const [autoCoolingCandidateId, setAutoCoolingCandidateId] =
    useState<string>();

  const [
    showEditInterviewAvailabilityModal,
    setShowEditInterviewAvailabilityModal,
  ] = useState<boolean>(false);

  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [rejectCandidateStatus, setRejectCandidateStatus] = useState(null);

  const refreshAPIs = async () => {
    await Promise.all([
      OpportunityLegacyApi.refreshOpportunityCandidates(opportunity.id),
      OpportunityLegacyApi.refreshOpportunityPositions(opportunity.id),
      OpportunityStore.load(opportunity.id),
      CandidateOpportunityLegacyApi.refreshCandidateOpportunities(candidate.id),
    ]);
  };

  const updateOpportunityCandidateAndCost = async (statusOption: string) => {
    try {
      await OpportunityLegacyApi.updateOpportunityCandidate(
        opportunity.id,
        candidate.id,
        { candidateStatus: statusOption }
      );

      if (talentPosition) {
        await OpportunityLegacyApi.updateBurdendedCost(
          opportunity.id,
          candidate.id
        );
      }
    } catch (e) {
      PreloadedAppErrorsStore.show(
        ((e as ApiError).getErrors() as { message: string })?.message
      );
    }
    await refreshAPIs();
  };

  return (
    <div data-test="OPPORTUNITY_TALENT_CANDIDATE_CONTEXT_MENU">
      <ContextMenu
        direction="left"
        anchor={
          <IconButton
            icon="vertical-ellipsis"
            variation="minimal"
            pattern="secondary"
            inverse
          />
        }
      >
        <ContextMenuHeader>Update Status</ContextMenuHeader>
        {candidateStatusOptions.length === 0 &&
        previewStatusOptions.length === 0 ? (
          <ContextMenuItem disabled name="CANNOT_CHANGE_STATUS">
            {getCannotChangeStatusCopy()}
          </ContextMenuItem>
        ) : (
          <>
            {previewStatusOptions.map(statusOption => (
              <ContextMenuItem
                name={`preview-status-${statusOption.toLowerCase()}`}
                key={`preview-status-${statusOption.toLowerCase()}`}
                disabled
              >
                {positionContextMenuStatusLabels[statusOption]}
              </ContextMenuItem>
            ))}
          </>
        )}
        {(candidateStatusOptions.length > 0 ||
          opportunityCandidate.candidateStatus ===
            CandidateStatuses.WarmedYes) && (
          <>
            {opportunityCandidate.candidateStatus ===
              CandidateStatuses.WarmedYes && (
              <ContextMenuItem
                name="SUBMIT_ITEM"
                onClick={() => {
                  EditSubmissionStore.load(
                    opportunity.submissionId,
                    opportunity.id,
                    candidate.id,
                    false
                  );
                }}
              >
                Submit
              </ContextMenuItem>
            )}
            {candidateStatusOptions.map((statusOption: string) => (
              <React.Fragment key={`update-status-${statusOption}-key`}>
                {statusOption === CandidateStatuses.Cooled &&
                  !isMlRecommendation && (
                    <ContextMenuItem
                      onClick={async () => {
                        setAutoCoolingCandidateId(candidate.id);
                        FormAutoCoolingModalStore.load({
                          candidateId: candidate.id,
                          opportunityId: opportunity.id,
                          candidateStatus: CandidateStatuses.Cooled,
                        });
                      }}
                      name="update-status-cooled"
                    >
                      {positionContextMenuStatusLabels[statusOption]}
                    </ContextMenuItem>
                  )}
                {statusOption === CandidateStatuses.InterestedRejected &&
                  !isMlRecommendation && (
                    <ContextMenuItem
                      onClick={async () => {
                        setRejectCandidateStatus(statusOption);
                        setShowRejectionModal(true);
                      }}
                      name="update-status-interestedrejected"
                    >
                      {poolContextMenuStatusLabels[statusOption]}
                    </ContextMenuItem>
                  )}
                {statusOption === CandidateStatuses.Interviewing &&
                  !isMlRecommendation && (
                    <ContextMenuItem
                      onClick={async () => {
                        if (!opportunity.isQualificationComplete) {
                          PreloadedAppErrorsStore.show(
                            'Please qualify this opportunity before setting talent status to Interview.'
                          );

                          return;
                        }

                        AppEventItemModalStore.openModal(
                          EventSubjectConst.Opportunity,
                          {
                            candidateId: candidate.id,
                            opportunityId: opportunity.id,
                            type: EventsConst.Types.Interview,
                            purpose: EventsConst.Purposes.OpportunityInterview,
                          }
                        );
                        await updateOpportunityCandidateAndCost(statusOption);
                      }}
                      name="update-status-interviewing"
                    >
                      {positionContextMenuStatusLabels[statusOption]}
                    </ContextMenuItem>
                  )}
                {statusOption === CandidateStatuses.Selected &&
                  !isMlRecommendation && (
                    <ContextMenuItem
                      onClick={async () => {
                        await updateOpportunityCandidateAndCost(statusOption);
                      }}
                      name="update-status-selected"
                      disabled={
                        !CandidateOpportunityUtils.hasSubmittedRate(
                          opportunityCandidate
                        ) ||
                        CandidateOpportunityUtils.baseHourlyUnavailable(
                          opportunityCandidate
                        )
                      }
                    >
                      {positionContextMenuStatusLabels[statusOption]}
                    </ContextMenuItem>
                  )}
                {statusOption === CandidateStatuses.Warmed &&
                  !isMlRecommendation && (
                    <ContextMenuItem
                      onClick={async () => {
                        setShowWarmingModal(true);
                      }}
                      name="update-status-warmed"
                    >
                      {positionContextMenuStatusLabels[statusOption]}
                    </ContextMenuItem>
                  )}
                {[
                  CandidateStatuses.Rejected,
                  CandidateStatuses.Removed,
                  CandidateStatuses.RepBioShare,
                  CandidateStatuses.ShortList,
                  CandidateStatuses.Suggested,
                  CandidateStatuses.TalentOptOut,
                  CandidateStatuses.WarmedYes,
                  CandidateStatuses.WarmedUnsure,
                ].includes(statusOption) &&
                  !isMlRecommendation && (
                    <ContextMenuItem
                      onClick={async () => {
                        await updateOpportunityCandidateAndCost(statusOption);
                      }}
                      name={`update-status-${statusOption.toLowerCase()}`}
                    >
                      {positionContextMenuStatusLabels[statusOption]}
                    </ContextMenuItem>
                  )}
                {!!isMlRecommendation && (
                  <ContextMenuItem
                    onClick={async () => {
                      if (statusOption === OppCandMlRecStatuses.AiRemoved) {
                        setRejectCandidateStatus(statusOption);
                        setShowRejectionModal(true);
                      } else {
                        try {
                          await OpportunityLegacyApi.createOpportunityCandidates(
                            opportunity.id,
                            {
                              candidateIds: [candidate.id],
                              positionId: undefined,
                            }
                          );
                          if (statusOption === CandidateStatuses.ShortList) {
                            await OpportunityLegacyApi.updateOpportunityCandidate(
                              opportunity.id,
                              candidate.id,
                              { candidateStatus: statusOption }
                            );
                          }
                        } catch (e) {
                          PreloadedAppErrorsStore.show(
                            ((e as ApiError).getErrors() as { message: string })
                              ?.message
                          );
                        }
                        await refreshAPIs();
                      }
                    }}
                    name={`update-status-${statusOption.toLowerCase()}`}
                  >
                    {statusOption === OppCandMlRecStatuses.AiRemoved
                      ? poolContextMenuStatusLabels[statusOption]
                      : positionContextMenuStatusLabels[statusOption]}
                  </ContextMenuItem>
                )}
              </React.Fragment>
            ))}
          </>
        )}
        <ContextMenuDivider />
        <ContextMenuHeader>Move Position</ContextMenuHeader>
        {!!(
          positions.length === 0 ||
          (talentPosition && positions.length === 1)
        ) && (
          <ContextMenuItem disabled name="NO_POSITION_ITEM">
            No position, can't change position
          </ContextMenuItem>
        )}
        {positions.length > 0 && !opportunity.isFulfillmentActive ? (
          <ContextMenuItem
            disabled
            name="FULFILLED_CANNOT_CHANGE_POSITION_ITEM"
          >
            {opportunity.stage === OpportunitiesConst.Stages.ClosedWon ||
            opportunity.stage === OpportunitiesConst.Stages.ClosedLost
              ? `${opportunity.stage}, can't change position`
              : `Fulfilled, can't change position`}
          </ContextMenuItem>
        ) : (
          <>
            {PositionUtil.sortPositions(positions)
              .map((position, index) => (
                <ContextMenuItem
                  key={`position-${position.id}-menu-item`}
                  disabled={isAnotherCandidateSelected(position)}
                  name={`MOVE_TO_POSITION_ITEM_${index + 1}`}
                  onClick={() => {
                    setReassignPositionId(position.id);
                    setShowReassignConfirmModal(true);
                  }}
                >
                  {isAnotherCandidateSelected(position) ? (
                    `Talent selected, can't change to position ${index + 1}`
                  ) : (
                    <div className="max-lines-1">
                      Move to position {index + 1}: {position.name}
                    </div>
                  )}
                </ContextMenuItem>
              ))
              .filter(
                i => i.key !== `position-${talentPosition?.id}-menu-item`
              )}
          </>
        )}
        <ContextMenuDivider />
        <ContextMenuHeader>Additional Settings</ContextMenuHeader>
        {!!talentPosition && (
          <ContextMenuItem
            name="CANDIDATE_CONTEXT_SET_RATE"
            onClick={() => {
              EditSubmissionStore.load(
                opportunity.submissionId,
                opportunity.id,
                candidate.id,
                true
              );
            }}
            disabled={
              !EditSubmissionStore.isRateSettableForCandidate(
                candidate,
                opportunity
              ) ||
              opportunityCandidate.candidateStatus ===
                CandidateStatuses.Removed ||
              opportunityCandidate.candidateStatus ===
                CandidateStatuses.TalentOptOut
            }
          >
            Set rate
          </ContextMenuItem>
        )}
        <ContextMenuItem
          onClick={() => {
            AppEventItemModalStore.openModal(EventSubjectConst.Candidate, {
              candidateId: candidate.id,
              opportunityId: opportunity.id,
            });
          }}
          name="CANDIDATE_CONTEXT_MENU_ADD_ACTIVITY"
        >
          Add activity
        </ContextMenuItem>
        {!isSalesUser(user) && (
          <ContextMenuItem
            onClick={setFlagged}
            name="CANDIDATE_CONTEXT_MENU_FLAG"
          >
            {candidate.isFlagged ? 'Remove flag' : 'Flag profile'}
          </ContextMenuItem>
        )}
        {AxiomForTalentUtil.canViewAft(candidate, user) && (
          <ContextMenuItem
            name="CANDIDATE_CONTEXT_MENU_OPEN_AFT"
            onClick={() => AxiomForTalentUtil.openAftProfile(candidate.id)}
          >
            Open in AFT
          </ContextMenuItem>
        )}
        <ContextMenuItem
          onClick={openTableau}
          name="CANDIDATE_CONTEXT_MENU_TABLEAU"
        >
          Find similar talent
        </ContextMenuItem>
        <ContextMenuDivider />
        <CandidateCalendarMenuContent
          user={user}
          onEditAvailability={setShowEditInterviewAvailabilityModal}
        >
          <ContextMenuHeader name="TALENT_AVAILABILITY_HEADER">
            Availability Settings
          </ContextMenuHeader>
        </CandidateCalendarMenuContent>
      </ContextMenu>
      {!!showReassignConfirmModal && (
        <OpportunityTalentReassignPositionModal
          onClose={() => setShowReassignConfirmModal(false)}
          isMlRecommendation={isMlRecommendation}
          opportunityId={opportunity.id}
          candidateId={candidate.id}
          positionId={reassignPositionId}
        />
      )}
      {!!showWarmingModal && (
        <OpportunityTalentWarmingModal
          onClose={() => setShowWarmingModal(false)}
          opportunityId={opportunity.id}
          candidateId={candidate.id}
        />
      )}
      {!!showEditInterviewAvailabilityModal && (
        <TalentInterviewAvailabilityModal
          candidate={candidate}
          user={user}
          onClose={() => setShowEditInterviewAvailabilityModal(false)}
        />
      )}
      {!!showRejectionModal && (
        <RejectionModal
          candidateId={candidate.id}
          opportunityId={opportunity.id}
          candidateStatus={rejectCandidateStatus}
          onClose={() => {
            setRejectCandidateStatus(null);
            setShowRejectionModal(false);
          }}
        />
      )}
      <FormStateTreatment
        name="COOL_MODAL_TREATMENT"
        isModalState
        dataId={autoCoolingCandidateId}
        dataIdName="candidateId"
        formStore={FormAutoCoolingModalStore}
        renderLoadedView={({ fireSubmit, fireCancel, formData }) => {
          return (
            <AutoCoolingModal
              form={formData}
              onCancel={() => {
                fireCancel();
                setAutoCoolingCandidateId(undefined);
              }}
              onSend={() => {
                fireSubmit();
                setAutoCoolingCandidateId(undefined);
              }}
            />
          );
        }}
      />
    </div>
  );
};
