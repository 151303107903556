import React, { PureComponent } from 'react';
import { string, func, oneOf } from 'prop-types';

import {
  Label,
  Input,
  StyledSvgCheck,
  StyledSvgDash,
  Container,
} from './AppEventItemModalStyles';

class AppEventItemModalCheckbox extends PureComponent {
  handleChange = () => {
    this.props.onChange(this.props.value);
  };

  render() {
    const {
      handleChange,
      props: { name, label, value },
    } = this;

    return (
      <Container>
        <Label htmlFor={name}>{label}</Label>
        {value === 'checked' ? (
          <StyledSvgCheck
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27.156 25.37"
          >
            <g transform="translate(-2164.616 -371.607)">
              <rect
                width="27"
                height="6"
                rx="2"
                transform="matrix(0.588, -0.809, 0.809, 0.588, 2171.048, 393.45)"
              />
              <rect
                width="13"
                height="6"
                rx="2"
                transform="matrix(0.809, 0.588, -0.588, 0.809, 2168.143, 383.964)"
              />
            </g>
          </StyledSvgCheck>
        ) : null}
        {value === 'indeterminate' ? (
          <StyledSvgDash
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27.156 25.37"
          >
            <rect width="12" height="4" rx="1.5" />
          </StyledSvgDash>
        ) : null}
        <Input
          aria-checked={value === 'indeterminate' ? 'mixed' : undefined}
          checked={value === 'checked'}
          id={name}
          name={name}
          onChange={handleChange}
          type="checkbox"
          value={value}
        />
      </Container>
    );
  }
}

AppEventItemModalCheckbox.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  value: oneOf(['checked', 'unchecked', 'indeterminate']).isRequired,
  onChange: func,
};

AppEventItemModalCheckbox.defaultProps = {
  onChange: () => {},
};

export default AppEventItemModalCheckbox;
