/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { func, arrayOf, shape, string, number } from 'prop-types';
import { ComboBox as KendoComboBox } from '@progress/kendo-react-dropdowns';

class LocationTypeaheadKendoComboBox extends React.Component {
  onChange = event => {
    if (event.filter) {
      this.props.onTextInputChange(event.filter.value);
    }
  };

  onSelect = event => {
    if (event.target.value) {
      this.props.onDropdownSelect(event.target.value);
    }
  };

  render() {
    const defaultValue = {
      locationName: this.props.defaultLocation.locationName,
      locationPlaceId: this.props.defaultLocation.locationPlaceId,
    };
    return (
      <KendoComboBox
        defaultValue={defaultValue}
        data={this.props.predictions}
        onFilterChange={this.onChange}
        onChange={this.onSelect}
        clearButton={false}
        filterable
        textField="locationName"
        popupSettings={{ animate: false }}
      />
    );
  }
}

LocationTypeaheadKendoComboBox.propTypes = {
  defaultLocation: shape({
    locationName: string,
    locationPlaceId: string,
    locationLatitude: number,
    locationLongitude: number,
    locationAddressComponents: shape({
      addresses: arrayOf(shape({})),
    }),
  }).isRequired,
  predictions: arrayOf(
    shape({
      locationName: string,
      locationPlaceId: string,
      locationLatitude: null,
      locationLongitude: null,
      locationAddressComponents: null,
    })
  ).isRequired,
  onDropdownSelect: func.isRequired,
  onTextInputChange: func.isRequired,
};

export default LocationTypeaheadKendoComboBox;
