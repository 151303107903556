import React from 'react';
import { string, bool, func, shape, number } from 'prop-types';

import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { FFError, isErroring } from '../FFError/FFError';

export const FFRichTextEditor = ({
  meta,
  input,
  readOnly,
  showResetButton,
  showCharsRemainingLabel,
  maxLength,
  onReset,
  placeholder,
}) => (
  <RichTextEditor
    invalid={isErroring(meta)}
    initialValue={meta.initial}
    value={input.value}
    readOnly={readOnly}
    onChange={input.onChange}
    showResetButton={showResetButton}
    showCharsRemainingLabel={showCharsRemainingLabel}
    maxLength={maxLength}
    errorMessageNode={<FFError finalFormElementMeta={meta} />}
    dirty={meta.dirty}
    onReset={onReset}
    placeholder={placeholder}
  />
);

FFRichTextEditor.defaultProps = {
  readOnly: false,
  showCharsRemainingLabel: RichTextEditor.defaultProps.showCharsRemainingLabel,
  maxLength: RichTextEditor.defaultProps.maxLength,
  onReset: null,
  showResetButton: false,
  placeholder: null,
};

FFRichTextEditor.propTypes = {
  input: shape({
    value: string.isRequired,
    onChange: func.isRequired,
  }).isRequired,
  maxLength: number,
  meta: shape({}).isRequired,
  placeholder: string,
  readOnly: bool,
  showCharsRemainingLabel: bool,
  showResetButton: bool,
  onReset: func,
};
