import React, { Component } from 'react';
import { string, shape } from 'prop-types';
import gtmParts from 'react-google-tag-manager';

import { window } from '../../utils/global-util';

class GoogleTagManager extends Component {
  componentDidMount() {
    const { dataLayerName, scriptId } = this.props;

    if (!window[dataLayerName]) {
      // eslint-disable-next-line unicorn/prefer-query-selector
      const gtmScriptNode = document.getElementById(scriptId);

      // eslint-disable-next-line no-eval
      eval(gtmScriptNode.textContent);
    }
  }

  render() {
    const {
      gtmId,
      dataLayerName,
      scriptId,
      previewVariables,
      additionalEvents,
    } = this.props;
    const gtm = gtmParts({
      id: gtmId,
      dataLayerName,
      additionalEvents,
      previewVariables,
    });

    return (
      <div>
        <div>{gtm.noScriptAsReact()}</div>
        <div id={scriptId || 'react-google-tag-manager-gtm'}>
          {gtm.scriptAsReact()}
        </div>
      </div>
    );
  }
}

GoogleTagManager.defaultProps = {
  dataLayerName: 'dataLayer',
  additionalEvents: {},
  previewVariables: '',
  scriptId: 'react-google-tag-manager-gtm',
};

GoogleTagManager.propTypes = {
  additionalEvents: shape({}),
  dataLayerName: string,
  gtmId: string.isRequired,
  previewVariables: string,
  scriptId: string,
};

export default GoogleTagManager;
