import styled from 'styled-components';

import { getEmploymentStylesByStatus } from '../../utils/color-util';

export const OpportunityStatusLegendList = styled.ul`
  display: inline-flex;
  margin: 0 1rem 0 0;
  padding: 0;
`;

export const OpportunityStatusLegendListItem = styled.li`
  &:last-child {
    margin-right: 2rem;
  }
  margin: 0 3rem 0 0;
  display: inline-flex;
  align-items: center;
`;

export const OpportunityStatusLegendLabel = styled.span``;
export const OpportunityStatusLegendSwatch = styled.div`
  margin-left: 0.5rem;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${({ status }) =>
    getEmploymentStylesByStatus(status).background};
  border: 1px solid
    ${({ status }) => getEmploymentStylesByStatus(status).border};
`;
