import React, { useState } from 'react';
import { string, shape, func } from 'prop-types';
import { CaretIcon } from '@axiom/ui';

import { formatDataTestId } from '../../utils/data-test-util';

import {
  SavedSearchesMenuItem,
  SavedSearchesItemFilterContainer,
  SavedSearchesItemTitle,
  SavedSearchesPlayWrapper,
  SavedSearchesItemWrapper,
} from './SavedSearchesStyles';

export const SavedSearchesItem = ({
  userFilter,
  handleFilterLoad,
  handleRenderFilter,
}) => {
  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilter = () => setShowFilters(!showFilters);
  return (
    <>
      <SavedSearchesItemWrapper data-test={userFilter.name}>
        <SavedSearchesItemTitle onClick={() => handleFilterLoad(userFilter)}>
          {userFilter.name}
        </SavedSearchesItemTitle>
        <SavedSearchesPlayWrapper
          onClick={() => handleFilterLoad(userFilter)}
          data-test={formatDataTestId('showSavedResults')}
          className="gtm-run-saved-search"
        >
          <CaretIcon direction="right" height={13} />
        </SavedSearchesPlayWrapper>
        <SavedSearchesMenuItem
          onClick={handleToggleFilter}
          expanded={showFilters}
          data-test={formatDataTestId('searchEllipsis')}
        />
      </SavedSearchesItemWrapper>
      {!!showFilters && (
        <SavedSearchesItemFilterContainer
          data-test={formatDataTestId('savedSearchForm')}
        >
          {handleRenderFilter(userFilter)}
        </SavedSearchesItemFilterContainer>
      )}
    </>
  );
};

SavedSearchesItem.propTypes = {
  handleFilterLoad: func.isRequired,
  handleRenderFilter: func.isRequired,
  userFilter: shape({
    name: string.isRequired,
    id: string.isRequired,
  }).isRequired,
};
