import React from 'react';
import {
  Checkbox,
  Form,
  GridColumn,
  GridRow,
  Layout,
  ParagraphHeader,
} from '@axiom/ui';
import { z } from 'zod';
import { UserSchema, ContactSchema } from '@axiom/validation';

import { ContactsApi } from '../../api/contacts-api';
import { ContactStore } from '../../stores/contact-store';

const EmailPrefFormSchema = ContactSchema.pick({
  availabilityNotificationOptOut: true,
  benchNotificationOptOut: true,
})
  .merge(UserSchema.pick({ contactId: true }))
  .partial({
    availabilityNotificationOptOut: true,
    benchNotificationOptOut: true,
  });

type EmailPrefFormType = z.infer<typeof EmailPrefFormSchema>;

export const ManageContactFormEmailPref = (props: EmailPrefFormType) => {
  const { contactId } = props;
  const handleOnSubmit = async (changeData: EmailPrefFormType) => {
    const payload: {
      availabilityNotificationOptOut?: boolean;
      benchNotificationOptOut?: boolean;
    } = {
      availabilityNotificationOptOut: undefined,
      benchNotificationOptOut: undefined,
    };

    if (changeData?.availabilityNotificationOptOut !== undefined) {
      payload.availabilityNotificationOptOut =
        !changeData.availabilityNotificationOptOut;
    }

    if (changeData?.benchNotificationOptOut !== undefined) {
      payload.benchNotificationOptOut = !changeData.benchNotificationOptOut;
    }
    await ContactsApi.updateContacts(contactId, payload);

    return ContactStore.load(contactId);
  };

  return (
    <GridRow gutterBottom="24px">
      <GridColumn width={5}>
        <ParagraphHeader name="SECTION_HEADER">
          User Email Notification Preferences
        </ParagraphHeader>
      </GridColumn>
      <GridColumn width={7} data-test="EMAIL_PREF">
        <Form
          schema={EmailPrefFormSchema}
          name="EMAIL_PREF_FORM"
          initialValues={props}
          onSubmit={handleOnSubmit}
          submitOnChange
        >
          {() => (
            <Layout verticalGutter="24px" direction="vertical">
              <Checkbox
                name="benchNotificationOptOut"
                description="Bench Updates"
                displayValue="Let me know when there are additions or changes to talent on my bench."
              />
              <Checkbox
                name="availabilityNotificationOptOut"
                description="Availability Updates"
                displayValue="Let me know when availability changes for talent on my bench."
              />
            </Layout>
          )}
        </Form>
      </GridColumn>
    </GridRow>
  );
};
