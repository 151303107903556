import React, { useState } from 'react';
import {
  Button,
  CondensedHeader,
  CondensedLarge,
  Gutter,
  Paragraph,
  ToastUtil,
} from '@axiom/ui';
import { Opportunity } from '@axiom/validation';

import { OpportunityCandidateMlRecommendationApi } from '../../api/opportunity-candidate-ml-recommendations-api';
import { OpportunityStore } from '../../stores/opportunity-store';
import { AiDuplicatesMessage } from '../AiDuplicatesMessage/AiDuplicatesMessage';
import {
  FormSchema,
  GetMoreMatchesModal,
} from '../GetMoreMatchesModal/GetMoreMatchesModal';
import { EditOpportunityTalentTabStore } from '../../stores/edit-opportunity-talent-tab-store';

export const hasBeenMatchedButtonText = 'Get More Matches';
export const hasNotYetBeenMatchedButtonText = 'Get AI Matches';

export const noMatchesErrorText = `We didn't find available talent who match up with all of this opportunity’s requirements.`;

export const OpportunityTalentAiMatchControl: React.FC<{
  opportunity: Opportunity;
  savedFilters?: FormSchema;
}> = ({ opportunity, savedFilters = {} }) => {
  const [hasGottenNoRecommendations, setHasGottenNoRecommendations] =
    useState(false);
  const [showGetMoreMatchesModal, setShowGetMoreMatchesModal] = useState(false);

  if (opportunity.isAiMatchable) {
    const shouldTriggerModal =
      opportunity.hasBeenAIMatched || hasGottenNoRecommendations;
    return (
      <>
        <Gutter top="24px" left="24px">
          <div
            data-test="pendo-analytics-aimatch-control"
            data-pendo-analytics-id="get-ai-matches-click"
            data-pendo-analytics-opportunity-id={opportunity.id}
          >
            {shouldTriggerModal ? (
              <Button
                name="PENDO_GET_MORE_MATCHES_BUTTON"
                onClick={() => {
                  setShowGetMoreMatchesModal(true);
                }}
              >
                {hasBeenMatchedButtonText}
              </Button>
            ) : (
              <Button
                name="LOADMLCANDBUTTON"
                onClick={async () => {
                  // we're sending the default parameters (first button click)
                  const response =
                    await OpportunityCandidateMlRecommendationApi.createOpportunityCandidateMlRecommendations(
                      { opportunityId: opportunity.id },
                      {}
                    );
                  // response is null if there is a known server error
                  if (response !== null) {
                    await OpportunityStore.load(opportunity.id);
                    const {
                      data: { candidateIds, opportunityCandidates } = {},
                    } = response;
                    if (!candidateIds?.length) {
                      ToastUtil.add({
                        dismissible: true,
                        name: `NOMATCHES`,
                        type: 'error',
                        children: (
                          <>
                            <CondensedHeader>Error</CondensedHeader>
                            <CondensedLarge>
                              {noMatchesErrorText}
                            </CondensedLarge>
                          </>
                        ),
                      });
                      setHasGottenNoRecommendations(true);
                    }

                    // Pop a toast if talent was already on the opp before the AI match
                    const dupes = opportunityCandidates?.filter(
                      ({ duplicate }) => duplicate
                    );
                    if (dupes?.length) {
                      ToastUtil.add({
                        dismissible: true,
                        name: `DUPLICATECAND`,
                        type: 'critical',
                        children: <AiDuplicatesMessage duplicates={dupes} />,
                      });
                    }
                  }
                }}
              >
                {hasNotYetBeenMatchedButtonText}
              </Button>
            )}
          </div>
        </Gutter>
        {!!showGetMoreMatchesModal && (
          <GetMoreMatchesModal
            opportunity={opportunity}
            onRequestSuccess={({ filters }) => {
              EditOpportunityTalentTabStore.saveFilters(filters);
            }}
            onClose={() => setShowGetMoreMatchesModal(false)}
            savedFilters={savedFilters}
          />
        )}
      </>
    );
  }
  return (
    opportunity.aiUnmatchableMessage && (
      <Gutter top="24px" left="24px">
        <Paragraph name="ai-unmatchable-message">
          {opportunity.aiUnmatchableMessage}
        </Paragraph>
      </Gutter>
    )
  );
};
