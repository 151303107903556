import React, { useEffect } from 'react';
import qs from 'qs';
import { TalentBios, useApi } from '@axiom/ui';
import { User } from '@axiom/validation';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { LegacyTalentApi } from '../../api/talent-api';
import { PracticeAreasApi } from '../../api/practice-areas-api';
import { CandidateApi } from '../../api/candidate-api';
import { EnvUtil } from '../../utils/env-util';
import { WindowUtil } from '../../utils/window-util';

export const CandidateBios = ({ user }: { user: User }) => {
  const urlParams = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { candidateId } = urlParams;
  const isAnon = location.pathname.includes('/anonymous');
  const download = location.pathname.includes('/download');
  const HIDE_HIGHLIGHTS = searchParams.get('hideHighlights');
  const HIDE_LOCATION = searchParams.get('hideLocation');

  const [{ data: candidate }, { data: practiceAreas }] = useApi(
    LegacyTalentApi.readCandidate(candidateId, { isAnonymous: isAnon }),
    PracticeAreasApi.readPracticeAreas()
  );

  const [{ data: groupedExperiences }] = useApi(
    candidate.id &&
      CandidateApi.readCandidateGroupedExperiences(candidate.id, {
        isAnonymous: isAnon,
      })
  );
  const baseUrl = `/talent/${candidate.id}`;

  useEffect(() => {
    WindowUtil.title(candidate.calculatedDisplayName);
  }, [candidateId]);

  const handleOnNavigate = (hideHighlights = false, hideLocation = false) => {
    const createQueryString = () => {
      if (hideHighlights && hideLocation) {
        return qs.stringify(
          { hideHighlights, hideLocation },
          { addQueryPrefix: true }
        );
      } else if (hideHighlights) {
        return qs.stringify({ hideHighlights }, { addQueryPrefix: true });
      } else if (hideLocation) {
        return qs.stringify({ hideLocation }, { addQueryPrefix: true });
      }
      return '';
    };

    const queries = createQueryString();
    const href = `${baseUrl}/download/bio${isAnon ? '/anonymous' : ''}${queries}`;

    window.open(href, 'download_bio');
  };
  return (
    <TalentBios
      hideLocation={HIDE_LOCATION === 'true'}
      user={user}
      hideHighlights={HIDE_HIGHLIGHTS === 'true'}
      candidate={candidate}
      groupedExperiences={groupedExperiences}
      practiceAreas={practiceAreas}
      onNavigate={handleOnNavigate}
      isAnonymous={!!isAnon}
      download={!!download}
      clientUrl={EnvUtil.clientUrl}
    />
  );
};
