import React from 'react';
import PropTypes from 'prop-types';

import { OpportunityUtil } from '../../utils/opportunity-util';

import {
  OpportunityStatusLegendList,
  OpportunityStatusLegendListItem,
  OpportunityStatusLegendLabel,
  OpportunityStatusLegendSwatch,
} from './OpportunityStatusLegendStyles';

const OpportunityStatusLegend = ({ opportunities }) => {
  const activeCount = opportunities.reduce((acc, currentValue) => {
    if (currentValue.isFulfillmentActive) {
      return acc + 1;
    }

    return acc;
  }, 0);

  const inactiveCount = opportunities.length - activeCount;

  return (
    <OpportunityStatusLegendList>
      <OpportunityStatusLegendListItem key="active-status-legend-item">
        <OpportunityStatusLegendLabel>
          {`Active (${activeCount})`}
        </OpportunityStatusLegendLabel>
        <OpportunityStatusLegendSwatch
          status={OpportunityUtil.getFulfillmentStatusColorName(true)}
        />
      </OpportunityStatusLegendListItem>
      <OpportunityStatusLegendListItem key="inactive-status-legend-item">
        <OpportunityStatusLegendLabel>
          {`Inactive (${inactiveCount})`}
        </OpportunityStatusLegendLabel>
        <OpportunityStatusLegendSwatch
          status={OpportunityUtil.getFulfillmentStatusColorName(false)}
        />
      </OpportunityStatusLegendListItem>
    </OpportunityStatusLegendList>
  );
};

OpportunityStatusLegend.defaultProps = {
  opportunities: [],
};
OpportunityStatusLegend.propTypes = {
  // This component needs to be refactored
  // eslint-disable-next-line react/forbid-prop-types
  opportunities: PropTypes.arrayOf(PropTypes.object),
};

export default OpportunityStatusLegend;
