import React from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { OpportunitiesConst } from '@axiom/const';
import {
  Gutter,
  Modal,
  Button,
  ModalHeader,
  ModalSection,
  ModalFooter,
  CondensedLabel,
} from '@axiom/ui';
import { Field } from 'react-final-form';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { EditOpportunityTalentTabStore } from '../../stores/edit-opportunity-talent-tab-store';
import { FormAutoCoolingModalStore } from '../../stores/form-auto-cooling-modal-store';
import { FFRichTextEditor } from '../FFRichTextEditor/FFRichTextEditor';

const AutoCoolingModalComponent = ({
  form,
  editOpportunityTalentTabStore,
  onCancel,
  onSend,
}) => {
  return (
    <StoreStateTreatment
      name="COOLING_MESSAGE_TREATMENT"
      isModalState
      storeState={editOpportunityTalentTabStore}
      renderLoadedView={({ data: editOpportunityTalentTabData }) => {
        return (
          <Modal>
            <ModalHeader onClose={onCancel}>Cooling Talent</ModalHeader>
            <ModalSection>
              <div data-test="AUTOCOOLINGFORM">
                <Gutter bottom="16px">
                  <CondensedLabel>
                    We'll send the following message to the talent directly to
                    let them know they've been cooled. To customize the message,
                    edit the text below before you click Send.
                  </CondensedLabel>
                </Gutter>
                <Field
                  name="candidateStatusMessage"
                  component={FFRichTextEditor}
                  initialValue={
                    form.coolingMessage ||
                    (editOpportunityTalentTabData.opportunity.stage ===
                    OpportunitiesConst.Stages.ClosedLost
                      ? `Thanks for your interest in this opportunity. Unfortunately, the client has expressed they no longer have a need for Axiom's support. We will reach out to you should anything change.`
                      : `Thank you for your interest in this opportunity.\n\nWhile your skills and accomplishments are impressive, the client has decided to go in a different direction for this role.\n\nWe appreciate the time you spent helping us prepare your submission and will be sure to keep you in mind for future opportunities.`)
                  }
                />
              </div>
            </ModalSection>
            <ModalFooter>
              <Button
                className="cooling-talent-action"
                onClick={onCancel}
                name="COOLINGTALENTCANCELBUTTON"
                variation="outline"
              >
                Cancel
              </Button>
              <Button
                className="cooling-talent-action"
                onClick={onSend}
                name="COOLINGTALENTSENDBUTTON"
              >
                Send
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    />
  );
};

AutoCoolingModalComponent.defaultProps = {};
AutoCoolingModalComponent.propTypes = {
  editOpportunityTalentTabStore:
    EditOpportunityTalentTabStore.getStateShape().isRequired,
  form: FormAutoCoolingModalStore.getDataShape().isRequired,
  onCancel: func.isRequired,
  onSend: func.isRequired,
};

export const AutoCoolingModal = connect(state => ({
  editOpportunityTalentTabStore: EditOpportunityTalentTabStore.select(state),
}))(AutoCoolingModalComponent);
