import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { func, string, bool, shape } from 'prop-types';
import {
  DarkTheme,
  DataTest,
  FlagIcon,
  Gutter,
  Header3,
  Label,
  Text,
  IconButton,
  Layout,
  LayoutItem,
  Paragraph,
} from '@axiom/ui';
import { DisplayPronouns } from '@axiom/const';
import qs from 'qs';

import { WindowUtil } from '../../utils/window-util';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { DateUtil } from '../../utils/date-util';
import { fullNameByIdSelector } from '../../redux/selectors/users';
import { setTalentIsFlagged as setTalentIsFlaggedAction } from '../../redux/actions/talent';
import { talentShape } from '../../models/talent';
import { formatDataTestId } from '../../utils/data-test-util';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { FormTalentProfileStatusStore } from '../../stores/form-talent-profile-status-store';
import { isSalesUser, isTalentSuccessUser } from '../../utils/roles-util';
import { CandidateAvailabilityModalStore } from '../../stores/candidate-availability-modal-store';
import { NumberUtil } from '../../utils/number-util';
import { CandidateOpportunitiesStore } from '../../stores/candidate-opportunities-store';
import { EnvUtil } from '../../utils/env-util';

import TalentProfileStatus, { REASON_FOR_NO_EDIT } from './TalentProfileStatus';
import { TalentProfileStatusEdit } from './TalentProfileStatusEdit';
import {
  ButtonFlagIconWrapper,
  HeaderTopWrapper,
  DivHeaderTopLeftWrapper,
  Header,
  HeaderWrapper,
  Title,
  TPHStatusWrapper,
  TPHSubmissionMessage,
} from './TalentProfileHeaderStyles';

const TalentProfileHeaderComponent = ({
  isDetail,
  onClickPopOut,
  searchTerm,
  setTalentIsFlagged,
  talent,
  user,
  usersById,
  nextAvailableStatuses,
  hideTitleBar,
}) => {
  const [editStatus, setEditStatus] = useState({
    canEdit: false,
    reason: REASON_FOR_NO_EDIT.CAN_NOT_CHANGE,
  });

  useEffect(() => {
    const hasPermissions = isTalentSuccessUser(user);
    const canEdit = hasPermissions && nextAvailableStatuses.length > 0;

    let reason = null;
    if (!canEdit) {
      reason = hasPermissions
        ? REASON_FOR_NO_EDIT.CAN_NOT_CHANGE
        : REASON_FOR_NO_EDIT.NO_PERMISSION;
    }

    setEditStatus({
      canEdit,
      reason,
    });

    return () => FormTalentProfileStatusStore.cancel();
  }, [user, nextAvailableStatuses]);

  return (
    <DataTest value="talentTabHeader">
      {!hideTitleBar && (
        <HeaderWrapper profileStatus={talent.profileStatus}>
          <HeaderTopWrapper>
            <DivHeaderTopLeftWrapper>
              <DarkTheme backgroundName="none">
                {!!talent.isFlagged && (
                  <Gutter bottom="0.25rem" name="FLAGGED_TALENT_MESSAGE">
                    <Text size="small" className="font-style-italic">
                      Please reach out to the Talent Owner before proceeding.
                    </Text>
                    <Text size="small" className="font-style-italic">
                      Flagged by: {usersById[talent.flaggedBy]}{' '}
                      {DateUtil.displayTimestamp(talent.flaggedAt)}
                    </Text>
                  </Gutter>
                )}
              </DarkTheme>
            </DivHeaderTopLeftWrapper>
          </HeaderTopWrapper>
          <DarkTheme backgroundName="none">
            <Header>
              <Title>
                <div className="text-align-middle">
                  <ButtonFlagIconWrapper
                    data-test={formatDataTestId('flag_talent_button')}
                    data-value={!!talent.isFlagged}
                    disabled={isSalesUser(user)}
                    onClick={() => {
                      setTalentIsFlagged({
                        candidateId: talent.id,
                        isFlagged: !talent.isFlagged,
                        user,
                        filters: WindowUtil.getUrlQuery(),
                        page: window.location.href,
                      });
                    }}
                  >
                    <FlagIcon filled={!!talent.isFlagged} />
                  </ButtonFlagIconWrapper>
                  <Layout position="middle" horizontalGutter="16px">
                    <LayoutItem>
                      <Header3 as="span" name="talentName">
                        {talent.calculatedDisplayName}
                      </Header3>
                    </LayoutItem>
                    <LayoutItem fluid>
                      {talent.pronouns && (
                        <Paragraph name="CANDIDATE_PRONOUNS">
                          {DisplayPronouns[talent.pronouns]}
                        </Paragraph>
                      )}
                    </LayoutItem>
                  </Layout>
                </div>
              </Title>
              <Gutter right="8px">
                <IconButton
                  icon="tableau"
                  inverse
                  pattern="secondary"
                  variation="minimal"
                  toTab={`${EnvUtil.tableauSimilarTalentUrl}?${qs.stringify({
                    TNP: talent.calculatedDisplayName,
                  })}`}
                  title="Open Tableau to find similar talent"
                />
              </Gutter>
              {!!onClickPopOut && !isDetail && (
                <IconButton
                  icon="popout"
                  inverse
                  pattern="secondary"
                  variation="minimal"
                  toTab={
                    searchTerm && searchTerm.length >= 2
                      ? `/talent-detail/${talent.id}?search=${searchTerm}`
                      : `/talent-detail/${talent.id}`
                  }
                  title={`Open "${talent.calculatedDisplayName}" in new tab`}
                />
              )}
            </Header>
          </DarkTheme>
        </HeaderWrapper>
      )}
      <FormStateTreatment
        allowOverflow
        name="PROFILE_STATUS_TREATMENT"
        formStore={FormTalentProfileStatusStore}
        renderNonLoadedView={() => (
          <Gutter vertical="24px" horizontal="16px">
            <Layout position="space-between top">
              <LayoutItem rightGutter="16px">
                <Label>Current Availability</Label>
                <Layout position="middle">
                  <IconButton
                    pattern="secondary"
                    variation="minimal"
                    icon="clock"
                    onClick={() =>
                      CandidateAvailabilityModalStore.openModal(talent.id)
                    }
                    title="Show availability calculation"
                    name="AVAILABILITY_MODAL_CLOCK_ICON"
                  />
                  <span>
                    {NumberUtil.formatAsHours(talent.weeklyAvailability)}
                  </span>
                </Layout>
              </LayoutItem>
              <LayoutItem rightGutter="16px">
                <TalentProfileStatus
                  usersById={usersById}
                  talent={talent}
                  editStatus={editStatus}
                />
              </LayoutItem>
              <LayoutItem>
                <Label>Proactive Submissions</Label>
                <TPHSubmissionMessage
                  isProfileShared={talent.isProfileShared}
                  data-test={formatDataTestId('submission_message')}
                >
                  {talent.isProfileShared ? 'Open' : 'Not Open'}
                </TPHSubmissionMessage>
              </LayoutItem>
              {!!editStatus.canEdit && (
                <LayoutItem leftGutter="16px">
                  <TPHStatusWrapper>
                    <IconButton
                      icon="pencil"
                      pattern="secondary"
                      variation="minimal"
                      onClick={() => FormTalentProfileStatusStore.load(talent)}
                      name="EDITPROFILESTATUSBUTTON"
                    />
                  </TPHStatusWrapper>
                </LayoutItem>
              )}
            </Layout>
          </Gutter>
        )}
        renderLoadedView={({ fireSubmit, fireCancel, formData }) => (
          <TalentProfileStatusEdit
            nextAvailableStatuses={nextAvailableStatuses}
            submit={fireSubmit}
            cancel={fireCancel}
            formData={formData}
          />
        )}
      />
    </DataTest>
  );
};

TalentProfileHeaderComponent.defaultProps = {
  onClickPopOut: undefined,
  searchTerm: undefined,
  isDetail: false,
  nextAvailableStatuses: [],
  hideTitleBar: false,
};

TalentProfileHeaderComponent.propTypes = {
  hideTitleBar: bool,
  isDetail: bool,
  nextAvailableStatuses:
    CandidateOpportunitiesStore.getNextAvailableStatusesShape(),
  searchTerm: string,
  setTalentIsFlagged: func.isRequired,
  talent: talentShape.isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
  usersById: shape({}).isRequired,
  onClickPopOut: func,
};

export const TalentProfileHeader = connect(
  state => ({
    usersById: fullNameByIdSelector(state),
  }),
  { setTalentIsFlagged: setTalentIsFlaggedAction }
)(TalentProfileHeaderComponent);
