import { Experience, Opportunity, Position } from '@axiom/validation';

export const CandidateExperienceUtil = {
  sortExperiences(experiences: Experience[]) {
    const sort = (a: Experience, b: Experience) =>
      (a.startDate || '') > (b.startDate || '') ? -1 : 1;

    return experiences
      .sort(sort)
      .map(exp => ({
        ...exp,
      }))
      .reduce(
        (data, exp) => {
          data[exp.isAxiom ? 'axiom' : 'other'].push(exp);

          return data;
        },
        { axiom: [], other: [] }
      );
  },

  doesExperienceContainSearch(experience: Experience, search: string) {
    return (
      this.searchExperience(experience, search) ||
      this.searchOpportunity(experience.opportunity, search) ||
      this.searchPosition(experience.opportunity?.position, search)
    );
  },

  searchExperience(experience: Experience, search: string) {
    const searchableFieldKeys = [
      'client',
      'description',
      'externalOpportunityName',
      'locationName',
    ];

    return this.searchFields(experience, search, searchableFieldKeys);
  },

  searchOpportunity(opportunity: Opportunity, search: string) {
    const opportunitySearchableFieldKeys = [
      'jobName',
      'locationCityStateCountry',
    ];

    return this.searchFields(
      opportunity,
      search,
      opportunitySearchableFieldKeys
    );
  },

  searchPosition(position: Position, search: string) {
    const positionSearchableFieldKeys = ['worksite'];

    return this.searchFields(position, search, positionSearchableFieldKeys);
  },

  searchFields(
    object: Record<string, unknown>,
    search: string,
    fields: string[]
  ) {
    if (!object) {
      return false;
    }

    return fields.some((key: string) => {
      const data = object[key];

      if (typeof data !== 'string') {
        return false;
      }

      if (!data) return false;

      const searchParts = search
        .split(/[\s-/$()*+.?[\\\]^{|}]/)
        .filter((p: string) => !!p && p.length > 2);
      const fieldParts = data.split(/[\s-/$()*+.?[\\\]^{|}]/);

      return searchParts.some((sp: string) =>
        fieldParts.some(fp => fp.toLowerCase().includes(sp.toLowerCase()))
      );
    });
  },
};
