import React, { FC, PropsWithChildren, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useApi } from '@axiom/ui';

import { TalentActivitiesApi } from '../../api/talent-activities-api';
import { getDecoratedTalent } from '../../redux/actions/talent';
import { makeTalentViewSelector } from '../../redux/selectors/talent-profile-component-selector';
import { TalentProfile } from '../TalentProfile/TalentProfile';

interface OpportunityTalentCandidateProfileProps extends PropsWithChildren {
  candidateId: string;
}

const OpportunityTalentCandidateProfileComponent: FC<
  OpportunityTalentCandidateProfileProps
> = ({ candidateId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDecoratedTalent(candidateId));
  }, [candidateId]);

  const [{ data: events }] = useApi(
    TalentActivitiesApi.readActivities(candidateId)
  );

  return (
    <TalentProfile
      candidateId={candidateId}
      expanded
      isDetail
      onCloseSidedrawer={() => {}}
      hideTitleBar
      events={{
        eventSubject: 'Candidate',
        eventSubjectId: candidateId,
        events,
      }}
    />
  );
};

export const OpportunityTalentCandidateProfile = connect(
  makeTalentViewSelector
)(OpportunityTalentCandidateProfileComponent);
