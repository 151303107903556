import { AccountModel } from '../models/account';
import GenericStore from '../classes/generic-store';
import {
  getAccount,
  removeContactFromAccount,
} from '../api/accounts-legacy-api';

class AccountStoreClass extends GenericStore {
  load(id) {
    return this.setState(getAccount(id));
  }

  removeContactFromAccount(contactId, accountId) {
    return removeContactFromAccount({ accountId, contactId }).then(() => {
      this.load(accountId);
    });
  }

  getDataShape() {
    return AccountModel;
  }
}

export const AccountStore = new AccountStoreClass();
