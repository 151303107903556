import React from 'react';
import { connect } from 'react-redux';
import { CandidateOpportunitiesConst } from '@axiom/const';
import { CandidateOpportunityUtils } from '@axiom/utils';
import { bool } from 'prop-types';
import {
  Button,
  CheckmarkCircleIcon,
  DataTest,
  DataTestUtil,
  ExclamationMarkCircleIcon,
  Label,
  Text,
  Tooltip,
  WarningIcon,
} from '@axiom/ui';
import { isNumber } from 'lodash';

import {
  AlignRight,
  Col,
  InlineCol,
  InlineRow,
  Row,
} from '../../themes/components';
import HorizontalRule from '../HorizontalRule/HorizontalRule';
import UnorderedList from '../UnorderedList/UnorderedList';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import RadioButton from '../RadioButton/RadioButton';
import { NumberUtil } from '../../utils/number-util';
import { EditSubmissionRateStore } from '../../stores/edit-submission-rate-store';
import { EditSubmissionStore } from '../../stores/edit-submission-store';
import { formatDataTestId } from '../../utils/data-test-util';
import { LoadingCurtain } from '../LoadingCurtain/LoadingCurtain';
import { PreloadedFeatureFlagsStore } from '../../stores/preloaded-feature-flags-store';
import { talentShape } from '../../models/talent';
import { EnvUtil } from '../../utils/env-util';

import {
  SMSCExperiencesDescription,
  SMSCProposedCostWrapper,
  SMSCRateWrapper,
} from './SubmissionModalSelectedCandidateStyles';
import {
  CPQWrapper,
  CPQCol,
  CPQLabel,
  SubmissionModalDataLabel,
} from './SubmissionModalStyles';
import { SubmissionModalProposedRateInput } from './SubmissionModalProposedRateInput';

const proposedRadioNames = {
  proposedHourlyCompensation: 'proposedHourlyCompensation',
  proposedMarginTarget: 'proposedMarginTarget',
  proposedHourlyRate: 'proposedHourlyRate',
};

const calcsByPropName = {
  [proposedRadioNames.proposedHourlyCompensation]:
    CandidateOpportunityUtils.calculateProposedHourlyComp,
  [proposedRadioNames.proposedMarginTarget]:
    CandidateOpportunityUtils.calculateProposedMarginTarget,
  [proposedRadioNames.proposedHourlyRate]:
    CandidateOpportunityUtils.calculateProposedHourlyRate,
};
const displayBillingOptions = {
  hourly: CandidateOpportunitiesConst.DisplayBillingRate.hourly,
  daily: CandidateOpportunitiesConst.DisplayBillingRate.daily,
  weekly: CandidateOpportunitiesConst.DisplayBillingRate.weekly,
  none: CandidateOpportunitiesConst.DisplayBillingRate.none,
};

class SubmissionModalCandidateRate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProposedRadio: proposedRadioNames.proposedHourlyRate,
    };
  }

  onProposedRadioSelected = value => {
    this.setState({ selectedProposedRadio: value });
  };

  onProposedInputChanged = (name, evt, editData) => {
    const { selectedProposedRadio } = this.state;

    const newValue = Number.parseFloat(evt.target.value);
    const newFormValues = {
      ...editData,
      [proposedRadioNames[name]]: newValue,
    };

    EditSubmissionRateStore.setState({
      ...newFormValues,
      [selectedProposedRadio]:
        calcsByPropName[selectedProposedRadio](newFormValues),
    });
  };

  onDisplayBillingChanged = (value, editData) => {
    EditSubmissionRateStore.setState({
      ...editData,
      displayBillingRate: value,
    });
  };

  onResetProposed = editData => {
    EditSubmissionRateStore.setState({
      ...editData,
      ...CandidateOpportunityUtils.getDefaultProposedFromPosition(editData),
    });
    this.setState({
      selectedProposedRadio: proposedRadioNames.proposedHourlyRate,
    });
  };

  isResetButtonDisabled = editData => {
    const candidateDefaults =
      CandidateOpportunityUtils.getDefaultProposedFromPosition(editData);
    return (
      candidateDefaults.proposedHourlyCompensation ===
        editData.proposedHourlyCompensation &&
      candidateDefaults.proposedMarginTarget ===
        editData.proposedMarginTarget &&
      candidateDefaults.proposedHourlyRate === editData.proposedHourlyRate
    );
  };

  getMarginIndicator = editSubmission => {
    if (
      !editSubmission.marginApprovalFloor ||
      !editSubmission.marginApprovalCeiling
    ) {
      return null;
    }
    const underMarginMessage = `Proposed margin is below the approval threshold of ${editSubmission.marginApprovalFloor}%. Please follow the approval process for review and be ready to explain why you are requesting the lower margin.`;
    const withinMarginMessage = `Within target margin range. Approval not required above ${editSubmission.marginApprovalFloor}%.`;
    const overMarginMessage = `Proposed margin is above the approval threshold of ${editSubmission.marginApprovalCeiling}%. Please follow the approval process for review and be ready to explain why you are requesting the higher margin.`;
    if (CandidateOpportunityUtils.isOverMargin(editSubmission)) {
      return (
        <InlineRow>
          <InlineCol>
            <ExclamationMarkCircleIcon checked name="MARGIN_OVER_GUIDELINES" />
          </InlineCol>
          <InlineCol>{overMarginMessage}</InlineCol>
        </InlineRow>
      );
    }
    if (CandidateOpportunityUtils.isWithinMargin(editSubmission)) {
      return (
        <InlineRow>
          <InlineCol>
            <CheckmarkCircleIcon checked name="MARGIN_WITHIN_GUIDELINES" />
          </InlineCol>
          <InlineCol>{withinMarginMessage}</InlineCol>
        </InlineRow>
      );
    }

    return (
      <InlineRow>
        <InlineCol>
          <ExclamationMarkCircleIcon name="MARGIN_OUTSIDE_GUIDELINES" />
        </InlineCol>
        <InlineCol>{underMarginMessage}</InlineCol>
      </InlineRow>
    );
  };

  renderWithCurrency = value =>
    NumberUtil.formatCurrency(value, this.props.submission.currency);

  renderBody(editSubmission) {
    const {
      submission: { currency },
      readOnly,
      featureFlags,
      candidate,
    } = this.props;

    const cpqGuidesUnavailable =
      !editSubmission.marginFloorPct ||
      !editSubmission.marginTargetPct ||
      !editSubmission.marginUpperPct ||
      !editSubmission.marginApprovalFloor ||
      !editSubmission.marginApprovalCeiling;

    const { selectedProposedRadio } = this.state;

    const talentHourlyCompTooltip = `How it's calculated: Salary (${NumberUtil.formatNumberAsDecimal(
      candidate.candidateBaseAnnualSalary
    )}) / Work Schedule (${
      candidate.candidateWorkscheduleAnnualHours
    }) * Premium Multiple (${NumberUtil.formatNumberAsDecimal(
      candidate.productPremiumRateMultiple
    )}) * Exchange Rate (${NumberUtil.formatNumberAsDecimal(
      candidate.exchangeRate,
      4
    )}) = Talent Hourly Comp (${
      CandidateOpportunityUtils.baseHourlyUnavailable(editSubmission)
        ? 'Unavailable'
        : NumberUtil.formatNumberAsDecimal(
            editSubmission.baseHourlyCompensation
          )
    })`;

    const talentProposedHourlyTooltip = `How this is used: 
    Proposed Hourly Comp (${NumberUtil.formatNumberAsDecimal(
      editSubmission.proposedHourlyCompensation
    )}) * 
    Bonus Multiple (${NumberUtil.formatNumberAsDecimal(
      candidate.candidateBonusPercentage / 100 + 1,
      4
    )}) = 
    Hourly OTE (${NumberUtil.formatNumberAsDecimal(
      editSubmission.proposedHourlyCompensation *
        (candidate.candidateBonusPercentage / 100 + 1)
    )}) * 
    Burden Multiple (${NumberUtil.formatNumberAsDecimal(
      candidate.productBurdenRateMultiple
    )}) = 
    Axiom’s Total Hourly Cost (${NumberUtil.formatNumberAsDecimal(
      editSubmission.proposedHourlyCompensation *
        (candidate.candidateBonusPercentage / 100 + 1) *
        candidate.productBurdenRateMultiple
    )})`;

    return (
      <SMSCRateWrapper>
        <SMSCExperiencesDescription>
          <DataTest value="MINPAYREQMESSAGE">
            {/*
              This should only show on meetsMinPayRequirements === false.
              True indicates they passed the check, and null means we don't have data yet.
            */}
            {PreloadedFeatureFlagsStore.isMinPayCheckEnabled(featureFlags) &&
            editSubmission.meetsMinPayRequirements === false ? (
              <InlineRow style={{ marginTop: '0.5rem' }}>
                <InlineCol>
                  <WarningIcon />
                </InlineCol>
                <InlineCol>
                  This talent has a guaranteed pay requirement and the position
                  you are adding them to does not meet the minimum reserved
                  hours.
                </InlineCol>
              </InlineRow>
            ) : null}
          </DataTest>
          <DataTest value="CONTRACTORREQMESSAGE">
            {editSubmission.consultant1099UtilizationWarning === true ? (
              <InlineRow style={{ marginTop: '0.5rem' }}>
                <InlineCol>
                  <WarningIcon />
                </InlineCol>
                <InlineCol>
                  Engagement of Independent Contractors for more than 30+ hours
                  is not allowed except in very exceptional circumstances which
                  requires pre-approval.
                </InlineCol>
              </InlineRow>
            ) : null}
          </DataTest>
        </SMSCExperiencesDescription>
        <Row>
          <Col>
            <UnorderedList
              dataTestId={DataTestUtil.format('TALENTHOURLYCOMP')}
              title={
                <>
                  Talent Hourly Comp{' '}
                  <span className="float-right talent-hourly-tooltip">
                    <Tooltip>{talentHourlyCompTooltip}</Tooltip>
                  </span>
                </>
              }
              values={
                CandidateOpportunityUtils.baseHourlyUnavailable(editSubmission)
                  ? 'Unavailable'
                  : this.renderWithCurrency(
                      editSubmission.baseHourlyCompensation
                    )
              }
            />
          </Col>
          <Col>
            <UnorderedList
              title="Compensation Type"
              values={CandidateOpportunityUtils.getCompensationType(
                editSubmission,
                editSubmission.position
              )}
            />
          </Col>
          <Col>
            {!readOnly && (
              <Button
                pattern="secondary"
                name="Get Talent Comp"
                onClick={() =>
                  EditSubmissionRateStore.getBurdenedCost(
                    editSubmission.opportunityId,
                    editSubmission.candidateId,
                    editSubmission.submissionId
                  )
                }
              >
                Get Talent Comp
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <SMSCProposedCostWrapper>
              <SubmissionModalProposedRateInput
                candidate={editSubmission}
                selectedProposedRadio={selectedProposedRadio}
                propName="proposedHourlyCompensation"
                propLabel={`Proposed Hourly Comp (${currency})`}
                appendRadio={<Tooltip>{talentProposedHourlyTooltip}</Tooltip>}
                onProposedRadioSelected={this.onProposedRadioSelected}
                onProposedInputChanged={this.onProposedInputChanged}
                readOnly={
                  CandidateOpportunityUtils.baseHourlyUnavailable(
                    editSubmission
                  ) ||
                  readOnly ||
                  cpqGuidesUnavailable
                }
              />
            </SMSCProposedCostWrapper>
          </Col>
          <Col>
            <SubmissionModalProposedRateInput
              candidate={editSubmission}
              selectedProposedRadio={selectedProposedRadio}
              propName="proposedMarginTarget"
              propLabel="Proposed Margin (%)"
              onProposedRadioSelected={this.onProposedRadioSelected}
              onProposedInputChanged={this.onProposedInputChanged}
              readOnly={
                CandidateOpportunityUtils.baseHourlyUnavailable(
                  editSubmission
                ) ||
                readOnly ||
                cpqGuidesUnavailable
              }
            />
          </Col>
          <Col>
            <SubmissionModalProposedRateInput
              candidate={editSubmission}
              selectedProposedRadio={selectedProposedRadio}
              propName="proposedHourlyRate"
              propLabel={`Proposed Hourly Rate (${currency})`}
              onProposedRadioSelected={this.onProposedRadioSelected}
              onProposedInputChanged={this.onProposedInputChanged}
              readOnly={
                CandidateOpportunityUtils.baseHourlyUnavailable(
                  editSubmission
                ) ||
                readOnly ||
                cpqGuidesUnavailable
              }
            />
          </Col>
        </Row>
        <CPQWrapper>
          <CPQCol>
            <CPQLabel>CPQ Targets:</CPQLabel>
          </CPQCol>
          <CPQCol>
            {cpqGuidesUnavailable
              ? '-'
              : `${editSubmission.marginFloorPct}% - ${editSubmission.marginUpperPct}%`}
          </CPQCol>
        </CPQWrapper>

        <InlineRow style={{ width: '100%', justifyContent: 'space-between' }}>
          <InlineCol>{this.getMarginIndicator(editSubmission)}</InlineCol>
          <InlineCol>
            {!readOnly && (
              <Button
                name="Reset"
                pattern="secondary"
                variation="outline"
                disabled={this.isResetButtonDisabled(editSubmission)}
                onClick={() => this.onResetProposed(editSubmission)}
              >
                Reset
              </Button>
            )}
          </InlineCol>
        </InlineRow>
        <HorizontalRule marginTop="2rem" marginBottom="2rem" />
        <SMSCExperiencesDescription>
          Select how you want to display the talent rate to the client. Note
          whether the rate is within CPQ guidelines - if it isn’t, approval will
          be required prior to contracting.
        </SMSCExperiencesDescription>
        <Row>
          <Col>
            <SubmissionModalDataLabel>
              <Label>
                Hourly Rate
                <RadioButton
                  name="displayBillingRateHourlyRadio"
                  domID="displayBillingRateHourlyRadio"
                  checked={
                    editSubmission.displayBillingRate ===
                    displayBillingOptions.hourly
                  }
                  value={displayBillingOptions.hourly}
                  onChange={({ target: { value } }) =>
                    this.onDisplayBillingChanged(value, editSubmission)
                  }
                  disabled={readOnly}
                />
              </Label>
            </SubmissionModalDataLabel>
            <DataTest value="DISPLAY_HOURLY_RATE">
              <Text>
                {this.renderWithCurrency(editSubmission.proposedHourlyRate)}
              </Text>
            </DataTest>
          </Col>
          <Col>
            <SubmissionModalDataLabel>
              <Label>
                Daily Rate
                <RadioButton
                  name="displayBillingRateDailyRadio"
                  domID="displayBillingRateDailyRadio"
                  checked={
                    editSubmission.displayBillingRate ===
                    displayBillingOptions.daily
                  }
                  value={displayBillingOptions.daily}
                  onChange={({ target: { value } }) =>
                    this.onDisplayBillingChanged(value, editSubmission)
                  }
                  disabled={readOnly}
                />
              </Label>
            </SubmissionModalDataLabel>
            <DataTest value="DISPLAY_DAILY_RATE">
              <Text>
                {this.renderWithCurrency(
                  editSubmission.proposedHourlyRate *
                    editSubmission.position.billingHoursPerDay
                )}
              </Text>
              <SubmissionModalDataLabel>
                <Text size="small" className="font-style-italic">
                  (Hourly Rate x{' '}
                  <DataTest value="DISPLAY_BILLING_HOURS_PER_DAY" inline>
                    {editSubmission.position.billingHoursPerDay}
                  </DataTest>
                  )
                </Text>
              </SubmissionModalDataLabel>
            </DataTest>
          </Col>
          <Col>
            <SubmissionModalDataLabel>
              <Label>
                Weekly Rate
                <RadioButton
                  name="displayBillingRateWeeklyRadio"
                  domID="displayBillingRateWeeklyRadio"
                  checked={
                    editSubmission.displayBillingRate ===
                    displayBillingOptions.weekly
                  }
                  value={displayBillingOptions.weekly}
                  onChange={({ target: { value } }) =>
                    this.onDisplayBillingChanged(value, editSubmission)
                  }
                  disabled={readOnly}
                />
              </Label>
            </SubmissionModalDataLabel>
            <DataTest value="DISPLAY_WEEKLY_RATE">
              <Text>
                {this.renderWithCurrency(
                  editSubmission.proposedHourlyRate *
                    (editSubmission.position.billingHoursPerDay * 5)
                )}
              </Text>
              <SubmissionModalDataLabel>
                <Text size="small" className="font-style-italic">
                  (Daily Rate x 5)
                </Text>
              </SubmissionModalDataLabel>
            </DataTest>
          </Col>
          <Col>
            <SubmissionModalDataLabel>
              <Label>
                Don't display value
                <RadioButton
                  name="displayBillingRateNoneRadio"
                  domID="displayBillingRateNoneRadio"
                  checked={
                    editSubmission.displayBillingRate ===
                    displayBillingOptions.none
                  }
                  value={displayBillingOptions.none}
                  onChange={({ target: { value } }) =>
                    this.onDisplayBillingChanged(value, editSubmission)
                  }
                  disabled={readOnly}
                />
              </Label>
            </SubmissionModalDataLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <AlignRight>
              {!readOnly && (
                <Button
                  pattern="secondary"
                  disabled={
                    CandidateOpportunityUtils.baseHourlyUnavailable(
                      editSubmission
                    ) ||
                    !isNumber(editSubmission.proposedHourlyCompensation) ||
                    !isNumber(editSubmission.proposedHourlyRate) ||
                    !isNumber(editSubmission.proposedMarginTarget) ||
                    cpqGuidesUnavailable
                  }
                  name="Set Rate"
                  onClick={() => {
                    EditSubmissionRateStore.save(editSubmission);
                  }}
                >
                  Set Rate
                </Button>
              )}
            </AlignRight>
          </Col>
        </Row>
      </SMSCRateWrapper>
    );
  }

  render() {
    const { editRateState } = this.props;

    return (
      <StoreStateTreatment
        name="SUBMISSION_RATE_SECTION"
        storeState={editRateState}
        refetchLoader={
          <LoadingCurtain
            loadingText="Gathering submission data. This may take a few seconds..."
            isFullscreen
          />
        }
        renderLoadedView={({ data }) => this.renderBody(data)}
      />
    );
  }
}

SubmissionModalCandidateRate.propTypes = {
  candidate: talentShape.isRequired,
  editRateState: EditSubmissionRateStore.getStateShape().isRequired,
  featureFlags: PreloadedFeatureFlagsStore.getDataShape().isRequired,
  readOnly: bool.isRequired,
  submission: EditSubmissionStore.getDataShape().isRequired,
};

export default connect(state => ({
  editRateState: EditSubmissionRateStore.select(state),
  featureFlags: PreloadedFeatureFlagsStore.select(state),
}))(SubmissionModalCandidateRate);
