import { TagShape } from '../models/tag';
import { addTag, updateTag } from '../api/tags-api';
import { AjvUtil } from '../utils/ajv-util';
import FormStore from '../classes/form-store';

import { PreloadedTagsStore } from './preloaded-tags-store';

const TagValidation = AjvUtil.compileSchema({
  required: ['name'],
  properties: {
    name: {
      type: 'string',
      maxLength: 255,
      minLength: 1,
    },
  },
});

class FormTagsManagementStoreClass extends FormStore {
  getDataShape() {
    return TagShape;
  }

  load(tag = {}) {
    /**
     * The preloaded tag store puts these in an off format for some reason....
     * so converting back to what server expects
     */
    return this.setState({
      id: tag.value || null,
      name: tag.label || null,
      type: tag.type || null,
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(TagValidation, formData);
  }

  submit(changedFields, formData) {
    if (formData.id) {
      this.clearState(
        updateTag(formData.id, changedFields).then(() =>
          PreloadedTagsStore.load()
        )
      );
    } else {
      this.clearState(
        addTag({ ...changedFields, type: 'tag' }).then(() =>
          PreloadedTagsStore.load()
        )
      );
    }
  }
}

export const FormTagsManagementStore = new FormTagsManagementStoreClass();
