import { reducer as form } from 'redux-form';

import app from './app';
import bannerMessages from './banner-messages';
import barredLocations from './barred-locations';
import groupManagementView from './group-management-view';
import groups from './groups';
import homeOffices from './home-offices';
import opportunityCandidates from './opportunit-candidates';
import opportunityCollaborators from './opportunity-collaborators';
import opportunityLanguages from './opportunity-languages';
import opportunities from './opportunities';
import languages from './languages';
import searches from './searches';
import notifications from './notifications';
import talent from './talent';
import talentBarredLocations from './talent-barred-locations';
import talentCollaborators from './talent-collaborators';
import talentHomeOffices from './talent-home-offices';
import talentLanguages from './talent-languages';
import talentOpportunities from './talent-opportunities';
import talentTags from './talent-tags';
import userManagementView from './user-management-view';
import userOpportunities from './user-opportunities';
import users from './users';
import userTalent from './user-talent';

export default {
  app,
  bannerMessages,
  barredLocations,
  form,
  groupManagementView,
  groups,
  homeOffices,
  languages,
  notifications,
  opportunities,
  opportunityCandidates,
  opportunityCollaborators,
  opportunityLanguages,
  searches,
  talent,
  talentBarredLocations,
  talentCollaborators,
  talentHomeOffices,
  talentLanguages,
  talentOpportunities,
  talentTags,
  userManagementView,
  userOpportunities,
  users,
  userTalent,
};
