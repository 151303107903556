/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Tabs, TabsItem, Gutter, Suspense } from '@axiom/ui';
import { bool, string, func } from 'prop-types';
import { connect } from 'react-redux';
import {
  useLocation,
  useParams,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';

import TalentProfileDetailForm from '../TalentProfileDetail/TalentProfileDetail';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import ActivityLog from '../ActivityLog/ActivityLog';
import { CandidateExperience } from '../CandidateExperience/CandidateExperience';
import { talentFormInitialValues, talentShape } from '../../models/talent';
import { makeTalentViewSelector } from '../../redux/selectors/talent-profile-component-selector';
import { TALENT_EDIT } from '../../redux/constants';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { EventsStore } from '../../stores/events-store';
import { CandidateStore } from '../../stores/candidate-store';
import { CandidateOpportunitiesStore } from '../../stores/candidate-opportunities-store';
import { CandidateCollaboratorsStore } from '../../stores/candidate-collaborators-store';
import { WindowUtil } from '../../utils/window-util';
import { TalentOpportunityTab } from '../TalentOpportunityTab/TalentOpportunityTab';

import { TalentProfileHeader } from './TalentProfileHeader';

const TalentProfileComponent = ({
  expanded,
  isDetail,
  events,
  talent,
  user,
  eventsState,
  candidateState,
  candidateOpportunityState,
  collaboratorsState,
  hasSubmitted,
  onCloseSidedrawer,
  hideTitleBar = false,
}) => {
  const location = useLocation();
  const { id, viewId } = useParams();
  const navigate = useNavigate();

  let paramId = id;

  const renderTabs = (eventsData, opportunities) => {
    const isEnvoySalesUser = user.roles.includes('EnvoySales');

    const tabBodyProps = {
      engagements: talent.candidacyEngagements,
      form: TALENT_EDIT,
      hasSubmitted,
      initialValues: talentFormInitialValues(talent),
      isDetail,
      isEnvoySalesUser,
      mode: 'talent',
      talent,
    };

    let rootPath = 'talent';
    if (location.pathname?.includes('talent-detail')) {
      rootPath = 'talent-detail';
    } else if (location.pathname?.includes('bench')) {
      rootPath = 'bench';
    } else if (location.pathname?.includes('opportunity-detail')) {
      rootPath = `opportunity-detail/${id}/talent`;
      paramId = viewId;
    }

    const basePath = `/${rootPath}/${paramId}`;

    const getStartTab = () => {
      switch (true) {
        case location.pathname?.includes('/activitylog'): {
          return 'activity-log';
        }
        case location.pathname?.includes('/experience'): {
          return 'experience';
        }
        case location.pathname?.includes('/opportunities'): {
          return 'opportunities';
        }
        default: {
          return 'profile';
        }
      }
    };

    const goToTab = tabSlug => {
      navigate(WindowUtil.appendQueryParams(`${basePath}/${tabSlug}`));
    };

    return (
      <Tabs fluid scrollable stretched startTab={getStartTab()}>
        <TabsItem
          label="Profile"
          name="profile"
          onClick={() => goToTab('profile')}
        >
          <Gutter horizontal="16px">
            <TalentProfileDetailForm {...tabBodyProps} />
          </Gutter>
        </TabsItem>
        <TabsItem
          label="Opportunities"
          name="opportunities"
          onClick={() => goToTab('opportunities')}
        >
          <Gutter horizontal="16px" vertical="8px">
            <Suspense>
              <TalentOpportunityTab candidate={tabBodyProps.talent} />
            </Suspense>
          </Gutter>
        </TabsItem>
        <TabsItem
          label="Experience"
          name="experience"
          onClick={() => goToTab('experience')}
        >
          <Gutter horizontal="16px">
            <Suspense>
              <CandidateExperience candidate={talent} />
            </Suspense>
          </Gutter>
        </TabsItem>
        <TabsItem
          label="Activity Log"
          name="activity-log"
          onClick={() => goToTab('activitylog')}
        >
          <Gutter horizontal="16px">
            <ActivityLog
              eventsData={eventsData}
              opportunities={opportunities}
            />
          </Gutter>
        </TabsItem>
      </Tabs>
    );
  };

  if (!talent || !talent.id) {
    return (
      <p style={{ margin: '60px 30px', textAlign: 'center' }}>
        Please select a talent from the list.
      </p>
    );
  }

  if (expanded && !location.pathname.includes('opportunity-detail')) {
    WindowUtil.title(talent.calculatedDisplayName);
  }

  return (
    <StoreStateTreatment
      name="Sidedrawer_Store"
      storeState={[
        eventsState,
        candidateOpportunityState,
        candidateState,
        collaboratorsState,
      ]}
      stretched
      renderLoadedView={({ data: [eventsData, candidateOpportunityData] }) => (
        <>
          <StoreStateTreatment
            allowOverflow
            storeState={candidateOpportunityState}
            renderLoadedView={({ data: { nextAvailableStatuses } }) => (
              <TalentProfileHeader
                nextAvailableStatuses={nextAvailableStatuses}
                isDetail={isDetail}
                onClickPopOut={onCloseSidedrawer}
                talent={talent}
                user={user}
                hideTitleBar={hideTitleBar}
              />
            )}
          />
          <Routes>
            {[`/:profileView/:profileViewId`, `/:profileView`, '/'].map(p => (
              <Route
                key={p}
                path={p}
                element={renderTabs(
                  events ?? eventsData,
                  candidateOpportunityData.opportunities
                )}
              />
            ))}
          </Routes>
        </>
      )}
    />
  );
};

TalentProfileComponent.defaultProps = {
  expanded: false,
  isDetail: false,
  events: null,
  // connect
  dispatch: () => {},
  hasSubmitted: false,
  searchTerm: '',
  talent: null,
  user: null,
  candidateId: null,
  hideTitleBar: false,
};

TalentProfileComponent.propTypes = {
  candidateId: string,
  candidateOpportunityState:
    CandidateOpportunitiesStore.getStateShape().isRequired,
  candidateState: CandidateStore.getStateShape().isRequired,
  collaboratorsState: CandidateCollaboratorsStore.getStateShape().isRequired,
  // connect
  dispatch: func,
  events: EventsStore.getDataShape(),
  eventsState: EventsStore.getStateShape().isRequired,
  expanded: bool,
  hasSubmitted: bool,
  hideTitleBar: bool,
  isDetail: bool,
  searchTerm: string,
  talent: talentShape,
  user: PreloadedUserStore.getDataShape(),
  onCloseSidedrawer: func.isRequired,
};

export const TalentProfile = connect(makeTalentViewSelector)(
  TalentProfileComponent
);
