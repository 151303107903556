import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Gutter, Suspense, TabsItem, Tabs, useApi } from '@axiom/ui';

import { UsersApi } from '../../api/users-api';

import { WorkspaceGrid } from './WorkspaceGrid';
import { WorkspaceBenchReminders } from './WorkspaceBenchReminders';

export const tabNamePathMap: Record<string, string> = {
  opportunities: 'PENDO-TAB-OPPORTUNITIES',
  'bench-reminders': 'bench-reminders',
};

export const WorkspaceTabs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [{ data: user }] = useApi(UsersApi.getSessionUser());

  const goToTab = (tabSlug: string) => {
    navigate(`/workspace/${tabSlug}`);
  };

  const getStartTab = () => {
    for (const tab of Object.keys(tabNamePathMap)) {
      if (pathname.includes(`/${tab}`)) {
        return tabNamePathMap[tab];
      }
    }

    return tabNamePathMap.opportunities;
  };

  const isSalesUser =
    user.roles.includes('EnvoySales') && !user.roles.includes('EnvoyAdmin');

  const getTabs = () => {
    const tabs = [
      <TabsItem
        key="opportunities"
        name={tabNamePathMap.opportunities}
        label="My Opportunities"
        onClick={() => goToTab('opportunities')}
      >
        <Suspense stretched>
          <WorkspaceGrid user={user} />
        </Suspense>
      </TabsItem>,
    ];

    if (!isSalesUser) {
      tabs.push(
        <TabsItem
          key="bench-reminders"
          name={tabNamePathMap['bench-reminders']}
          label="Bench Reminders"
          onClick={() => goToTab('bench-reminders')}
        >
          <WorkspaceBenchReminders user={user} />
        </TabsItem>
      );
    }
    return tabs;
  };

  return (
    <Gutter top="16px">
      <Tabs
        name="tabs"
        startTab={isSalesUser ? tabNamePathMap.opportunities : getStartTab()}
        stretched
      >
        {getTabs()}
      </Tabs>
    </Gutter>
  );
};
