import {
  getDecoratedOpportunity,
  opportunitySaveSuccess,
} from '../redux/actions/opportunities';
import { putJob as putOpportunity } from '../api/jobs-api';
import { putCollaboratorsByOpportunityId } from '../api/opportunity-collaborators-api';
import { putLanguagesByOpportunityId } from '../api/opportunity-languages-api';
import { AjvUtil } from '../utils/ajv-util';
import GenericStore from '../classes/generic-store';

import { OpportunityStore } from './opportunity-store';

const FormOpportunityItemValidation = AjvUtil.compileSchema({
  required: ['id'],
  properties: {
    forecastCategory: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    id: {
      type: 'string',
      format: 'uuid',
    },
    ownerUserId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    practiceAreaId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    salesCloseDate: {
      type: ['string', 'null'],
      format: 'date',
    },
    salesforceCxLeadId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    salesLeadId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    useCase: {
      type: ['string', 'null'],
      maxLength: 255,
    },
  },
});

class EditOpportunityStoreClass extends GenericStore {
  load(opportunity) {
    this.setState(opportunity);
  }

  validate(data) {
    return AjvUtil.formatValidationForFinalForm(
      FormOpportunityItemValidation,
      data
    );
  }

  save(formData) {
    return this.clearState(
      Promise.all([
        putOpportunity(formData.id, {
          id: formData.id,
          salesCloseDate: formData.salesCloseDate,
          ownerUserId: formData.ownerUserId,
          salesLeadId: formData.salesLeadId,
          salesforceCxLeadId: formData.salesforceCxLeadId,
          forecastCategory: formData.forecastCategory,
          practiceAreaId: formData.practiceAreaId,
          useCase: formData.useCase,
        }),
        formData.collaboratorId
          ? putCollaboratorsByOpportunityId(
              formData.id,
              formData.collaboratorId
            )
          : null,
        formData.languages
          ? putLanguagesByOpportunityId(formData.id, formData.languages)
          : null,
      ]).then(([opportunity, collaboratorId, languages]) => {
        if (collaboratorId || languages) {
          this.dispatch(getDecoratedOpportunity(opportunity.data.id));
        }

        this.dispatch(opportunitySaveSuccess(opportunity.data));
        OpportunityStore.load(formData.id);

        return opportunity.data;
      })
    );
  }
}

export const EditOpportunityStore = new EditOpportunityStoreClass();
