import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalSection,
  Button,
} from '@axiom/ui';
import { func, shape } from 'prop-types';

import { talentModel } from '../../models/talent';
import { formatDataTestId } from '../../utils/data-test-util';

import { StyledTalentResume } from './TalentResumeStyles';

const TalentResume = ({ onCancel, talent }) => {
  const formatResume = resume => {
    const paragraphs = resume.split(/\r|\n/);
    return paragraphs.map(p => (p.length > 0 ? <p key={p}>{p}</p> : null));
  };

  return (
    <Modal size="large">
      <ModalHeader onClose={onCancel}>Resume</ModalHeader>
      <ModalSection>
        <StyledTalentResume data-test={formatDataTestId('resumeContainer')}>
          {talent && talent.resume ? (
            formatResume(talent.resume)
          ) : (
            <p>No resume on file.</p>
          )}
        </StyledTalentResume>
      </ModalSection>
      <ModalFooter>
        <Button onClick={onCancel}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

TalentResume.propTypes = {
  talent: shape(talentModel).isRequired,
  onCancel: func.isRequired,
};

export default TalentResume;
