import { shape, string, arrayOf } from 'prop-types';
import { EventsConst } from '@axiom/const';

import GenericStore from '../classes/generic-store';
import { opportunityShape } from '../models/opportunities-const';
import { positionShape } from '../models/position';
import { talentModel } from '../models/talent';
import {
  addCandidatesToOpportunity,
  addCandidatesToPosition,
  updateOpportunityCandidate,
} from '../api/opportunities-legacy-api';

/* eslint import/no-cycle:0 */
import { OpportunityStore } from './opportunity-store';

class EditOpportunityTalentTabStoreClass extends GenericStore {
  TALENT_POOL_ID = 'TALENT_POOL';

  extractRoleFitDescriptions = (candidates, events) => {
    const roleFitDescriptions = {};
    candidates.forEach(candidate => {
      const interestExpression = events.find(
        event =>
          event.candidateId === candidate.id &&
          event.purpose === EventsConst.Purposes.ExpressedInterest
      );
      if (interestExpression) {
        roleFitDescriptions[candidate.id] = interestExpression.description;
      }
    });
    return roleFitDescriptions;
  };

  load({ opportunity, candidates, positions, events }) {
    return this.setState({
      hoveredPositionId: null,
      opportunity,
      positions,
      talentPool: {
        id: this.TALENT_POOL_ID,
        candidates: candidates.filter(candidate => !candidate.positionId),
      },
      candidates,
      savedFilters: {},
      roleFitDescriptions: this.extractRoleFitDescriptions(candidates, events),
    });
  }

  getDataShape() {
    return shape({
      hoveredPositionId: string,
      opportunity: opportunityShape,
      positions: arrayOf(positionShape),
      talentPool: shape({
        id: string,
        candidates: arrayOf(shape(talentModel)),
      }),
      candidates: arrayOf(shape(talentModel)),
      savedFilters: shape({}),
      events: arrayOf(shape({})),
    });
  }

  saveCandidateToPosition({ opportunityId, candidateId, positionId }) {
    return this.watchState(
      addCandidatesToPosition({
        opportunityId,
        candidateIds: [candidateId],
        positionId,
      })
    ).then(() => {
      return OpportunityStore.updateBurdenedCost(
        opportunityId,
        candidateId
      ).then(() => {
        return OpportunityStore.load(opportunityId);
      });
    });
  }

  saveCandidateToOpportunity({ opportunityId, candidateId }) {
    return this.watchState(
      addCandidatesToOpportunity({
        opportunityId,
        candidateIds: [candidateId],
      })
    ).then(() => {
      return OpportunityStore.load(opportunityId);
    });
  }

  saveOpportunityCandidate({
    candidateId,
    opportunityId,
    positionId,
    ...props
  }) {
    return this.watchState(
      updateOpportunityCandidate({
        candidateId,
        opportunityId,
        positionId,
        ...props,
      })
    ).then(() => {
      if (positionId) {
        return OpportunityStore.updateBurdenedCost(opportunityId, candidateId);
      }
      return OpportunityStore.load(opportunityId);
    });
  }

  saveFilters(filters) {
    this.setState({
      savedFilters: filters,
    });
  }

  revertStateWithError(oldState, error) {
    return this.setState(oldState).then(() => this.setErrorState(error));
  }
}

export const EditOpportunityTalentTabStore =
  new EditOpportunityTalentTabStoreClass();
