import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Button,
  Paragraph,
} from '@axiom/ui';
import { connect } from 'react-redux';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { TalentWarningModalStore } from '../../stores/talent-warning-modal-store';
import { PreloadedFeatureFlagsStore } from '../../stores/preloaded-feature-flags-store';

export const TalentWarningModalsComponent = ({
  talentWarningModalState,
  featureFlags,
}) => {
  const onCancel = () => {
    TalentWarningModalStore.clearState();
  };
  return (
    <StoreStateTreatment
      isModalState
      name="TALENT_WARNING_MODALS_STATE"
      storeState={talentWarningModalState}
      renderLoadedView={({ data }) => {
        const { shouldWarnMinPay, shouldWarnContractor } = data;
        const minPayEnabled =
          PreloadedFeatureFlagsStore.isMinPayCheckEnabled(featureFlags);

        return (
          <>
            {!!shouldWarnContractor && (
              <Modal size="confirm">
                <ModalHeader onClose={onCancel}>Confirm Add</ModalHeader>
                <ModalSection>
                  <Paragraph>
                    Engagement of Independent Contractors for more than 30+
                    hours is not allowed except in very exceptional
                    circumstances which requires pre-approval.
                  </Paragraph>
                </ModalSection>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      TalentWarningModalStore.setState({
                        shouldWarnContractor: false,
                      });
                    }}
                    name="TALENT_CONTRACTOR_WARNING_MODAL_OK"
                  >
                    Okay
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            {!!shouldWarnMinPay && !!minPayEnabled && (
              <Modal size="confirm">
                <ModalHeader onClose={onCancel}>Confirm Add</ModalHeader>
                <ModalSection>
                  <Paragraph>
                    This talent has a guaranteed pay requirement and the
                    position you are adding them to does not meet the minimum
                    reserved hours.
                  </Paragraph>
                </ModalSection>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      TalentWarningModalStore.setState({
                        shouldWarnMinPay: false,
                      });
                    }}
                    name="TALENT_MIN_PAY_WARNING_MODAL_OK"
                  >
                    Okay
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </>
        );
      }}
    />
  );
};

TalentWarningModalsComponent.propTypes = {
  featureFlags: PreloadedFeatureFlagsStore.getStateShape().isRequired,
  talentWarningModalState: TalentWarningModalStore.getStateShape().isRequired,
};

export const TalentWarningModals = connect(state => ({
  talentWarningModalState: TalentWarningModalStore.select(state),
  featureFlags: PreloadedFeatureFlagsStore.select(state),
}))(TalentWarningModalsComponent);
