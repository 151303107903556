import React from 'react';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { CandidatesConst, PositionsConst, WeeklyTimeUnits } from '@axiom/const';
import {
  CalendarIcon,
  Gutter,
  Header3,
  Header5,
  Label,
  Text,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Button,
  IconButton,
  Tooltip,
  Layout,
  Badge,
  LayoutItem,
} from '@axiom/ui';

import { DateUtil } from '../../utils/date-util';
import { CandidateAvailabilityModalStore } from '../../stores/candidate-availability-modal-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { formatDataTestId } from '../../utils/data-test-util';

import {
  CurrentAvailabilityColumn,
  DesiredHoursColumn,
  EngagementListColumn,
  EngagementTable,
  ModalGridRow,
  RollOffIconWrapper,
} from './CurrentAvailabilityModalStyles';

const { Confirmed, NotConfirmed } = PositionsConst.EndDateStatuses;

const getUpdateDateString = updatedAt =>
  updatedAt ? DateUtil.displayShortDate(updatedAt) : 'Profile default';

const getDateWithIcon = (date, dateStatus, dataTestId) => (
  <RollOffIconWrapper>
    <CalendarIcon
      withCheck={dateStatus === Confirmed}
      withCross={dateStatus === NotConfirmed}
    />
    <div className="data" data-test={formatDataTestId(dataTestId)}>
      <Header5>{getUpdateDateString(date)}</Header5>
    </div>
  </RollOffIconWrapper>
);

const CurrentAvailabilityModal = ({ currentAvailabilityModalState }) => (
  <StoreStateTreatment
    name="current_availability_modal_state"
    isModalState
    storeState={currentAvailabilityModalState}
    renderLoadedView={({
      data: {
        calculatedDisplayName,
        weeklyAvailability,
        currentAvailabilityUpdatedAt,
        calculatedDesiredWeeklyHours,
        desiredHoursUpdatedAt,
        soonestEngagementEndDate,
        soonestEngagementEndDateStatus,
        activeEngagements,
        calculatedEngagedAndReservedHours,
      },
    }) => {
      let totalEngagementHours = 0;
      const engagedHoursBadgeColor =
        calculatedEngagedAndReservedHours?.totalEngagedHours >=
        CandidatesConst.EngagedHoursWarningLimit
          ? 'red'
          : 'gray';
      const reservedHoursBadgeColor =
        calculatedEngagedAndReservedHours?.totalReservedHours >=
        CandidatesConst.ReservedHoursWarningLimit
          ? 'red'
          : 'gray';
      const displayEngagements = Array.from({
        length: Math.max(activeEngagements.length, 6),
      })
        .fill(null)
        .map((nil, index) => {
          if (activeEngagements[index]) {
            const engagement = activeEngagements[index];
            const weeklyEngagementHours =
              get(engagement, 'position.weeklyTimeUnit', '') ===
              WeeklyTimeUnits.Hourly
                ? get(engagement, 'position.billingUnitsPerWeek', 0)
                : get(engagement, 'position.billingUnitsPerWeek', 0) *
                  get(engagement, 'position.billingHoursPerDay', 0);

            totalEngagementHours += weeklyEngagementHours;

            return {
              ...engagement,
              weeklyEngagementHours,
            };
          }

          return nil;
        });

      return (
        <Modal name="AVAILABILITY_MODAL" size="large">
          <ModalHeader
            onClose={() => CandidateAvailabilityModalStore.closeModal()}
          >
            Current Availability
          </ModalHeader>
          <ModalSection>
            <Gutter bottom="1rem">
              <Layout direction="horizontal" position="space-between">
                <LayoutItem>
                  <Header3 name="DISPLAY_NAME">{calculatedDisplayName}</Header3>
                </LayoutItem>

                <Gutter right="16px">
                  <Layout direction="horizontal" position="space-around">
                    <LayoutItem>
                      <Badge background={engagedHoursBadgeColor}>
                        Engaged:{' '}
                        {calculatedEngagedAndReservedHours.totalEngagedHours}{' '}
                        hrs
                      </Badge>
                    </LayoutItem>
                    <Gutter left="8px" />
                    <LayoutItem>
                      <Badge background={reservedHoursBadgeColor}>
                        Reserved:{' '}
                        {calculatedEngagedAndReservedHours.totalReservedHours}{' '}
                        hrs
                      </Badge>
                    </LayoutItem>
                  </Layout>
                </Gutter>
              </Layout>
            </Gutter>
            <ModalGridRow className="main-container">
              <CurrentAvailabilityColumn>
                <ModalGridRow>
                  <Header5>Current Availability</Header5>
                </ModalGridRow>
                <ModalGridRow
                  data-test={formatDataTestId('WEEKLY_AVAILABILITY_HOURS')}
                >
                  <Header3>
                    {weeklyAvailability > 0 ? (
                      <>{weeklyAvailability} hrs</>
                    ) : (
                      <>
                        0 hrs
                        <Tooltip>0 indicates none or negative</Tooltip>
                      </>
                    )}
                  </Header3>
                </ModalGridRow>
                <ModalGridRow
                  className="last-edited"
                  data-test={formatDataTestId('AVAILABILITY_UPDATED_AT')}
                >
                  <Text size="small" subtle>
                    Last Edited:{' '}
                    {getUpdateDateString(currentAvailabilityUpdatedAt)}
                  </Text>
                </ModalGridRow>
                <ModalGridRow className="expected-change">
                  <Header5>Expected Change</Header5>
                </ModalGridRow>
                <ModalGridRow className="expected-change__date">
                  {getDateWithIcon(
                    soonestEngagementEndDate,
                    soonestEngagementEndDateStatus,
                    'SOONEST_END_DATE'
                  )}
                </ModalGridRow>
              </CurrentAvailabilityColumn>
              <DesiredHoursColumn
                lastEdited={getUpdateDateString(desiredHoursUpdatedAt)}
              >
                <ModalGridRow>
                  <Header5>Desired Hours</Header5>
                </ModalGridRow>
                <ModalGridRow
                  className="desired-hours"
                  data-test={formatDataTestId('DESIRED_WEEKLY_HOURS')}
                >
                  <Header3>{calculatedDesiredWeeklyHours} hrs</Header3>
                </ModalGridRow>
                <ModalGridRow
                  className="updated-at"
                  data-test={formatDataTestId('DESIRED_HOURS_UPDATED_AT')}
                >
                  <Text size="small" subtle>
                    Last Edited: <br />
                    {getUpdateDateString(desiredHoursUpdatedAt)}
                  </Text>
                </ModalGridRow>
              </DesiredHoursColumn>
              <EngagementListColumn>
                <EngagementTable>
                  <thead>
                    <tr>
                      <th
                        className="utilization-column"
                        data-test={formatDataTestId('TOTAL_ENGAGEMENT_HOURS')}
                      >
                        Utilization Amount ({totalEngagementHours})
                      </th>
                      <th
                        className="active-engagements-column"
                        data-test={formatDataTestId('ACTIVE_ENGAGEMENT_COUNT')}
                      >
                        Active Engagements ({activeEngagements.length})
                      </th>
                      {}
                      <th className="opportunity-name-column" />
                      {}
                      <th className="roll-off-date-column" />
                      {}
                      <th className="opp-link-column" />
                    </tr>
                  </thead>
                  <tbody>
                    {displayEngagements.map((engagement, idx) => {
                      if (isNil(engagement)) {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <tr key={idx}>
                            <td className="utilization-column" />
                            <td className="active-engagements-column" />
                            <td className="opportunity-name-column" />
                            <td className="roll-off-date-column" />
                            <td className="opp-link-column" />
                          </tr>
                        );
                      }

                      const {
                        accountName,
                        jobName,
                        id,
                        weeklyEngagementHours,
                        position: { endDate, endDateStatus, reservedType },
                      } = engagement;

                      return (
                        <tr
                          // eslint-disable-next-line react/no-array-index-key
                          key={idx}
                          data-test={formatDataTestId('ACTIVE_ENGAGEMENT')}
                        >
                          <td
                            className="hours utilization-column"
                            data-test={formatDataTestId(
                              'ACTIVE_ENGAGEMENT_WEEKLY_HOURS'
                            )}
                          >
                            <Header3>
                              {weeklyEngagementHours} hrs{' '}
                              {!!reservedType && reservedType.toLowerCase()}
                            </Header3>
                          </td>
                          <td className="active-engagements-column">
                            <Label>Account Name</Label>
                            <div
                              className="data"
                              data-test={formatDataTestId(
                                'ACTIVE_ENGAGEMENT_ACCOUNT_NAME'
                              )}
                            >
                              {accountName}
                            </div>
                          </td>
                          <td className="opportunity-name-column">
                            <Label>Opportunity Name</Label>
                            <div
                              className="data"
                              data-test={formatDataTestId(
                                'ACTIVE_ENGAGEMENT_OPPORTUNITY_NAME'
                              )}
                            >
                              {jobName}
                            </div>
                          </td>
                          <td
                            className={`roll-off-date-column ${
                              endDateStatus || 'with-icon'
                            }`}
                          >
                            <Label>Roll-off Date</Label>
                            <div>
                              {getDateWithIcon(
                                endDate,
                                endDateStatus,
                                'ACTIVE_ENGAGEMENT_END_DATE'
                              )}
                            </div>
                          </td>
                          <td className="opp-link-column">
                            <IconButton
                              icon="popout"
                              pattern="secondary"
                              variation="minimal"
                              name="ACTIVE_ENGAGEMENT_OPPORTUNITY_LINK"
                              toTab={`/opportunity-detail/${id}`}
                              title={`Open "${jobName}" in new tab`}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </EngagementTable>
              </EngagementListColumn>
            </ModalGridRow>
          </ModalSection>
          <ModalFooter>
            <Button
              name="CANCELEXPERIENCEBUTTON"
              onClick={() => CandidateAvailabilityModalStore.closeModal()}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      );
    }}
  />
);

CurrentAvailabilityModal.propTypes = {
  currentAvailabilityModalState:
    CandidateAvailabilityModalStore.getStateShape().isRequired,
};

export default connect(state => ({
  currentAvailabilityModalState: CandidateAvailabilityModalStore.select(state),
}))(CurrentAvailabilityModal);
