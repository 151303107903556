import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('opportunity');

export const getJobs = async (body = { page: 1 }) => {
  const { data, _meta } = await api.GET('/opportunities', body);
  return {
    data,
    meta: _meta,
  };
};

export const getJob = async jobId => {
  const response = await api.GET(`/opportunities/${jobId}`);
  return {
    data: response.data,
  };
};

export const getRelatedJobs = async jobId => {
  const response = await api.GET(`/opportunities/${jobId}/related`);
  return {
    data: response.data,
  };
};

export const putJob = async (jobId, body) => {
  const { data } = await api.PATCH(`/opportunities/${jobId}`, body);
  return { data };
};
