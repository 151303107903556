import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('notification');

export const getNotifications = async ({ userId, page }) => {
  const { data, _meta } = await api.GET(`/users/${userId}/notifications`, {
    page,
  });
  /**
   * Make sure we're only looking at notifications
   */
  const notifications =
    data && data.length > 0
      ? data.filter(notification => notification.type === 'notification')
      : [];

  /**
   * Make sure they're sorted newest to oldest
   */
  return {
    data: notifications.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
    _meta,
  };
};

export const putNotificationRead = async notificationId => {
  const { data } = await api.PATCH(`/notifications/${notificationId}`, {
    isRead: true,
  });

  return { data };
};

export const putAllNotificationsRead = async userId => {
  const { data } = await api.PUT(
    `/users/${userId}/notifications/markAllAsRead`
  );

  return { data };
};
