import { string, number, shape, arrayOf } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { addContactsToAccount } from '../api/accounts-legacy-api';
import { getContacts } from '../api/contacts-api';
import { ContactModel } from '../models/contact';

import { AccountStore } from './account-store';

class AppFindContactForAccountModalStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      accountIds: arrayOf(string),
      reloadAccountId: string,
      searchTerm: string,
      results: arrayOf(ContactModel),
      totalResultsCount: number,
      page: number,
    });
  }

  beginAddingToAccount(accountIds, reloadAccountIdOnSubmit) {
    return this.setState({
      accountIds: accountIds || [],
      reloadAccountId: reloadAccountIdOnSubmit,
      searchTerm: '',
      results: [],
      totalResultsCount: 0,
    });
  }

  findContacts(searchTerm, storeData) {
    if (searchTerm.length === 0) return null;

    return this.setState(
      getContacts({
        filters: {},
        search: searchTerm,
        page: storeData.page,
      }).then(data => ({
        searchTerm,
        page: data.meta.currentPage,
        results: [...storeData.results, ...(data.results || [])],
        totalResultsCount: data.meta.resultCount,
      }))
    );
  }

  submit(storeData, contactId) {
    if (contactId && storeData.accountIds.length > 0) {
      const { accountIds, reloadAccountId } = storeData;

      const promise = addContactsToAccount({
        accountId: accountIds[0],
        contactIds: [contactId],
      });

      return this.clearState(promise).then(() => {
        if (reloadAccountId) {
          AccountStore.load(reloadAccountId);
        }
      });
    }
    return null;
  }
}

export const AppFindContactForAccountModalStore =
  new AppFindContactForAccountModalStoreClass();
