import { call, put, takeEvery } from 'redux-saga/effects';

import { BANNER_MESSAGES_LOAD } from '../constants';
import { getBannerMessages } from '../../api/banner-messages-api';
import {
  bannerMessagesLoaded,
  bannerMessagesLoadedError,
} from '../actions/banner-messages';
import { addApiError } from '../actions/app';

export function* getBannerMessageSaga(action) {
  try {
    const bannerMessage = yield call(getBannerMessages, action.payload);
    yield put(bannerMessagesLoaded(bannerMessage));
  } catch (e) {
    yield put(addApiError(e.applicationError));
    yield put(bannerMessagesLoadedError(e));
  }
}

export default function* bannerMessagesSaga() {
  yield takeEvery(BANNER_MESSAGES_LOAD, getBannerMessageSaga);
}
