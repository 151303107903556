import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('position');

export const PositionsApi = {
  patchPosition(positionId, body) {
    return api.PATCH(`/positions/${positionId}`, body);
  },
  deletePosition(positionId) {
    return api.DELETE(`/positions/${positionId}`);
  },
};
