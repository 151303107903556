import React, { useState } from 'react';
import { ContextMenu } from '@axiom/ui';
import { Candidate, Opportunity, User } from '@axiom/validation';

import { isSalesUser } from '../../utils/roles-util';
import { TalentInterviewAvailabilityModal } from '../TalentInterviewAvailabilityModal/TalentInterviewAvailabilityModal';
import { CandidateCalendarMenuContent } from '../CandidateCalendarMenuContent/CandidateCalendarMenuContent';

export const CandidateCalendarMenu = ({
  candidate,
  user,
  icon,
}: {
  user: User;
  candidate: Candidate;
  icon: JSX.Element;
  opportunity?: Opportunity;
  position?: Opportunity['positions'][number];
}) => {
  const [
    showEditInterviewAvailabilityModal,
    setShowEditInterviewAvailabilityModal,
  ] = useState<boolean>(false);

  return (
    <>
      {!isSalesUser(user) && (
        <ContextMenu
          name="INTERVIEW_AVAILABILITY_MENU"
          direction="left"
          anchor={icon}
        >
          <CandidateCalendarMenuContent
            user={user}
            onEditAvailability={setShowEditInterviewAvailabilityModal}
          />
        </ContextMenu>
      )}
      {!!showEditInterviewAvailabilityModal && (
        <TalentInterviewAvailabilityModal
          candidate={candidate}
          user={user}
          onClose={() => setShowEditInterviewAvailabilityModal(false)}
        />
      )}
    </>
  );
};
