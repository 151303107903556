import { call, put, takeLatest } from 'redux-saga/effects';

import { addApiError } from '../actions/app';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_POLL,
  UPDATE_NOTIFICATION,
  UPDATE_ALL_NOTIFICATIONS,
} from '../constants';
import {
  getNotifications,
  putNotificationRead,
  putAllNotificationsRead,
} from '../../api/notifications-api';
import {
  getNotificationsSuccess,
  getNotificationsError,
  getNotificationsPollSuccess,
  getNotificationsPollError,
  putNotificationReadSuccess,
  putNotificationReadError,
  putAllNotificationReadSuccess,
  putAllNotificationReadError,
} from '../actions/notifications';

export function* getNotificationGenerator({ payload }) {
  try {
    const notifications = yield call(getNotifications, payload);
    yield put(
      getNotificationsSuccess({ notifications, userId: payload.userId })
    );
  } catch (e) {
    yield put(getNotificationsError(e));
    yield put(addApiError(e.applicationError));
  }
}
export function* getNotificationPollGenerator({ payload }) {
  try {
    const notifications = yield call(getNotifications, payload);
    yield put(
      getNotificationsPollSuccess({ notifications, userId: payload.userId })
    );
  } catch (e) {
    yield put(getNotificationsPollError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* updateNotificationGenerator({ payload }) {
  try {
    const notifications = yield call(putNotificationRead, payload);
    yield put(putNotificationReadSuccess({ notifications }));
  } catch (e) {
    yield put(putNotificationReadError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* updateAllNotificationsGenerator({ payload }) {
  try {
    const notifications = yield call(putAllNotificationsRead, payload);
    yield put(putAllNotificationReadSuccess({ notifications }));
  } catch (e) {
    yield put(putAllNotificationReadError(e));
    yield put(addApiError(e.applicationError));
  }
}

function* notificationsSaga() {
  yield takeLatest(GET_NOTIFICATIONS, getNotificationGenerator);
  yield takeLatest(GET_NOTIFICATIONS_POLL, getNotificationPollGenerator);
  yield takeLatest(UPDATE_NOTIFICATION, updateNotificationGenerator);
  yield takeLatest(UPDATE_ALL_NOTIFICATIONS, updateAllNotificationsGenerator);
}

export default notificationsSaga;
