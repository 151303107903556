import {
  Card,
  CardHeader,
  CardSection,
  Layout,
  useApi,
  Tiles,
  SmallHeader,
  Badge,
  GridColumn,
  GridRow,
  Grid,
  TilesItem,
} from '@axiom/ui';
import React, { useEffect } from 'react';
import { TopTask } from '@axiom/validation';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { TopTasksUtil } from '../../utils/top-tasks-util';
import { TopTasksApi } from '../../api/top-tasks-api';

import { TopTaskTile } from './WorkspaceTopTaskTile';
import { TopTasksNoResults } from './WorkspaceTopTasksNoResults';

export const WorkspaceTopTasks = () => {
  const navigate = useNavigate();
  const [{ data: topTasks }] = useApi(TopTasksApi.readTopTasks());

  topTasks.sort((a: TopTask, b: TopTask) => {
    return (
      moment().diff(b.taskExpectedCompletionDateTime) -
      moment().diff(a.taskExpectedCompletionDateTime)
    );
  });

  // Needed to keep Top Tasks in sync across navigation
  useEffect(() => {
    TopTasksApi.refreshTopTasks();
  }, []);

  return (
    <Grid stretched>
      <GridRow gutterTop="16px">
        <GridColumn>
          <Card stretched scrollable>
            <CardHeader>
              <Layout
                name="TOP_TASK_COUNT"
                direction="horizontal"
                horizontalGutter="8px"
                position="middle"
              >
                <Badge background="light blue">{topTasks.length}</Badge>
                <SmallHeader>
                  Top {topTasks.length === 1 ? 'Task' : 'Tasks'}
                </SmallHeader>
              </Layout>
            </CardHeader>
            <CardSection>
              {topTasks.length === 0 ? (
                <TopTasksNoResults />
              ) : (
                <Tiles columnWidth={1}>
                  {topTasks.map((task: TopTask) => (
                    <TilesItem
                      key={task.id}
                      name={`TOP_TASK_TILE_${task.id}`}
                      onClick={() => {
                        navigate(TopTasksUtil.getTopTaskLink(task));
                      }}
                    >
                      <TopTaskTile task={task} />
                    </TilesItem>
                  ))}
                </Tiles>
              )}
            </CardSection>
          </Card>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
