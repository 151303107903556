import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('barred location');

export const getBarredLocationsByTalentId = async talentId => {
  const { data, _meta } = await api.GET(
    `/candidates/${talentId}/barredLocations`
  );
  return {
    data,
    meta: _meta,
  };
};
