import { AccountConst } from '@axiom/const';
import { Account, Contact } from '@axiom/validation';
import { Api } from '@axiom/ui';

import { EnvUtil } from '../utils/env-util';
import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('account');

export const getAccount = async (accountId: Account['id']) => {
  const { data: account } = await api.GET(`/accounts/${accountId}`, {
    expand: [AccountConst.Expansions.parentAccountInfo],
  });
  return account;
};

export const getAccounts = async (filterMeta: {
  search: string;
  page: number;
}) => {
  const { data, meta } = await api.GET(
    `${EnvUtil.apiEnvoyUrl}/accounts`,
    filterMeta,
    true
  );
  return {
    results: data,
    meta,
  };
};

export const addContactsToAccount = async ({
  accountId,
  contactIds,
}: {
  accountId: Account['id'];
  contactIds: Contact['id'][];
}) => {
  const { data } = await api.POST(`/accounts/${accountId}/contacts`, {
    contactIds,
  });
  return data;
};

export const removeContactFromAccount = async ({
  accountId,
  contactId,
}: {
  accountId: Account['id'];
  contactId: Contact['id'];
}) => {
  const { data } = await api.DELETE(
    `/accounts/${accountId}/contacts/${contactId}`
  );
  return { data };
};

class AccountApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readAccount(accountId: Account['id']) {
    return super.read<{ data: Account }>({
      endpoint: `/accounts/${accountId}`,
      method: 'GET',
    });
  }
}

export const AccountApi = new AccountApiClass();
