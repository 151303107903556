import { compact } from 'lodash';

import { DateUtil } from '../../utils/date-util';
import { COMPLETED, ENGAGED } from '../../models/relational-opportunity-const';

export const generateAddressDisplayName = talent => {
  const displayValues = compact([
    talent.addressCity,
    talent.addressState,
    talent.addressCountry,
  ]);

  if (displayValues.length > 2) return displayValues.slice(0, 2).join(', ');

  return displayValues.join(', ');
};

export const deriveTalentFields = (() => {
  // without the one item cache, this method always returns a new object which can cause many unnecessary rerenders
  let oneCache = {};

  return config => {
    const {
      activityLog = [],
      barredLocations = [],
      byId = {},
      collaborators = [],
      degrees,
      experiences = [],
      homeOffice = {},
      languages = [],
      opportunities = [],
      tags = [],
      talentData = {},
    } = config;

    const key = JSON.stringify(config);

    if (oneCache[key]) return oneCache[key];

    const { profileStatus, profileStatusUpdatedAt } = talentData;

    const isBeached = /beach/i.test(profileStatus);
    const daysBeached =
      isBeached && profileStatusUpdatedAt
        ? DateUtil.castAsMoment().diff(
            DateUtil.castAsMoment(profileStatusUpdatedAt),
            'days'
          )
        : null;
    const maxHours = talentData.maxHours || 50;

    const availability = 'MISSING DATA';

    const candidacyList = opportunities;

    const candidacyEngagements = opportunities.filter(
      opportunity => opportunity.candidateStatus === ENGAGED
    );

    const previousCandidacyEngagements = opportunities.filter(
      opportunity => opportunity.candidateStatus === COMPLETED
    );

    const addressDisplayName = generateAddressDisplayName(talentData);

    const updatedTalentData = { ...talentData };

    const updatedById = byId[talentData.id]
      ? {
          ...byId,
          [talentData.id]: {
            ...byId[talentData.id],
          },
        }
      : byId;

    const talent = updatedById[talentData.id] || updatedTalentData;
    oneCache = {};
    oneCache[key] = {
      ...talent,
      activityLog,
      addressDisplayName,
      availability,
      barredLocationNames: barredLocations.map(({ name }) => name),
      barredLocations: barredLocations.map(({ id }) => id),
      candidacyEngagements,
      candidacyList,
      degrees: degrees || talent.degrees || [],
      collaborators,
      daysBeached,
      experiences: experiences || talent.experiences || [],
      homeOfficeName: homeOffice.name,
      languages,
      maxHours,
      tags: tags.map(({ id }) => id),
      previousCandidacyEngagements,
    };
    return oneCache[key];
  };
})();
