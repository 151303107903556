import { ContactModel } from '../models/contact';
import GenericStore from '../classes/generic-store';
import { removeContactFromAccount } from '../api/accounts-legacy-api';
import { getContact } from '../api/contacts-api';
import { updateUser } from '../api/users-api';

class ContactStoreClass extends GenericStore {
  load(id) {
    return this.setState(getContact(id));
  }

  changeUserStatus(contactId, userId, isActive) {
    return this.watchState(updateUser({ id: userId, isActive })).then(() => {
      return this.load(contactId);
    });
  }

  removeContactFromAccount(contactId, accountId) {
    return removeContactFromAccount({ accountId, contactId }).then(() => {
      this.load(contactId);
    });
  }

  getDataShape() {
    return ContactModel;
  }
}

export const ContactStore = new ContactStoreClass();
