import { string, number, shape, arrayOf } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { addAccountsToContact } from '../api/contacts-api';
import { getAccounts } from '../api/accounts-legacy-api';
import { AccountModel } from '../models/account';

import { ContactStore } from './contact-store';

class AppFindAccountForContactModalStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      contactIds: arrayOf(string),
      reloadContactId: string,
      searchTerm: string,
      results: arrayOf(AccountModel),
      totalResultsCount: number,
      page: number,
    });
  }

  beginAddingToContact(contactIds, reloadContactIdOnSubmit) {
    return this.setState({
      contactIds: contactIds || [],
      reloadContactId: reloadContactIdOnSubmit,
      searchTerm: '',
      results: [],
      totalResultsCount: 0,
    });
  }

  findAccounts(searchTerm, storeData) {
    if (searchTerm.length === 0) return null;

    return this.setState(
      getAccounts({
        filters: {},
        search: searchTerm,
        page: storeData.page,
      }).then(data => ({
        searchTerm,
        page: data.meta.currentPage,
        results: [...storeData.results, ...(data.results || [])],
        totalResultsCount: data.meta.resultCount,
      }))
    );
  }

  submit(storeData, accountId) {
    if (accountId && storeData.contactIds.length > 0) {
      const { contactIds, reloadContactId } = storeData;

      const promise = addAccountsToContact({
        contactId: contactIds[0],
        accountIds: [accountId],
      });

      return this.clearState(promise).then(() => {
        if (reloadContactId) {
          ContactStore.load(reloadContactId);
        }
      });
    }
    return null;
  }
}

export const AppFindAccountForContactModalStore =
  new AppFindAccountForContactModalStoreClass();
