import React from 'react';
import { FormText as RSFormText } from 'reactstrap';
import { bool, number, node, oneOfType, string } from 'prop-types';
import size from 'lodash/size';
import styled from 'styled-components';

export const FlexLeftWrapper = styled.div`
  padding-top: 10px;
  flex: initial;
  display: inline-flex;
  flex-direction: column;
`;

export const FlexRightWrapper = styled.div`
  padding-top: 10px;
  flex: 1 auto;
  width: auto;
  text-align: end;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
`;

const InputCharRemaining = ({
  showCharsRemainingLabel,
  maxLength,
  errorMessageNode,
  value,
  type,
}) => {
  const shouldShowCharsRemaining = () =>
    type !== 'number' && showCharsRemainingLabel === true && maxLength !== null;

  return (
    <FlexWrapper>
      <FlexLeftWrapper>{errorMessageNode}</FlexLeftWrapper>
      <FlexRightWrapper>
        {shouldShowCharsRemaining() && (
          <RSFormText>
            {`${maxLength - size(value) || 0} characters remaining`}
          </RSFormText>
        )}
      </FlexRightWrapper>
    </FlexWrapper>
  );
};

InputCharRemaining.defaultProps = {
  showCharsRemainingLabel: true,
  maxLength: 255,
  errorMessageNode: null,
  value: '',
  type: 'text',
};

InputCharRemaining.propTypes = {
  errorMessageNode: node,
  maxLength: number,
  showCharsRemainingLabel: bool,
  type: string,
  value: oneOfType([string, number]),
};

export default InputCharRemaining;
