import React from 'react';
import {
  Accordions,
  CondensedHeader,
  CondensedMedium,
  Flashy,
  Gutter,
} from '@axiom/ui';
import { shape, arrayOf, func, bool } from 'prop-types';

import RichTextEditor from '../RichTextEditor/RichTextEditor';

import { SubmissionModalCandidateExperienceItem } from './SubmissionModalCandidateExperienceItem';
import {
  SMSCSubmissionDetails,
  SMSCSubmissionDetailsBody,
  SMSCSubmissionDetailsHeader,
} from './SubmissionModalSelectedCandidateStyles';

class SubmissionModalCandidateExperience extends React.Component {
  onEyeballClicked = (experienceVisible, experienceId) => {
    const {
      candidate: { experiences = [] },
    } = this.props;

    this.props.onCandidateDetailsChanged({
      experiences: experiences.map(experience =>
        experience.id === experienceId
          ? { ...experience, experienceVisible }
          : experience
      ),
    });
  };

  onExperienceSubmissionDescriptionChanged = (
    submissionDescription,
    experienceId
  ) => {
    const {
      candidate: { experiences = [] },
    } = this.props;

    this.props.onCandidateDetailsChanged({
      experiences: experiences.map(experience =>
        experience.id === experienceId
          ? {
              ...experience,
              submissionDescription: submissionDescription || '',
            }
          : experience
      ),
    });
  };

  onSubmissionDetailsChange = submissionDescription => {
    this.props.onCandidateDetailsChanged({ submissionDescription });
  };

  render() {
    const { experiences, candidate, readOnly } = this.props;
    const hiddenExperiences = experiences.filter(
      ({ experienceVisible }) => !experienceVisible
    );
    return (
      <div data-test="SUBMISSION_EXPERIENCE">
        <Gutter vertical="24px">
          <CondensedHeader>Included Experiences</CondensedHeader>
          <Gutter bottom="8px" />
          <CondensedMedium>
            The following experiences will be included in your submission. You
            can edit or hide an experience from this submission. All changes
            will apply only to this submission.
          </CondensedMedium>
        </Gutter>
        <Accordions name="EXPERIENCES">
          {experiences
            .filter(({ experienceVisible }) => experienceVisible)
            .map(experience => (
              <SubmissionModalCandidateExperienceItem
                key={`visible-${experience.id}`}
                experience={experience}
                readOnly={readOnly}
                eyeOpen
                onEyeballClicked={() =>
                  this.onEyeballClicked(false, experience.id)
                }
                onExperienceSubmissionDescriptionChanged={
                  this.onExperienceSubmissionDescriptionChanged
                }
                candidateId={candidate.id}
              />
            ))}
        </Accordions>
        {hiddenExperiences.length > 0 && (
          <>
            <Gutter bottom="24px" />
            <Flashy color="contentBreak">
              <hr />
            </Flashy>
            <Gutter bottom="24px" />
            <CondensedHeader>Hidden Experiences</CondensedHeader>
            <Gutter bottom="8px" />
            <CondensedMedium>
              The following experiences have been hidden from this submission.
              You can include a hidden experience by clicking the “unhide” icon.
            </CondensedMedium>
            <Gutter bottom="24px" />
            <Accordions name="HIDDEN_EXPERIENCES">
              {hiddenExperiences.map(experience => (
                <SubmissionModalCandidateExperienceItem
                  key={`hidden-${experience.id}`}
                  experience={experience}
                  readOnly={readOnly}
                  onEyeballClicked={() =>
                    this.onEyeballClicked(true, experience.id)
                  }
                  onExperienceSubmissionDescriptionChanged={
                    this.onExperienceSubmissionDescriptionChanged
                  }
                  candidateId={candidate.id}
                />
              ))}
            </Accordions>
          </>
        )}
        <Gutter bottom="24px" />
        <Flashy color="contentBreak">
          <hr />
        </Flashy>
        <SMSCSubmissionDetails>
          <SMSCSubmissionDetailsHeader>
            Submission Details
          </SMSCSubmissionDetailsHeader>
          <SMSCSubmissionDetailsBody>
            <RichTextEditor
              name="description"
              id="description"
              data-test="DESCRIPTION"
              initialValue={candidate.submissionDescription || ''}
              onChange={this.onSubmissionDetailsChange}
              readOnly={readOnly}
            />
          </SMSCSubmissionDetailsBody>
        </SMSCSubmissionDetails>
      </div>
    );
  }
}

SubmissionModalCandidateExperience.propTypes = {
  candidate: shape({}).isRequired,
  experiences: arrayOf(shape({})).isRequired,
  readOnly: bool.isRequired,
  onCandidateDetailsChanged: func.isRequired,
};

export default SubmissionModalCandidateExperience;
