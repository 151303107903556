import { EnvUtil } from '../utils/env-util';
import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('submission');

export const getSubmission = async submissionId => {
  const { data } = await api.GET(
    `${EnvUtil.apiEnvoyUrl}/submissions/${submissionId}`
  );
  return { data };
};

export const patchSubmission = async (submissionId, body) => {
  const { data } = await api.PATCH(`/submissions/${submissionId}`, body);
  return { data };
};

export const postSendSubmissionEmail = async (submissionId, body = {}) => {
  const { data } = await api.POST(
    `/submissions/${submissionId}/sendSubmissionEmail`,
    body
  );

  return { data };
};
