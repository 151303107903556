import React from 'react';
import { string, bool } from 'prop-types';
import { DarkTheme, IconButton, Label, Text } from '@axiom/ui';

import { OpportunityUtil } from '../../utils/opportunity-util';
import { OpportunityStore } from '../../stores/opportunity-store';
import { formatDataTestId } from '../../utils/data-test-util';

import {
  JPHBanner,
  JPHBannerLeft,
  JPHBannerStageIcon,
  JPHBannerStageIconLabel,
  JPHBannerStageIconValue,
  JPHBannerLabelWrapper,
} from './OpportunityProfileHeaderBannerStyles';

const OpportunityProfileHeaderBanner = ({
  opportunity,
  searchTerm,
  isDetail,
}) => {
  const status = OpportunityUtil.getFulfillmentStatusColorName(
    opportunity.isFulfillmentActive
  );
  const opportunityName = opportunity.opportunityName || opportunity.jobName;

  return (
    <DarkTheme backgroundName="none">
      <JPHBanner statusName={status}>
        <JPHBannerLeft>
          <JPHBannerStageIcon statusName={status}>
            <JPHBannerStageIconLabel>STAGE</JPHBannerStageIconLabel>
            <JPHBannerStageIconValue data-test={formatDataTestId('stage code')}>
              {opportunity.stageCode || '?'}
            </JPHBannerStageIconValue>
          </JPHBannerStageIcon>
          <JPHBannerLabelWrapper>
            <Label>Opportunity Name</Label>
            <Text name="opportunity name" bold>
              {opportunityName}
            </Text>
          </JPHBannerLabelWrapper>
        </JPHBannerLeft>
        <div>
          {!isDetail && (
            <IconButton
              icon="popout"
              inverse
              pattern="secondary"
              variation="minimal"
              toTab={
                searchTerm && searchTerm.length >= 2
                  ? `/opportunity-detail/${opportunity.id}?search=${searchTerm}`
                  : `/opportunity-detail/${opportunity.id}`
              }
              title={`Open "${opportunity.jobName}" in new tab`}
              className="gtm-opp-new-tab"
            />
          )}
        </div>
      </JPHBanner>
    </DarkTheme>
  );
};

OpportunityProfileHeaderBanner.defaultProps = {
  searchTerm: undefined,
  isDetail: false,
};

OpportunityProfileHeaderBanner.propTypes = {
  isDetail: bool,
  opportunity: OpportunityStore.getDataShape().isRequired,
  searchTerm: string,
};

export default OpportunityProfileHeaderBanner;
