import { useMemo } from 'react';
import { CandidatesConst } from '@axiom/const';
import { candidateOpportunityStatusUtils } from '@axiom/utils';
import {
  CandidateRedux,
  Opportunity,
  OpportunityPosition,
  User,
} from '@axiom/validation';

export const useCanDoInterviewEvents = ({
  candidate,
  opportunity,
  position,
  user,
}: {
  candidate: CandidateRedux;
  opportunity: Opportunity;
  position: OpportunityPosition;
  user: User;
}): boolean => {
  return useMemo(() => {
    if (!opportunity?.isQualificationComplete) {
      return false;
    }

    const currentCandidateStatus =
      candidate.candidateStatus ||
      candidate?.candidateOpportunities?.find(
        opp => opp.opportunityId === opportunity.id
      )?.candidateStatus;
    const hasGoodStatus =
      currentCandidateStatus ===
        CandidatesConst.CandidateStatuses.Interviewing ||
      !!(
        currentCandidateStatus &&
        candidateOpportunityStatusUtils.isValidTransition({
          currentCandidateStatus,
          opportunity,
          position,
          role: user.roles[0],
          targetStatus: CandidatesConst.CandidateStatuses.Interviewing,
        })
      );

    return hasGoodStatus;
  }, [candidate, opportunity, position, user]);
};
