import { call, put, takeEvery } from 'redux-saga/effects';

import { addApiError } from '../actions/app';
import { OPPORTUNITY_COLLABORATORS_LOAD } from '../constants';
import { getCollaboratorsByOpportunityId } from '../../api/opportunity-collaborators-api';
import {
  opportunityCollaboratorsLoaded,
  opportunityCollaboratorsError,
} from '../actions/opportunity-collaborators';

export function* getOpportunityCollaboratorsSaga({ payload }) {
  try {
    const collaboratorsByOpportunity = yield call(
      getCollaboratorsByOpportunityId,
      payload.id
    );
    yield put(opportunityCollaboratorsLoaded(collaboratorsByOpportunity));
  } catch (e) {
    yield put(addApiError(e.applicationError));
    yield put(opportunityCollaboratorsError(e));
  }
}

export default function* opportunityCollaboratorSaga() {
  yield takeEvery(
    OPPORTUNITY_COLLABORATORS_LOAD,
    getOpportunityCollaboratorsSaga
  );
}
