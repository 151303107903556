import React from 'react';
import { shape, func, string, bool } from 'prop-types';
import {
  Header3,
  CondensedHeader,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  Flashy,
} from '@axiom/ui';

import { EditSubmissionStore } from '../../stores/edit-submission-store';
import { opportunityShape } from '../../models/opportunities-const';
import EmptyCandidates from '../../public/svg/empty-candidates.svg';
import { CandidateUtil } from '../../utils/candidate-util';

import { SubmissionModalTalentListItem } from './SubmissionModalStyles';

const moveCandidate = (candidates, candidateIndex, shouldMoveUp) => {
  const movedCandidate = candidates[candidateIndex];
  candidates.splice(candidateIndex, 1);
  const newIndex = shouldMoveUp ? candidateIndex - 1 : candidateIndex + 1;
  candidates.splice(newIndex, 0, movedCandidate);
  return candidates;
};

class SubmissionModalCandidateList extends React.Component {
  handleUpPosition = (index, candidateGroups) => {
    const { curatedCandidates, afcSubmittedCandidates } = candidateGroups;
    const { submission } = this.props;

    submission.candidates = [
      ...moveCandidate(curatedCandidates, index, true),
      ...afcSubmittedCandidates,
    ];
    EditSubmissionStore.setState(submission);
  };

  handleDownPosition = (index, candidateGroups) => {
    const { submission } = this.props;
    const { curatedCandidates, afcSubmittedCandidates } = candidateGroups;

    submission.candidates = [
      ...moveCandidate(curatedCandidates, index, false),
      ...afcSubmittedCandidates,
    ];
    EditSubmissionStore.setState(submission);
  };

  groupCandidates = (submission, opportunity) => {
    const { directEligible } = opportunity;
    const afcSubmittedCandidateSet = new Set();
    if (directEligible) {
      submission.candidates.forEach(candidate => {
        if (CandidateUtil.isSubmittedFromWorkFeed(candidate)) {
          afcSubmittedCandidateSet.add(candidate);
        }
      });
    }

    const curatedCandidateSet = new Set(
      [...submission.candidates].filter(
        candidate => !afcSubmittedCandidateSet.has(candidate)
      )
    );

    return [curatedCandidateSet, afcSubmittedCandidateSet];
  };

  isUpDisabled = index => index === 0;

  isDownDisabled = (index, candidates) =>
    candidates.length <= index + 1 ||
    (candidates[index + 1] && !candidates[index + 1].isActiveForSubmission);

  render() {
    const {
      submission,
      selectedCandidateId,
      onSelectedCandidateChange,
      readOnly,
      opportunity,
    } = this.props;
    if (!submission || submission.candidates.length === 0) {
      return null;
    }

    const [curatedCandidateSet, afcSubmittedCandidateSet] =
      this.groupCandidates(submission, opportunity);

    const getDisplayColorForName = candidate => {
      if (candidate.id === selectedCandidateId) {
        return 'textReversed';
      }

      if (!candidate.isActiveForSubmission) {
        return 'textSubtle';
      }

      return 'textDefault';
    };

    return (
      <Gutter top="60px" name="TALENT_LIST">
        <Layout direction="vertical" verticalGutter="20px">
          <Header3 name="PENDO_CURATED_TALENT_HEADER">Curated talent</Header3>
          <LayoutItem
            borderBottom="1px solid contentBreak"
            bottomGutter="20px"
            name="PENDO_CURATED_TALENT_LIST"
          >
            {curatedCandidateSet.size === 0 ? (
              <Gutter top="20px" bottom="20px">
                <Layout
                  position="middle center"
                  direction="vertical"
                  verticalGutter="16px"
                >
                  <EmptyCandidates width="100px" height="79px" />
                  <CondensedHeader>No Curated Talent</CondensedHeader>
                </Layout>
              </Gutter>
            ) : (
              [...curatedCandidateSet].map((candidate, index) => (
                <SubmissionModalTalentListItem
                  data-test="SUBMISSION_CANDIDATE"
                  data-value={
                    candidate.id === selectedCandidateId ? 'Active' : 'Inactive'
                  }
                  key={candidate.id}
                  selected={candidate.id === selectedCandidateId}
                  onClick={() => onSelectedCandidateChange(candidate.id)}
                >
                  <Layout position="middle">
                    <LayoutItem fluid name="NAME" data-value={candidate.id}>
                      <Flashy color={getDisplayColorForName(candidate)} bold>
                        {candidate.calculatedDisplayName}
                      </Flashy>
                    </LayoutItem>
                    {selectedCandidateId === candidate.id &&
                      !!candidate.isActiveForSubmission &&
                      !readOnly && (
                        <Layout horizontalGutter="8px" inline>
                          <IconButton
                            pattern="secondary"
                            icon="arrow-up"
                            name="UP_ARROW"
                            disabled={this.isUpDisabled(index)}
                            onClick={() =>
                              this.handleUpPosition(index, {
                                curatedCandidates: [...curatedCandidateSet],
                                afcSubmittedCandidates: [
                                  ...afcSubmittedCandidateSet,
                                ],
                              })
                            }
                          />
                          <IconButton
                            pattern="secondary"
                            icon="arrow-down"
                            name="DOWN_ARROW"
                            disabled={this.isDownDisabled(index, [
                              ...curatedCandidateSet,
                            ])}
                            onClick={() =>
                              this.handleDownPosition(index, {
                                curatedCandidates: [...curatedCandidateSet],
                                afcSubmittedCandidates: [
                                  ...afcSubmittedCandidateSet,
                                ],
                              })
                            }
                          />
                        </Layout>
                      )}
                  </Layout>
                </SubmissionModalTalentListItem>
              ))
            )}
          </LayoutItem>
          <Header3 name="PENDO_SELF_SUBMITTED_TALENT_HEADER">
            Self-Submitted Talent
          </Header3>
          <LayoutItem name="PENDO_SELF_SUBMITTED_TALENT_LIST">
            {afcSubmittedCandidateSet.size === 0 ? (
              <Gutter top="20px" bottom="20px">
                <Layout
                  position="middle center"
                  direction="vertical"
                  verticalGutter="16px"
                >
                  <EmptyCandidates width="100px" height="79px" />
                  <CondensedHeader>No Other Submissions</CondensedHeader>
                </Layout>
              </Gutter>
            ) : (
              [...afcSubmittedCandidateSet].map(candidate => (
                <SubmissionModalTalentListItem
                  data-test="SUBMISSION_CANDIDATE"
                  data-value={
                    candidate.id === selectedCandidateId ? 'Active' : 'Inactive'
                  }
                  key={candidate.id}
                  selected={candidate.id === selectedCandidateId}
                  onClick={() => onSelectedCandidateChange(candidate.id)}
                >
                  <Flashy
                    bold
                    color={getDisplayColorForName(candidate)}
                    name="NAME"
                  >
                    {candidate.calculatedDisplayName}
                  </Flashy>
                </SubmissionModalTalentListItem>
              ))
            )}
          </LayoutItem>
        </Layout>
      </Gutter>
    );
  }
}

SubmissionModalCandidateList.propTypes = {
  opportunity: opportunityShape.isRequired,
  readOnly: bool.isRequired,
  selectedCandidateId: string.isRequired,
  submission: shape({}).isRequired,
  onSelectedCandidateChange: func.isRequired,
};

export default SubmissionModalCandidateList;
