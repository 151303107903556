import { arrayOf, shape, string, number } from 'prop-types';

import { AccountModel } from '../models/account';
import GenericStore from '../classes/generic-store';
import { getAccounts } from '../api/accounts-legacy-api';

class AccountsStoreClass extends GenericStore {
  load({ searchTerm, page = 1, results = [] } = {}) {
    const payload = { search: searchTerm || undefined, page };

    return this.setState(
      getAccounts(payload).then(data => ({
        page: data.meta.currentPage,
        results: [...results, ...(data.results || [])],
        totalResultsCount: data.meta.resultCount,
        searchTerm,
      }))
    );
  }

  // TODO: missing user information in accounts.contacts array
  getDataShape() {
    return shape({
      results: arrayOf(AccountModel),
      page: number,
      totalResultsCount: number,
      searchTerm: string,
    });
  }
}

export const AccountsStore = new AccountsStoreClass();
