import { shape, string } from 'prop-types';

import FormStore from '../classes/form-store';
import { AjvUtil } from '../utils/ajv-util';
import { patchTalent, LegacyTalentApi } from '../api/talent-api';
import { getDecoratedTalent } from '../redux/actions/talent';
import { CandidateApi } from '../api/candidate-api';

import { CandidateOpportunitiesStore } from './candidate-opportunities-store';
import { BenchStore } from './bench-store';

const ProfileStatusValidation = AjvUtil.compileSchema({
  required: ['profileStatus'],
  properties: {
    profileStatus: {
      type: 'string',
      maxLength: 255,
      minLength: 1,
    },
  },
});

class FormTalentProfileStatusStoreClass extends FormStore {
  getDataShape() {
    return shape({
      profileStatus: string,
    });
  }

  load(talent) {
    return this.setState({
      candidateId: talent.id,
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      ProfileStatusValidation,
      formData
    );
  }

  submit(formData, stateData) {
    return this.clearState(
      patchTalent(stateData.candidateId, formData)
        .then(() => {
          CandidateOpportunitiesStore.load({
            id: stateData.candidateId,
            profileStatus: stateData.profileStatus,
          });

          // using setTimeout since the grid take a moment to update because of ElasticSearch.
          setTimeout(() => {
            const isBenchPage = window.location.pathname.includes('/bench');

            if (isBenchPage) {
              BenchStore.loadByConfig({
                meta: {},
              });
            } else {
              CandidateApi.refreshCandidates();
            }
          }, 2000);
          this.dispatch(getDecoratedTalent(stateData.candidateId));
          LegacyTalentApi.invalidateAll();
        })
        .catch(() => {
          // prevent the form from going into an error state
        })
    );
  }
}

export const FormTalentProfileStatusStore =
  new FormTalentProfileStatusStoreClass();
