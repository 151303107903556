import React from 'react';
import { z } from 'zod';
import {
  Button,
  Form,
  FormGroup,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  Radio,
  Textarea,
} from '@axiom/ui';
import { CandidateOpportunitiesConst } from '@axiom/const';
import { CandidateOpportunity } from '@axiom/validation';

import { OpportunityCandidateMlRecommendationApi } from '../../api/opportunity-candidate-ml-recommendations-api';
import { CandidateOpportunityLegacyApi } from '../../api/candidate-opportunities-legacy-api';
import { OpportunityStore } from '../../stores/opportunity-store';

const {
  CandidateStatuses,
  CandidateRejectionLossCodes,
  CandidateRejectionLossCodesLabels,
  AllStatusLabels,
  OppCandMlRecStatuses,
  CandidateOpportunityLossReasonMessages,
} = CandidateOpportunitiesConst;

export const RejectionModalLossCodes = [
  CandidateRejectionLossCodes.CannotWorkOnAdhocEngagements,
  CandidateRejectionLossCodes.InsufficientAvailability,
  CandidateRejectionLossCodes.CostTooExpensive,
  CandidateRejectionLossCodes.ProfileNeedsMoreDetailForSubmission,
  CandidateRejectionLossCodes.ExperienceLackingIndustrySpecificExperience,
  CandidateRejectionLossCodes.QualifiedCandidateThatWasNotSelected,
  CandidateRejectionLossCodes.ExperienceOverqualified,
  CandidateRejectionLossCodes.TimeZoneLocation,
  CandidateRejectionLossCodes.ExperiencePoorPracticeArea,
  CandidateRejectionLossCodes.WrongTalentType,
  CandidateRejectionLossCodes.ExperienceUnderqualified,
  CandidateRejectionLossCodes.ClientDriven,
  CandidateRejectionLossCodes.ExpressedInterestTooLateIntoProcess,
  CandidateRejectionLossCodes.Other,
  CandidateRejectionLossCodes.IncorrectLanguage,
] as NonEmptyArray<string>;

const interestRejectFormSchema = z.object({
  rejectionLossCode: z.enum(RejectionModalLossCodes),
  rejectionLossCodeCustomMessage: z
    .string()
    .min(1, { message: 'Required' })
    .max(300),
});

const aiRejectFormSchema = interestRejectFormSchema.pick({
  rejectionLossCode: true,
});

export const RejectionModal = ({
  candidateId,
  opportunityId,
  candidateStatus,
  onClose,
}: {
  candidateId: CandidateOpportunity['candidateId'];
  opportunityId: CandidateOpportunity['opportunityId'];
  candidateStatus:
    | typeof CandidateStatuses.InterestedRejected
    | typeof OppCandMlRecStatuses.AiRemoved;
  onClose: () => void;
}) => {
  const isAiRemoved = candidateStatus === OppCandMlRecStatuses.AiRemoved;

  return (
    <Form
      name="CANDIDATE_REJECTION_FORM"
      schema={isAiRemoved ? aiRejectFormSchema : interestRejectFormSchema}
      initialValues={{
        rejectionLossCode: null,
        rejectionLossCodeCustomMessage: null,
      }}
      onSubmit={async changedValues => {
        const { rejectionLossCode, rejectionLossCodeCustomMessage } =
          changedValues;

        await (isAiRemoved
          ? OpportunityCandidateMlRecommendationApi.updateOpportunityCandidateMlRecommendation(
              {
                opportunityId,
                candidateId,
                mlLossCode: changedValues.rejectionLossCode,
              }
            )
          : CandidateOpportunityLegacyApi.updateCandidateOpportunityStatus(
              candidateId,
              opportunityId,
              {
                rejectionLossCode: changedValues.rejectionLossCode,
                rejectionLossCodeCustomMessage:
                  rejectionLossCodeCustomMessage ===
                  CandidateOpportunityLossReasonMessages[rejectionLossCode]
                    ? null
                    : rejectionLossCodeCustomMessage,
                candidateStatus,
                candidateStatusMessage: undefined,
              }
            ));

        await CandidateOpportunityLegacyApi.refreshCandidateOpportunities(
          candidateId
        );
        await OpportunityStore.load(opportunityId);
        return onClose();
      }}
    >
      {({ fireSubmit, setValues, values }) => {
        return (
          <Modal name="REJECTION_MODAL">
            <ModalHeader
              name="REJECTION_MODAL_HEADER"
              onClose={onClose}
              background="blue"
            >
              Select{' '}
              {AllStatusLabels[candidateStatus as keyof typeof AllStatusLabels]}{' '}
              reason
            </ModalHeader>
            <ModalSection>
              <Paragraph>
                Select the reason that best describes why you did not submit
                this talent. A more descriptive version of this reason will be
                shared with talent.
              </Paragraph>
              <Gutter bottom="24px" />
              <FormGroup name="rejectionLossCode">
                <Grid columns={2}>
                  <GridRow>
                    {RejectionModalLossCodes.map((code, i) => (
                      <GridColumn key={code}>
                        <Gutter
                          bottom={
                            i < RejectionModalLossCodes.length - 1
                              ? '24px'
                              : '0px'
                          }
                        >
                          <Radio
                            name="rejectionLossCode"
                            displayValue={
                              CandidateRejectionLossCodesLabels[code]
                            }
                            option={code}
                            onChange={v => {
                              if (!isAiRemoved) {
                                const rejectionLossCodeCustomMessage =
                                  CandidateOpportunityLossReasonMessages[
                                    v as keyof typeof CandidateOpportunityLossReasonMessages
                                  ] ?? undefined;

                                // setTimeout is required here due to issues with old values
                                // being run through validation when setValues is called
                                // validation will incorrectly display errors
                                // open issue: https://github.com/jaredpalmer/formik/issues/2083
                                setTimeout(() => {
                                  setValues({
                                    ...values,
                                    rejectionLossCode: v,
                                    rejectionLossCodeCustomMessage,
                                  });
                                }, 0);
                              }
                            }}
                          />
                        </Gutter>
                      </GridColumn>
                    ))}
                  </GridRow>
                </Grid>
              </FormGroup>
              <Gutter bottom="24px" />
              {!isAiRemoved && (
                <Textarea
                  name="rejectionLossCodeCustomMessage"
                  label="Talent will receive the following “cooling message”. Feel free to customize this message."
                />
              )}
            </ModalSection>
            <ModalFooter>
              <Button
                onClick={onClose}
                name="REJECTION_MODAL_CANCEL"
                variation="outline"
              >
                Cancel
              </Button>
              <Button onClick={fireSubmit} name="REJECTION_MODAL_SUBMIT">
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
