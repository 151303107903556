import React, { useState } from 'react';
import {
  AccordionsItem,
  AccordionsItemHeader,
  AccordionsItemSection,
  Badge,
  Button,
  CondensedLarge,
  DateUtil,
  Flashy,
  FluidButtonLayout,
  Form,
  Gutter,
  Icon,
  IconButton,
  Label,
  Layout,
  LayoutItem,
  ReadonlyTextarea,
  Textarea,
} from '@axiom/ui';
import {
  Candidate,
  SubmissionExperience,
  SubmissionExperienceSchema,
} from '@axiom/validation';

import {
  SMSCExperiencesDescriptionDetails,
  SMSCExperienceDescriptionTitle,
} from './SubmissionModalSelectedCandidateStyles';

const editSubmissionDescriptionFormSchema = SubmissionExperienceSchema.pick({
  submissionDescription: true,
});

export type SubmissionModalCandidateExperienceItemProps = {
  experience: SubmissionExperience;
  readOnly: boolean;
  onEyeballClicked: () => void;
  onExperienceSubmissionDescriptionChanged: (
    submissionDescription: string,
    experienceId: SubmissionExperience['id']
  ) => void;
  candidateId: Candidate['id'];
  eyeOpen?: boolean;
};
export const SubmissionModalCandidateExperienceItem: React.FC<
  SubmissionModalCandidateExperienceItemProps
> = ({
  experience,
  readOnly,
  onEyeballClicked,
  onExperienceSubmissionDescriptionChanged,
  eyeOpen = false,
}) => {
  const [editDescription, setEditDescription] = useState(false);
  const toggleEditDescription = () => setEditDescription(!editDescription);
  const hasSubmissionDescription =
    (experience.submissionDescription ?? null) !== null;
  const currentDescription = hasSubmissionDescription
    ? experience.submissionDescription
    : experience.description;
  return (
    <AccordionsItem name="EXPERIENCE_ITEM_accordion" data-value={experience.id}>
      <AccordionsItemHeader>
        <Layout position="middle space-between" horizontalGutter="16px">
          {!!experience.isAxiom && (
            <Badge background="orange" name="AXIOM_EXPERIENCE_BADGE">
              A
            </Badge>
          )}
          <LayoutItem fluid>
            <CondensedLarge name="EXPERIENCE_STRING">
              <Flashy bold name="CLIENT">
                {`${experience.client}; `}
              </Flashy>
              <span data-test="START">
                {DateUtil.displayLongMonthYear(experience.startDate)}
              </span>
              {' - '}
              <span data-test="END">
                {DateUtil.displayEndDate(experience.endDate)}
              </span>
            </CondensedLarge>
          </LayoutItem>
          {!!hasSubmissionDescription &&
            experience.submissionDescription !== experience.description && (
              <Badge
                background="gray"
                name="EXPERIENCE_SUBMISSION_DESCRIPTION_EDITED_BADGE"
              >
                Edited
              </Badge>
            )}
          <IconButton
            name="EXPERIENCE_EYEBALL_ICON"
            icon={eyeOpen ? 'eye' : 'eye-hidden'}
            variation="minimal"
            pattern="secondary"
            onClick={e => {
              e.stopPropagation();
              onEyeballClicked();
            }}
          />
          <Flashy color="contentBreak">
            <Icon name="spacer-vertical" />
          </Flashy>
          <Gutter right="8px" />
        </Layout>
      </AccordionsItemHeader>
      <AccordionsItemSection>
        <SMSCExperienceDescriptionTitle>
          <Label>Description</Label>
          {!editDescription && !readOnly && (
            <IconButton
              icon="pencil"
              pattern="secondary"
              variation="minimal"
              onClick={toggleEditDescription}
              name="EDIT_DESCRIPTION_BUTTON"
            />
          )}
        </SMSCExperienceDescriptionTitle>
        <SMSCExperiencesDescriptionDetails>
          {editDescription ? (
            <Form
              name="EDIT_EXPERIENCE_DESCRIPTION"
              schema={editSubmissionDescriptionFormSchema}
              initialValues={{
                submissionDescription: currentDescription,
              }}
              onSubmit={({ submissionDescription }) => {
                onExperienceSubmissionDescriptionChanged(
                  submissionDescription,
                  experience.id
                );
                toggleEditDescription();
              }}
            >
              {({ fireSubmit }) => {
                return (
                  <>
                    <Textarea
                      name="submissionDescription"
                      id={`${experience.id}-submissionDescription`}
                      full
                    />
                    <Gutter bottom="8px" />
                    <FluidButtonLayout>
                      <Button
                        name="CANCEL_EXPERIENCE_DESCRIPTION"
                        onClick={toggleEditDescription}
                        variation="outline"
                      >
                        Cancel
                      </Button>
                      <Button
                        name="SAVE_EXPERIENCE_DESCRIPTION"
                        type="submit"
                        onClick={fireSubmit}
                      >
                        Save
                      </Button>
                    </FluidButtonLayout>
                  </>
                );
              }}
            </Form>
          ) : (
            <ReadonlyTextarea
              name="READONLY_EXPERIENCE_DESCRIPTION"
              value={currentDescription}
            />
          )}
        </SMSCExperiencesDescriptionDetails>
      </AccordionsItemSection>
    </AccordionsItem>
  );
};
