import { string, bool, shape } from 'prop-types';

import FormStore from '../classes/form-store';
import { AccountsApi } from '../api/accounts-api';
import { AjvUtil } from '../utils/ajv-util';

import { AccountStore } from './account-store';

const AccountConfidentialValidation = AjvUtil.compileSchema({
  required: ['confidentialAccountName'],
  properties: {
    confidentialAccountName: {
      type: 'string',
      maxLength: 255,
      minLength: 1,
    },
  },
});

class FormAccountConfidentialStoreClass extends FormStore {
  getDataShape() {
    return shape({
      accountId: string,
      isAccountConfidential: bool,
      confidentialAccountName: string,
    });
  }

  load({ accountId, isAccountConfidential, confidentialAccountName = null }) {
    return this.setState({
      accountId,
      isAccountConfidential,
      confidentialAccountName,
    });
  }

  validate(formData) {
    if (formData.isAccountConfidential) {
      return AjvUtil.formatValidationForFinalForm(
        AccountConfidentialValidation,
        {
          ...formData,
          confidentialAccountName:
            formData.confidentialAccountName?.trim() || null,
        }
      );
    }
    return true;
  }

  submit(changedField, formData) {
    return this.clearState(
      AccountsApi.updateAccount(formData.accountId, {
        isAccountConfidential: formData.isAccountConfidential,
        confidentialAccountName:
          formData.confidentialAccountName?.trim() || null,
      })
    ).then(() => {
      AccountStore.load(formData.accountId);
    });
  }
}

export const FormAccountConfidentialStore =
  new FormAccountConfidentialStoreClass();
