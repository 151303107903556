import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import {
  DateUtil,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalSection,
  Button,
} from '@axiom/ui';

import { FormCell, FormRow } from '../../styled/form';
import HorizontalRule from '../HorizontalRule/HorizontalRule';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import FFMultiSelect from '../FFMultiSelect/FFMultiSelect';
import { FormTalentProfileEditStore } from '../../stores/form-talent-profile-edit-store';
import { CandidateCollaboratorsStore } from '../../stores/candidate-collaborators-store';
import { PreloadedUsersStore } from '../../stores/preloaded-users-store';
import { FFInput } from '../FFInput/FFInput';
import { FFDropdown } from '../FFDropdown/FFDropdown';
import {
  DropdownOptionsShape,
  DropdownSelectedValueShape,
} from '../../models/dropdown-options';
import { PreloadedBarredLocationsStore } from '../../stores/preloaded-barred-locations-store';

import TalentProfileEditFormLanguages from './TalentProfileEditFormLanguages';

const maxYearsRange = 75;

const TalentProfileEditForm = ({
  preSelectedCollaborators,
  usersOptions,
  barredLocationsOptions,
}) => {
  const [practicingYearsRange, setPracticingYearsRange] = useState([]);

  useEffect(() => {
    // this is here to prevent this logic from running on every redraw
    const data = [
      {
        label: '<1',
        value: 0,
      },
    ];

    for (let i = 1; i <= maxYearsRange; i += 1) {
      data.push({
        label: i,
        value: i,
      });
    }

    setPracticingYearsRange(data);
  }, []);

  return (
    <FormStateTreatment
      name="edit_talent_modal"
      isModalState
      formStore={FormTalentProfileEditStore}
      renderLoadedView={({ formData: { talent }, fireSubmit, fireCancel }) => {
        return (
          <Modal size="large" name="edit-talent-info">
            <ModalHeader onClose={fireCancel}>Edit Talent Info</ModalHeader>
            <ModalSection>
              <FormRow>
                <Field
                  name="displayFirstName"
                  component={FFInput}
                  defaultValue={talent.displayFirstName}
                  placeholder={talent.firstName}
                  label="Display First Name"
                  showAftIcon
                />
                <Field
                  name="displayLastName"
                  component={FFInput}
                  defaultValue={talent.displayLastName}
                  placeholder={talent.lastName}
                  label="Display Last Name"
                  showAftIcon
                />
              </FormRow>

              <FormRow>
                <FormCell width="double">
                  <Field
                    name="barredLocations"
                    component={FFMultiSelect}
                    label="Bar Admission:"
                    options={barredLocationsOptions}
                    initialValue={talent.barredLocations}
                  />
                </FormCell>
              </FormRow>
              <HorizontalRule />
              <FormRow>
                <FormCell width="double">
                  <Field
                    name="noticePeriod"
                    label="Notice Period:"
                    component={FFInput}
                    initialValue={talent.noticePeriod}
                  />
                </FormCell>
                <FormCell width="double">
                  <Field
                    name="yearsPracticing"
                    label="Years Practicing:"
                    component={FFDropdown}
                    options={practicingYearsRange}
                    initialValue={DateUtil.yearToYearsCount(
                      talent.practiceStartYear
                    )}
                    allowNull
                  />
                </FormCell>
              </FormRow>

              <TalentProfileEditFormLanguages type="talent" talent={talent} />

              <FormRow>
                <FormCell width="double">
                  <Field
                    name="collaborators"
                    component={FFMultiSelect}
                    label="Talent Collaborators:"
                    options={usersOptions}
                    initialValue={preSelectedCollaborators}
                  />
                </FormCell>
              </FormRow>
            </ModalSection>
            <ModalFooter>
              <Button
                name="EDITFORMCANCELBUTTON"
                onClick={fireCancel}
                variation="outline"
              >
                Cancel
              </Button>
              <Button name="EDITCANDIDATESAVEBUTTON" onClick={fireSubmit}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    />
  );
};

TalentProfileEditForm.propTypes = {
  barredLocationsOptions:
    PreloadedBarredLocationsStore.getOptionsShape().isRequired,
  preSelectedCollaborators: DropdownSelectedValueShape.isRequired,
  usersOptions: DropdownOptionsShape.isRequired,
};

const mapStateToProps = state => ({
  preSelectedCollaborators: CandidateCollaboratorsStore.selectData(state).map(
    c => c.id
  ),
  usersOptions: PreloadedUsersStore.selectData(state).map(user => ({
    label: user.fullName,
    value: user.id,
  })),
  barredLocationsOptions: PreloadedBarredLocationsStore.selectData(
    state
  ).options.map(bl => ({
    label: bl.name,
    value: bl.id,
  })),
});

export default connect(mapStateToProps)(TalentProfileEditForm);
