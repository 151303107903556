import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Layout, window } from '@axiom/ui';
import { shape } from 'prop-types';

import { useRouterBundle } from '../../hooks/useRouterBundle';
import {
  OpportunitiesStore,
  OpportunitiesStoreMetaConst,
} from '../../stores/opportunities-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import {
  FilterButtonWrapper,
  FilterContainer,
  FilterDropdownWrapper,
  FilterTextWrapper,
  LabelWrapper,
} from '../../styled';
import {
  ActionWrapper,
  HeaderActionItemWrapper,
  CellActionItemWrapper,
  // eslint-disable-next-line boundaries/element-types
} from '../KendoGrid/KendoGridListActionStyles';
import { DateUtil } from '../../utils/date-util';
import KendoGrid from '../KendoGrid/KendoGrid';
import { CheckboxWithLabel } from '../CheckboxWithLabel/CheckboxWithLabel';
import DropdownList from '../DropdownList/DropdownList';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { WithRouterShape } from '../../models/router';
import { OpportunityUtil } from '../../utils/opportunity-util';
import OpportunityStatusLegend from '../OpportunityStatusLegend/OpportunityStatusLegend';
import { StateComponentUtil } from '../../utils/state-component-util';
import Spinner from '../Spinner/Spinner';
import { AppFindCandidateForOppModalStore } from '../../stores/app-find-candidate-for-opp-modal-store';
import { MultiLoadConfirm } from '../MultiLoadConfirm/MultiLoadConfirm';
import { WindowUtil } from '../../utils/window-util';
import { SetUtil } from '../../utils/set-util';
import { StatusIcon } from '../StatusIcon/StatusIcon';
import { UserSettingsUtil } from '../../utils/user-settings-util';

import {
  OpportunitiesListSpinnerContainer,
  SpanOpportunityNameHeader,
} from './OpportunitiesListStyles';

const gridSortOptions = [
  {
    label: 'Relevance',
    value: OpportunitiesStoreMetaConst.sort.relevance,
  },
  {
    label: 'Opportunity name',
    value: OpportunitiesStoreMetaConst.sort.opportunityName,
  },
  {
    label: 'Account name',
    value: OpportunitiesStoreMetaConst.sort.accountName,
  },
  {
    label: 'Client name',
    value: OpportunitiesStoreMetaConst.sort.clientName,
  },
  {
    label: 'Positions',
    value: OpportunitiesStoreMetaConst.sort.positions,
  },
  {
    label: 'Stage',
    value: OpportunitiesStoreMetaConst.sort.stageCode,
  },
  {
    label: 'Close date',
    value: OpportunitiesStoreMetaConst.sort.salesCloseDate,
  },
  {
    label: 'Opportunity owner',
    value: OpportunitiesStoreMetaConst.sort.salesLead,
  },
];

class OpportunitiesListComponent extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (
      state.selectAllChecked &&
      props.opportunitiesState.data &&
      props.opportunitiesState.data.results
    ) {
      return {
        selectedIds: new Set(
          props.opportunitiesState.data.results.map(
            opportunities => opportunities.id
          )
        ),
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      selectAllChecked: false,
      selectedIds: new Set(),
    };
  }

  componentDidMount() {
    const { opportunitiesState } = this.props;
    OpportunitiesStore.loadByState(opportunitiesState);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      router: { location },
      opportunitiesState: { data: { results } = {} },
    } = this.props;

    const { selectedIds: prevSelectedIds } = prevState;
    const { selectAllChecked } = this.state;

    if (location.search !== prevProps.router.location.search) {
      this.resetSelectAll();
      OpportunitiesStore.loadByState();
    }

    if (selectAllChecked) {
      const resultsIDs = results.map(talent => talent.id);
      const resultIdsSet = new Set(resultsIDs);
      const selectedDifference = SetUtil.getDifferences(
        resultIdsSet,
        prevSelectedIds
      );

      if (selectedDifference.size > 0) {
        this.setState(previousState => ({
          ...previousState,
          selectedIds: new Set(resultsIDs),
        }));
      }
    }
  }

  onRowClick = ({ dataItem: { id } }) => {
    const {
      router: { navigate },
      user,
    } = this.props;

    if (
      UserSettingsUtil.shouldShowNewOpportunityTalentView(user.userSettings)
    ) {
      window.open(`/opportunity-detail/${id}`);
      navigate(WindowUtil.appendQueryParams(`/opportunities`));
    } else {
      navigate(WindowUtil.appendQueryParams(`/opportunities/${id}`));
    }
  };

  onLoadMore = data => {
    OpportunitiesStore.loadNextPage(data);
  };

  onDownload = meta => {
    const { selectAllChecked, selectedIds } = this.state;
    if (selectAllChecked) {
      OpportunitiesStore.downloadAllOpportunities(meta);
    } else {
      OpportunitiesStore.downloadSelectedOpportunities(meta, [...selectedIds]);
    }
  };

  getColumns(data) {
    const { selectAllChecked, selectedIds } = this.state;
    const { opportunitiesState } = this.props;
    return [
      {
        id: 'opportunityName',
        header: () => (
          <SpanOpportunityNameHeader>
            Opportunity name
          </SpanOpportunityNameHeader>
        ),
        cellTd: <td style={{ paddingLeft: 0 }} />,
        cell: ({ dataItem }) => (
          <StatusIcon
            onClick={() => this.onRowClick({ dataItem })}
            status={OpportunityUtil.getFulfillmentStatusColorName(
              dataItem.isFulfillmentActive
            )}
          >
            {dataItem.opportunityName || dataItem.jobName}
          </StatusIcon>
        ),
      },
      {
        id: 'accountName',
        header: () => 'Account name',
        cell: ({ dataItem }) => dataItem.accountName,
      },
      {
        id: 'clientName',
        header: () => 'Client name',
        cell: ({ dataItem }) => dataItem.clientName || null,
      },
      {
        id: 'positions',
        header: () => 'Positions',
        width: 150,
        cell: ({ dataItem }) =>
          dataItem.positions ? dataItem.positions.length : 0,
      },
      {
        id: 'stage',
        header: () => 'Stage',
        width: 150,
        cell: ({ dataItem }) =>
          OpportunityUtil.formatStageCode(dataItem.stageCode),
      },
      {
        id: 'closeDate',
        header: () => 'Close date',
        width: 150,
        cell: ({ dataItem }) => DateUtil.displayDate(dataItem.salesCloseDate),
      },
      {
        id: 'opportunityOwner',
        header: () => 'Opportunity owner',
        width: 200,
        cell: ({ dataItem }) => dataItem.salesLead?.fullName,
      },
      {
        id: 'Actions',
        width: 100,
        locked: true,
        resizable: false,
        reorderable: false,
        header: () => (
          <ActionWrapper>
            <HeaderActionItemWrapper />
            <HeaderActionItemWrapper>
              <CheckboxWithLabel
                type="checkbox"
                name="header"
                id="checkbox_master"
                key="master"
                label=""
                checked={selectAllChecked}
                onValueChange={checked => {
                  const newSelected = checked;
                  const newIds = new Set(
                    checked && data.results
                      ? data.results.map(opportunity => opportunity.id)
                      : null
                  );
                  this.setState({
                    selectAllChecked: newSelected,
                    selectedIds: newIds,
                  });
                }}
              />
            </HeaderActionItemWrapper>
          </ActionWrapper>
        ),
        cell: ({ dataItem }) => (
          <ActionWrapper>
            <Layout position="center" borderRight="1px solid #e0e0e0">
              <IconButton
                name={dataItem.opportunityName || dataItem.jobName}
                icon="popout"
                pattern="secondary"
                variation="minimal"
                toTab={`/opportunity-detail/${dataItem.id}`}
                title={`Open "${
                  dataItem.opportunityName || dataItem.jobName
                }" in new tab`}
              />
            </Layout>
            <CellActionItemWrapper>
              <CheckboxWithLabel
                type="checkbox"
                name={`checkbox-cell-${dataItem.id}`}
                id={`checkbox-cell-${dataItem.id}`}
                key={`checkbox-cell-${dataItem.id}`}
                label=""
                checked={selectedIds.has(dataItem.id)}
                onValueChange={checked => {
                  const { selectedIds: currentSelectedIds } = this.state;
                  currentSelectedIds[checked ? 'add' : 'delete'](dataItem.id);
                  const shouldSelectAll =
                    opportunitiesState?.data?.results?.length ===
                    currentSelectedIds.size;
                  this.setState({
                    selectAllChecked: checked ? shouldSelectAll : false,
                    currentSelectedIds,
                  });
                }}
                dataTest="checkbox"
              />
            </CellActionItemWrapper>
          </ActionWrapper>
        ),
      },
    ];
  }

  renderResultsCount = data => {
    switch (data.results.length) {
      case 0: {
        return 'No Opportunities found';
      }
      case 1: {
        return '1 Opportunity found';
      }
      default: {
        return `${OpportunitiesStore.getTotalResults(
          data
        )} Opportunities found`;
      }
    }
  };

  resetSelectAll() {
    this.setState({
      selectAllChecked: false,
      selectedIds: new Set(),
    });
  }

  render() {
    const { opportunitiesState, user } = this.props;
    const { selectedIds } = this.state;

    return (
      <>
        <MultiLoadConfirm
          data={OpportunitiesStore.getDataRequestCount()}
          name="OpportunitiesList"
        />
        <StoreStateTreatment
          name="OppListTreatment"
          storeState={opportunitiesState}
          showRefetchLoader={false}
          renderLoadedView={({ data }) => (
            <>
              <FilterContainer>
                <FilterDropdownWrapper>
                  <LabelWrapper name="sort_by">Sort By:</LabelWrapper>
                  <DropdownList
                    name="sort"
                    value={OpportunitiesStore.getSort()}
                    onChange={value => {
                      OpportunitiesStore.changeSort(value);
                      if (value === 'positions') {
                        OpportunitiesStore.changeOrder('desc');
                      } else {
                        OpportunitiesStore.changeOrder('asc');
                      }
                      this.resetSelectAll();
                    }}
                    options={gridSortOptions}
                    placeholder="Sort by..."
                    className="gtm-grid-sort"
                  />
                </FilterDropdownWrapper>
                <FilterTextWrapper>
                  {this.renderResultsCount(data)}
                  {StateComponentUtil.isLoading([opportunitiesState]) && (
                    <OpportunitiesListSpinnerContainer>
                      <Spinner size={22} />
                    </OpportunitiesListSpinnerContainer>
                  )}
                </FilterTextWrapper>
                <FilterButtonWrapper>
                  <OpportunityStatusLegend opportunities={data.results} />
                  <Layout horizontalGutter="8px" position="right" inline>
                    <IconButton
                      pattern="secondary"
                      icon="plus"
                      name="ADDRELATIONSHIPBUTTON"
                      disabled={selectedIds.size === 0}
                      title="Add to Candidate Log"
                      onClick={() =>
                        AppFindCandidateForOppModalStore.beginAddingToOpportunity(
                          [...selectedIds]
                        )
                      }
                      className="gtm-add-opp-talent"
                    />
                    {!user.roles.includes('EnvoySales') && (
                      <IconButton
                        pattern="secondary"
                        icon="download"
                        disabled={selectedIds.size === 0}
                        title="Export as CSV"
                        name="EXPORTASCSVBUTTON"
                        data-sourcepage="talent"
                        className="gtm-csv-download"
                        onClick={() => this.onDownload(data.meta)}
                      />
                    )}
                  </Layout>
                </FilterButtonWrapper>
              </FilterContainer>

              <KendoGrid
                localStorageKey="OpportunitiesListView"
                totalResults={OpportunitiesStore.getTotalResults(data)}
                data={data.results}
                loadMoreEntries={() => this.onLoadMore(data)}
                onRowClick={this.onRowClick}
                columns={this.getColumns(data)}
              />
            </>
          )}
        />
      </>
    );
  }
}

OpportunitiesListComponent.propTypes = {
  opportunitiesState: OpportunitiesStore.getStateShape().isRequired,
  router: shape(WithRouterShape).isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const OpportunitiesList = useRouterBundle(
  connect(state => ({
    opportunitiesState: OpportunitiesStore.select(state),
    user: PreloadedUserStore.select(state),
  }))(OpportunitiesListComponent)
);
