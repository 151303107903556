import { Certification } from '@axiom/const';

import FormStore from '../classes/form-store';
import { certificationShape } from '../models/certification';
import { AjvUtil } from '../utils/ajv-util';
import {
  createCertification,
  updateCertification,
} from '../api/certification-api';
import { getDecoratedTalent } from '../redux/actions/talent';

const {
  QUALIFICATION_AWARDS: { QUALIFICATION, YEAR_OF_CALL, STATE_EXAM },
  FORM_TYPES: { QUALIFICATIONS, CERTIFICATIONS_AND_AWARDS },
} = Certification;

const CertificationValidation = AjvUtil.compileSchema({
  required: ['award', 'institution', 'year'],
  properties: {
    award: {
      type: 'string',
      maxLength: 255,
      minLength: 1,
    },
    institution: {
      type: 'string',
      maxLength: 255,
      minLength: 1,
    },
    year: {
      type: 'number',
      title: 'Year',
      minLength: 1,
    },
    stateExamScore: {
      type: 'string',
    },
  },
});

class FormCertificationItemStoreClass extends FormStore {
  getDataShape() {
    return certificationShape;
  }

  load({
    id = null,
    award = null,
    candidateId,
    institution = null,
    year,
    stateExamScore,
  } = {}) {
    let type;
    if (award) {
      type =
        award === QUALIFICATION ||
        award === YEAR_OF_CALL ||
        award === STATE_EXAM
          ? QUALIFICATIONS
          : CERTIFICATIONS_AND_AWARDS;
    }

    // year has to be undefined otherwise validator thinks it has value if null
    return this.setState({
      year,
      id,
      award,
      candidateId,
      institution,
      type,
      stateExamScore,
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(CertificationValidation, {
      ...formData,
    });
  }

  submit(changedField, formData) {
    return this.clearState(
      formData.id
        ? updateCertification(formData.id, changedField)
        : createCertification(formData.candidateId, formData)
    ).then(() => {
      this.dispatch(getDecoratedTalent(formData.candidateId));
    });
  }
}

export const FormCertificationItemStore = new FormCertificationItemStoreClass();
