import { ActivityDto } from '@axiom/ui';

import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('activity');

export const addActivityById = async body => {
  const requestBody = new ActivityDto(body);
  const response = await api.MULTIPOST(`/events`, requestBody.export());
  return { data: response.data };
};

export const updateActivityById = async (id, body) => {
  const response = await api.MULTIPATCH(`/events/${id}`, body);
  return { data: response.data };
};

export const deleteActivityById = async id => {
  const response = await api.DELETE(`/events/${id}`);
  return { data: response.data };
};

export default {
  updateActivityById,
  deleteActivityById,
};
