import { EnvUtil } from '../utils/env-util';
import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('law schools');

export const getLawSchools = async filterMeta => {
  const { data, meta } = await api.GET(
    `${EnvUtil.apiEnvoyUrl}/lawSchools`,
    filterMeta,
    true
  );
  return {
    results: data,
    meta,
  };
};

export const getLawSchool = async id => {
  const { data } = await api.GET(`/lawSchools/${id}`);
  return data;
};

export const updateLawSchool = async (id, body) => {
  const { data } = await api.PATCH(`/lawSchools/${id}`, body);
  return { data };
};

export const postLawSchool = async body => {
  const { data } = await api.POST('/lawSchools', body);
  return { data };
};
