import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';

import { SavedSearchesItem } from './SavedSearchesItem';

export const SavedSearches = ({
  userFilters = [],
  handleFilterLoad,
  handleRenderFilter,
}) => (
  <>
    {userFilters.map(filter => (
      <SavedSearchesItem
        key={filter.id}
        userFilter={filter}
        handleFilterLoad={handleFilterLoad}
        handleRenderFilter={handleRenderFilter}
      />
    ))}
    {userFilters.length === 0 && <p>No filters saved</p>}
  </>
);

SavedSearches.propTypes = {
  handleFilterLoad: func.isRequired,
  handleRenderFilter: func.isRequired,
  userFilters: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
    }).isRequired
  ).isRequired,
};
