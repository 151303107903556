import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_PROGRESS_AMOUNT } from './ProgressBarConst';
import { ProgressMeter, ProgressIndicator } from './ProgressBarStyles';

const ProgressBar = ({ status }) => {
  return (
    <ProgressMeter>
      <ProgressIndicator status={status} style={{ width: `${status}%` }}>
        &nbsp;
      </ProgressIndicator>
    </ProgressMeter>
  );
};

ProgressBar.defaultProps = {
  status: DEFAULT_PROGRESS_AMOUNT,
};

ProgressBar.propTypes = {
  status: PropTypes.number,
};

export default ProgressBar;
