import React from 'react';
import { Button, Gutter } from '@axiom/ui';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroll-component';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import SearchInput from '../SearchInput/SearchInput';
import DropdownList from '../DropdownList/DropdownList';
import { lawSchoolShape } from '../../models/lawSchool';
import { formatDataTestId } from '../../utils/data-test-util';
import { LawSchoolsStore } from '../../stores/law-schools-store';
import { PreloadedLawSchoolRanksStore } from '../../stores/preloaded-law-school-ranks-store';

import {
  LawSchoolsManagementListRoot,
  LawSchoolsManagementListStaticItem,
  LawSchoolsManagementListDynamicScrollingItem,
  LSMList,
  LawSchoolsManagementListItem,
  LawSchoolsManagementListNewSchoolWrapper,
} from './LawSchoolsManagementListStyles';

const LawSchoolsManagementListComponent = ({
  selected,
  onSelect,
  lawSchoolsState,
  lawSchoolRanks,
}) => {
  const handleQueryChange = debounce(e => {
    const inputValue = e.target.value;
    LawSchoolsStore.load({ searchTerm: inputValue });
  }, 25);

  const searchTerm = lawSchoolsState.data?.searchTerm;
  const filter = lawSchoolsState.data?.filters?.tags[0];
  const rankFilterDefault = {
    label: 'Filter by school ranking',
    value: undefined,
  };

  return (
    <LawSchoolsManagementListRoot data-test={formatDataTestId('SEARCH_LIST')}>
      <LawSchoolsManagementListStaticItem>
        <SearchInput
          onChange={handleQueryChange}
          value={searchTerm}
          placeholder="Search schools"
          data-test={formatDataTestId('SEARCH_INPUT')}
        />
      </LawSchoolsManagementListStaticItem>
      <LawSchoolsManagementListStaticItem
        onClick={() =>
          onSelect({
            id: null,
            tags: lawSchoolRanks.find(({ label }) => label.match(/unranked/i))
              ?.value,
          })
        }
        data-test="ADD_SCHOOL"
      >
        <LawSchoolsManagementListNewSchoolWrapper>
          <Button icon="plus" pattern="secondary" variation="minimal">
            Add School
          </Button>
        </LawSchoolsManagementListNewSchoolWrapper>
      </LawSchoolsManagementListStaticItem>
      <Gutter bottom="8px" />
      <LawSchoolsManagementListStaticItem>
        <DropdownList
          onChange={lawSchoolRankId => {
            LawSchoolsStore.load({ filters: { tags: [lawSchoolRankId] } });
          }}
          value={filter}
          options={[rankFilterDefault, ...lawSchoolRanks]}
          name={formatDataTestId('SCHOOL_RANK_FILTER')}
        />
      </LawSchoolsManagementListStaticItem>
      <LawSchoolsManagementListDynamicScrollingItem>
        <StoreStateTreatment
          storeState={lawSchoolsState}
          name="SEARCH_LIST"
          renderLoadedView={({
            data: { results, totalResultsCount, page },
          }) => {
            return (
              <InfiniteScroll
                dataLength={results.length}
                next={() => {
                  const request = { results, page: page + 1 };
                  if (searchTerm) request.searchTerm = searchTerm;
                  if (filter) request.filters = { tags: [filter] };
                  LawSchoolsStore.load(request);
                }}
                hasMore={totalResultsCount > results.length}
                height="24em"
              >
                <LSMList
                  data-test={formatDataTestId('RESULTS_LIST')}
                  role="listbox"
                >
                  {results
                    .filter(({ name }) => !!name.trim())
                    .map(lawSchool => {
                      const { name, id } = lawSchool;

                      return (
                        <LawSchoolsManagementListItem
                          key={id}
                          isSelected={!!selected && selected.id === id}
                          onClick={() => onSelect(lawSchool)}
                          data-test={formatDataTestId('RESULT_ITEM')}
                        >
                          {name}
                        </LawSchoolsManagementListItem>
                      );
                    })}
                </LSMList>
              </InfiniteScroll>
            );
          }}
        />
      </LawSchoolsManagementListDynamicScrollingItem>
    </LawSchoolsManagementListRoot>
  );
};

LawSchoolsManagementListComponent.defaultProps = {
  selected: null,
};

LawSchoolsManagementListComponent.propTypes = {
  lawSchoolRanks: PreloadedLawSchoolRanksStore.getDataShape().isRequired,
  lawSchoolsState: LawSchoolsStore.getDataShape().isRequired,
  selected: lawSchoolShape,
  onSelect: func.isRequired,
};

export default connect(
  state => ({
    lawSchoolsState: LawSchoolsStore.select(state),
    lawSchoolRanks: PreloadedLawSchoolRanksStore.selectAsOptions(state),
  }),
  {}
)(LawSchoolsManagementListComponent);
