import { isEmpty, isEqual, pickBy } from 'lodash';
import {
  getFormInitialValues,
  getFormValues,
  reset as resetForm,
} from 'redux-form';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  GROUPS_LOAD,
  GROUP_CREATE,
  GROUP_DELETE,
  GROUP_UPDATE,
  GROUP_USER_ADD,
  GROUP_USER_REMOVE,
} from '../constants';
import { addApiError } from '../actions/app';
import {
  createGroup as createGroupAction,
  groupsLoaded,
  groupsLoadedError,
  groupCreated,
  groupCreatedError,
  groupDeleted,
  groupDeletedError,
  groupUpdated,
  groupUpdatedError,
  groupUserAdded,
  groupUserAddedError,
  groupUserRemoved,
  groupUserRemovedError,
  updateGroup as updateGroupAction,
} from '../actions/groups';
import {
  getGroups,
  createGroup,
  deleteGroup,
  updateGroup,
  addGroupUser,
  removeGroupUser,
} from '../../api/groups-api';

export function* getGroupsSaga() {
  try {
    const response = yield call(getGroups);
    yield put(groupsLoaded(response));
  } catch (e) {
    yield put(groupsLoadedError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* createGroupSaga({ payload: values }) {
  try {
    const { data } = yield call(createGroup, values);
    yield put(groupCreated(data));
  } catch (e) {
    yield put(groupCreatedError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* deleteGroupSaga({ payload: groupId }) {
  try {
    yield call(deleteGroup, groupId);
    yield put(groupDeleted(groupId));
  } catch (e) {
    yield put(groupDeletedError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* updateGroupSaga({ payload: { id: groupId, ...values } }) {
  try {
    const { data } = yield call(updateGroup, groupId, values);
    yield put(groupUpdated(data));
  } catch (e) {
    yield put(groupUpdatedError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* addGroupUserSaga({ payload: { groupId, userId } }) {
  try {
    yield call(addGroupUser, groupId, userId);
    yield put(groupUserAdded({ groupId, userId }));
  } catch (e) {
    yield put(groupUserAddedError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* removeGroupUserSaga({ payload: { groupId, userId } }) {
  try {
    yield call(removeGroupUser, groupId, userId);
    yield put(groupUserRemoved({ groupId, userId }));
  } catch (e) {
    yield put(groupUserRemovedError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* groupFormSaga(form) {
  try {
    const initialValues = yield select(getFormInitialValues(form));
    const { id, parentId, ...values } = yield select(getFormValues(form));
    const safeValues = pickBy(
      values,
      (val, key) => val !== null && !isEqual(val, initialValues[key])
    );
    if (isEmpty(safeValues)) {
      yield;
    } else if (id) {
      yield put(updateGroupAction({ ...safeValues, id }));
    } else {
      yield put(
        createGroupAction(parentId ? { ...safeValues, parentId } : safeValues)
      );
      yield put(resetForm(form));
    }
  } catch (e) {
    yield put(groupUpdatedError(e));
  }
}

export default function* groupsSaga() {
  yield takeLatest(GROUPS_LOAD, getGroupsSaga);
  yield takeLatest(GROUP_CREATE, createGroupSaga);
  yield takeLatest(GROUP_DELETE, deleteGroupSaga);
  yield takeLatest(GROUP_UPDATE, updateGroupSaga);
  yield takeEvery(GROUP_USER_ADD, addGroupUserSaga);
  yield takeEvery(GROUP_USER_REMOVE, removeGroupUserSaga);
}
