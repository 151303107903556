import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Opportunity, OpportunityCandidate } from '@axiom/validation';
import { CandidateOpportunityUtils } from '@axiom/utils';
import { Badge, Layout, ParagraphHeader, TilesItem, Tiles } from '@axiom/ui';
import { CandidateOpportunitiesConst } from '@axiom/const';

import { CandidateUtil } from '../../utils/candidate-util';

import { OTCandidateName } from './OpportunityTalentStyles';
import { OpportunityTalentRateMargin } from './OpportunityTalentRateMargin';

const {
  AllStatusLabels: AllStatusLabelsConst,
  CandidateStatuses,
  OppCandMlRecStatuses,
} = CandidateOpportunitiesConst;
const AllStatusLabels: Record<string, string> = AllStatusLabelsConst;

interface OpportunityTalentParentPositionListProps {
  opportunity: Opportunity;
  candidates: Array<OpportunityCandidate>;
  isTalentPool?: boolean;
}

export const OpportunityTalentParentPositionList: FC<
  OpportunityTalentParentPositionListProps
> = ({ opportunity, candidates, isTalentPool }) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Tiles columnWidth={1} name="talent-list">
      {candidates.map(candidate => {
        const hasSubmittedRate =
          CandidateOpportunityUtils.hasSubmittedRate(candidate);

        const isNew =
          !opportunity.isClosed &&
          [
            CandidateStatuses.Interested,
            OppCandMlRecStatuses.AiSuggested,
          ].includes(candidate.candidateStatus);

        const isSelfSubmit =
          opportunity.directEligible &&
          candidate.candidateStatus === CandidateStatuses.Submitted &&
          candidate.previousCandidateStatus === CandidateStatuses.Interested;

        const needsCooled =
          candidate.candidateStatus === CandidateStatuses.Rejected;

        return (
          <TilesItem
            key={candidate.id}
            name={candidate.id}
            selected={params.viewId === candidate.id}
            onClick={() =>
              navigate(
                `/opportunity-detail/${opportunity.id}/talent/${candidate.candidateId}`
              )
            }
          >
            <Layout direction="vertical" verticalGutter="8px">
              <Layout position="top space-between">
                <OTCandidateName>
                  <ParagraphHeader maxLines={1}>
                    {candidate.calculatedDisplayName}
                  </ParagraphHeader>
                </OTCandidateName>
                <Layout
                  position="right top"
                  horizontalGutter="4px"
                  verticalGutter="4px"
                  wrap
                >
                  {!!candidate.isCrossFulfilled && (
                    <Badge background="yellow">Cross</Badge>
                  )}
                  {!!isNew && <Badge background="blue">New</Badge>}
                  {!!needsCooled && (
                    <Badge background="yellow">Needs Cooled</Badge>
                  )}
                  {!!isSelfSubmit && <Badge background="gray">Self-Sub</Badge>}
                  <Badge
                    background={CandidateUtil.getCandidateStatusBadgeColor(
                      candidate.candidateStatus
                    )}
                  >
                    {AllStatusLabels[candidate.candidateStatus]}
                  </Badge>
                </Layout>
              </Layout>
              {!!(!isTalentPool || hasSubmittedRate) && (
                <OpportunityTalentRateMargin
                  opportunityCandidate={candidate}
                  opportunity={opportunity}
                />
              )}
            </Layout>
          </TilesItem>
        );
      })}
    </Tiles>
  );
};
