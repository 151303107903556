import { CandidateOpportunitiesConst, WeeklyTimeUnits } from '@axiom/const';
import sortBy from 'lodash/sortBy';

export const PositionUtil = {
  getFulfillmentStatus() {
    // eslint-disable-next-line unicorn/no-unreadable-iife
    return (({ Selected, Engaged, Completed }) => ({
      Selected,
      Engaged,
      Completed,
    }))(CandidateOpportunitiesConst.CandidateStatuses);
  },

  isCandidateStatusFulfilled(candidateStatus) {
    return !!this.getFulfillmentStatus()[candidateStatus];
  },

  isPositionFulfilled(candidates = []) {
    return !!candidates.some(candidate =>
      this.isCandidateStatusFulfilled(candidate.candidateStatus)
    );
  },

  sortPositions(positions) {
    return sortBy(positions, ['createdAt', 'id']);
  },

  calculatePositionHoursPerWeek(position) {
    if (position.weeklyTimeUnit === WeeklyTimeUnits.Hourly) {
      return position.billingUnitsPerWeek;
    }
    return position.billingUnitsPerWeek * position.billingHoursPerDay;
  },
};
