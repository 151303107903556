import { Opportunity } from '@axiom/const';

const constStageMaps = Opportunity.STAGE_CODE_MAP;

export const OPPORTUNITY_STAGE_OPTIONS = {
  [constStageMaps[Opportunity.DEVELOP]]: 'Stage 0b',
  [constStageMaps[Opportunity.VALIDATE]]: 'Stage 0b',
  [constStageMaps[Opportunity.CRAFT]]: 'Stage 1',
  [constStageMaps[Opportunity.COMPETE]]: 'Stage 2',
  [constStageMaps[Opportunity.CONTRACT]]: 'Stage 3',
  [constStageMaps[Opportunity.CLOSED_WON]]: 'Closed Won',
  [constStageMaps[Opportunity.CLOSED_LOST]]: 'Closed Lost',
};
export const OpportunityUtil = {
  formatStageCode(stageCode) {
    return OPPORTUNITY_STAGE_OPTIONS[stageCode] || null;
  },

  getFulfillmentStatusColorName(isFulfillmentActive) {
    return isFulfillmentActive ? 'ACTIVE_OPPORTUNITY' : 'INACTIVE_OPPORTUNITY';
  },
  isClosed(opportunity) {
    return (
      opportunity.stage === Opportunity.CLOSED_WON ||
      opportunity.stage === Opportunity.CLOSED_LOST
    );
  },
  getWorkTypes() {
    return {
      Onsite: 'On-Site',
      Remote: 'Remote',
      'Onsite/Remote': 'On-Site or Remote',
    };
  },
};
