import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('opportunity activity');

export const getActivitiesByJobId = async jobId => {
  const response = await api.GET(`/opportunities/${jobId}/events`);
  return { data: response.data };
};

export const addActivityByJobId = async body => {
  const response = await api.MULTIPOST(`/events`, body);
  return { data: response.data };
};
