import { Api } from '@axiom/ui';
import { Language } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';
import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('language');

export const getLanguages = async () => {
  const response = await api.GET(`/languages`);
  return {
    data: response.data,
  };
};

class LanguagesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readLanguages() {
    return super.read<{ data: Array<Language> }>({
      endpoint: '/languages',
      method: 'GET',
    });
  }
}

export const LanguagesApi = new LanguagesApiClass();
export default {
  getLanguages,
};
