import React from 'react';
import { bool, func, string, node } from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { CalendarIcon, ChevronIcon } from '@axiom/ui';

import { DatePropType, DateUtil } from '../../utils/date-util';
import DateMonthYearPicker from '../DateMonthYearPicker/DateMonthYearPicker';
import { formatDataTestId } from '../../utils/data-test-util';
import { generateRandomInt } from '../../utils/random-util';

import { DatePickerWrapper, StyledLabel } from './DatePickerStyles';

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      dropdownFocused: false,
    };
  }

  render() {
    const {
      anchorDirection,
      className,
      date,
      disabled,
      id,
      label,
      openDirection,
      onChange,
      placeholder,
      showClearDate,
      disablePastDates,
      disableParentScrolling,
    } = this.props;

    const { dropdownFocused, focused } = this.state;

    const parsedDate = date ? DateUtil.castAsMoment(date) : undefined;
    const formatDate = dateValue => DateUtil.formatAsDate(dateValue);

    const isOutsideRangeFunction = disablePastDates
      ? day => moment().startOf('day').isSameOrAfter(day)
      : () => false;

    return (
      <DatePickerWrapper
        id={id}
        data-test={formatDataTestId(
          (typeof label === 'string' && label) || 'DatePicker'
        )}
        className={className}
      >
        {!!label && (
          <StyledLabel htmlFor={id} isDisabled={disabled}>
            {label}
          </StyledLabel>
        )}
        <SingleDatePicker
          isOutsideRange={isOutsideRangeFunction}
          anchorDirection={anchorDirection}
          block
          date={parsedDate}
          disabled={disabled}
          enableOutsideDays
          focused={focused || dropdownFocused}
          hideKeyboardShortcutsPanel
          horizontalMonthPadding={50}
          id={id}
          disableScroll={disableParentScrolling}
          customInputIcon={<CalendarIcon />}
          inputIconPosition="after"
          transitionDuration={0}
          navNext={<ChevronIcon direction="right" />}
          navPrev={<ChevronIcon direction="left" />}
          numberOfMonths={1}
          openDirection={openDirection}
          onFocusChange={({ focused: focusedValue }) => {
            if (!dropdownFocused) {
              this.setState({
                focused: focusedValue,
              });
            }
          }}
          onDateChange={changedDate => {
            this.setState(
              {
                focused: false,
                dropdownFocused: false,
              },
              () => onChange(formatDate(changedDate))
            );
          }}
          placeholder={placeholder}
          readOnly
          renderMonthElement={({
            // eslint-disable-next-line no-shadow
            month: date,
            onMonthSelect,
            onYearSelect,
          }) => (
            <DateMonthYearPicker
              yearOptions={DateUtil.getYearOptionsByAmount(true, 10, 10)}
              onMonthChange={newMonth => onMonthSelect(date, newMonth)}
              onYearChange={newYear => onYearSelect(date, newYear)}
              onFocus={() => {
                this.setState({
                  focused: true,
                  dropdownFocused: true,
                });
              }}
              onBlur={() => {
                this.setState({
                  focused: true,
                  dropdownFocused: false,
                });
              }}
              date={date}
            />
          )}
          showClearDate={showClearDate}
          weekDayFormat="dd"
        />
      </DatePickerWrapper>
    );
  }
}

DatePicker.defaultProps = {
  anchorDirection: null,
  className: null,
  date: null,
  disabled: null,
  id: generateRandomInt(),
  label: null,
  onChange: () => {},
  openDirection: null,
  placeholder: null,
  showClearDate: null,
  disablePastDates: false,
  disableParentScrolling: true,
};

DatePicker.propTypes = {
  anchorDirection: string,
  className: string,
  date: DatePropType,
  disabled: bool,
  disableParentScrolling: bool,
  disablePastDates: bool,
  id: string,
  label: node,
  openDirection: string,
  placeholder: string,
  showClearDate: bool,
  onChange: func,
};
