import FormStore from '../classes/form-store';
import { certificationShape } from '../models/certification';
import { AjvUtil } from '../utils/ajv-util';
import { createEducation, updateEducation } from '../api/education-api';
import { getDecoratedTalent } from '../redux/actions/talent';

const EducationValidation = AjvUtil.compileSchema({
  required: ['lawSchoolId', 'degree', 'yearAwarded'],
  properties: {
    lawSchoolId: {
      type: ['string'],
      minLength: 1,
    },
    educationId: {
      type: ['string'],
    },
    degree: {
      type: ['string'],
      maxLength: 255,
      minLength: 1,
    },
    yearAwarded: {
      type: ['number'],
      minLength: 1,
    },
    stateExamScore: {
      type: ['string'],
    },
  },
});

class FormEducationItemClass extends FormStore {
  getDataShape() {
    return certificationShape;
  }

  load(candidateId, education) {
    const loadState = education
      ? {
          candidateId,
          educationId: education.id,
          institution: education.institution,
          lawSchoolId: education.lawSchoolId,
          degree: education.degree,
          yearAwarded: education.yearAwarded,
          stateExamScore: education.stateExamScore,
        }
      : {
          candidateId,
        };

    return this.setState(loadState);
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(EducationValidation, {
      ...formData,
    });
  }

  submit(changedFields, formData) {
    const { candidateId } = formData;

    return this.clearState(
      formData.educationId
        ? updateEducation(formData.educationId, { ...changedFields })
        : createEducation(candidateId, {
            candidateId,
            ...changedFields,
          })
    ).then(() => this.dispatch(getDecoratedTalent(candidateId)));
  }
}

export const FormEducationItemStore = new FormEducationItemClass();
