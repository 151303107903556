import { shape, arrayOf, string, oneOf } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { getLawSchoolRanks } from '../api/tags-api';

class LawSchoolRanksClass extends GenericStore {
  getDataShape() {
    return arrayOf(
      shape({
        id: string,
        name: string,
        type: oneOf(['lawSchoolRank']),
      })
    );
  }

  load() {
    return this.setState(getLawSchoolRanks().then(({ data }) => data));
  }

  select(state) {
    return super.select(state)?.data || [];
  }

  selectAsOptions(state) {
    const data = this.select(state);
    return data.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }
}

export const PreloadedLawSchoolRanksStore = new LawSchoolRanksClass();
