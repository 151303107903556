/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  oneOfType,
  string,
  func,
  number,
  shape,
  arrayOf,
  bool,
} from 'prop-types';
import { Field } from 'react-final-form';

import { EnvUtil } from '../../utils/env-util';
import { window } from '../../utils/global-util';
import { formatDataTestId } from '../../utils/data-test-util';

import LocationTypeaheadKendoComboBox from './LocationTypeaheadKendoComboBox';
import {
  DivLocationTypeaheadWrapper,
  DivInputDropdownWrapper,
  DivGoogleMapWrapper,
} from './LocationTypeaheadStyles';

export const DefaultLocation = {
  locationAddressComponents: null,
  locationLatitude: 40.7127753,
  locationLongitude: -74.0059728,
  locationName: '',
  locationPlaceId: null,
};

export const defaultLocationShape = shape({
  locationName: string,
  locationPlaceId: string,
  locationLatitude: number,
  locationLongitude: number,
  locationAddressComponents: shape({
    addresses: arrayOf(shape({})),
  }),
});

class LocationTypeahead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: this.props.remainOpen || false,
      sessionToken: '',
      predictions: [],
    };

    this.mapRef = React.createRef();
  }

  componentDidMount() {
    this.loadGoogleMaps().then(() => {
      this.map = new window.google.maps.Map(this.mapRef.current, {
        center: {
          lat: this.props.defaultLocation.locationLatitude,
          lng: this.props.defaultLocation.locationLongitude,
        },
        controlSize: 22,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        clickableIcons: false,
        draggable: false,
        mapTypeId: 'roadmap',
        zoom: 13,
      });

      this.placesService = new window.google.maps.places.PlacesService(
        this.map
      );
      this.autocompleteService =
        new window.google.maps.places.AutocompleteService();
      this.marker = new window.google.maps.Marker({ map: this.map });

      this.setState({
        sessionToken: new window.google.maps.places.AutocompleteSessionToken(),
      });
    });
  }

  // eslint-disable-next-line react/sort-comp
  setIsFocused = () => {
    this.setState({ isFocused: this.props.remainOpen || true });
  };

  setIsBlurred = () => {
    this.setState({ isFocused: this.props.remainOpen || false });
  };

  emitChange = locObj => {
    const setObj = {
      locationAddressComponents: locObj.locationAddressComponents,
      locationLatitude: locObj.locationLatitude,
      locationLongitude: locObj.locationLongitude,
      locationName: locObj.locationName,
      locationPlaceId: locObj.locationPlaceId,
    };

    this.props.onChange(setObj);
    this.setState(setObj);
  };

  onTextInputChange = value => {
    if (!!this.autocompleteService && value.length >= 2) {
      this.autocompleteService.getPlacePredictions(
        {
          input: value,
          sessionToken: this.state.sessionToken,
        },
        (predictions, status) => {
          if (status === 'OK') {
            this.setState({
              predictions: predictions.map(prediction => ({
                locationName: prediction.description,
                locationPlaceId: prediction.place_id,
                locationAddressComponents: null,
                locationLatitude: null,
                locationLongitude: null,
              })),
            });
          }
        }
      );
    } else {
      this.emitChange(DefaultLocation);
    }
  };

  onDropdownSelect = prediction => {
    this.placesService.getDetails(
      {
        placeId: prediction.locationPlaceId,
        sessionToken: this.state.sessionToken,
      },
      (placeDetails, status) => {
        if (status === 'OK') {
          this.map.setCenter(placeDetails.geometry.location);
          this.marker.setMap(null);
          this.marker.setPosition(placeDetails.geometry.location);
          setTimeout(() => this.marker.setMap(this.map), 200);
          this.setState({
            sessionToken:
              new window.google.maps.places.AutocompleteSessionToken(),
          });
          this.emitChange({
            locationAddressComponents: {
              addresses: placeDetails.address_components,
            },
            locationName: prediction.locationName,
            locationLatitude: placeDetails.geometry.location.lat(),
            locationLongitude: placeDetails.geometry.location.lng(),
            locationPlaceId: placeDetails.place_id,
          });
        }
      }
    );
  };

  loadGoogleMaps() {
    return new Promise((resolve, reject) => {
      const src = EnvUtil.googleMapsApiUrl;
      if (window.document.querySelector(`script[src='${src}']`)) {
        setTimeout(() => resolve(), 1000);
      } else {
        const scriptElement = window.document.createElement('script');
        scriptElement.addEventListener('load', resolve);
        scriptElement.addEventListener('error', reject);
        scriptElement.setAttribute('src', src);
        window.document.head.append(scriptElement);
      }
    });
  }

  render() {
    const { ComboBoxComponent, remainOpen } = this.props;

    return (
      <DivLocationTypeaheadWrapper
        onBlur={this.setIsBlurred}
        onFocus={this.setIsFocused}
      >
        <DivInputDropdownWrapper
          isOpen={this.state.isFocused}
          data-test={formatDataTestId('locationTypeahead')}
        >
          <ComboBoxComponent
            defaultLocation={this.props.defaultLocation}
            onTextInputChange={this.onTextInputChange}
            onDropdownSelect={this.onDropdownSelect}
            predictions={this.state.predictions}
          />
        </DivInputDropdownWrapper>
        <DivGoogleMapWrapper
          data-test={formatDataTestId('G_map')}
          data-closed={!this.state.isFocused}
          isOpen={this.state.isFocused}
          data-remainopen={remainOpen}
          ref={this.mapRef}
        />
        <Field component="input" hidden name="locationAddressComponents" />
        <Field component="input" hidden name="locationLatitude" />
        <Field component="input" hidden name="locationLongitude" />
        <Field component="input" hidden name="locationName" />
        <Field component="input" hidden name="locationPlaceId" />
      </DivLocationTypeaheadWrapper>
    );
  }
}

LocationTypeahead.defaultProps = {
  ComboBoxComponent: LocationTypeaheadKendoComboBox,
  defaultLocation: DefaultLocation,
  remainOpen: false,
};

LocationTypeahead.propTypes = {
  ComboBoxComponent: oneOfType([string, func]),
  defaultLocation: defaultLocationShape,
  remainOpen: bool,
  onChange: func.isRequired,
};

export default LocationTypeahead;
