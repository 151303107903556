import { UserOpportunitiesStoreMetaConst } from '../../stores/user-opportunities-store';

export const FILTER_OPTIONS_CONFIG = [
  {
    value:
      UserOpportunitiesStoreMetaConst.assigneeType
        .accountManagerOwnerOrSalesLead,
    label: 'Assigned to me',
  },
  {
    value: UserOpportunitiesStoreMetaConst.assigneeType.collaborator,
    label: 'Collaborating on',
  },
];

export const SORT_OPTIONS_CONFIG = [
  {
    value: UserOpportunitiesStoreMetaConst.sort.opportunityName,
    label: 'Opportunity Name',
  },
  {
    value: UserOpportunitiesStoreMetaConst.sort.accountName,
    label: 'Account Name',
  },
  {
    value: UserOpportunitiesStoreMetaConst.sort.stage,
    label: 'Stage',
  },
  {
    value: UserOpportunitiesStoreMetaConst.sort.closeDate,
    label: 'Close Date',
  },
  {
    value: UserOpportunitiesStoreMetaConst.sort.positions,
    label: 'Positions',
  },
  {
    value: UserOpportunitiesStoreMetaConst.sort.teLead,
    label: 'TS Lead',
  },
  {
    value: UserOpportunitiesStoreMetaConst.sort.salesLeadId,
    label: 'Opportunity Owner',
  },
];

export const WORKSPACE_FILTER_KEYS = {
  TYPE: 'type',
  STAGE: 'stage',
};
