import React from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import {
  CertificationPlaceholderIcon,
  Gutter,
  Header4,
  IconButton,
} from '@axiom/ui';

import NoContentPlaceholder from '../NoContentPlaceholder/NoContentPlaceholder';
import { Footnote } from '../../styled';
import { LogHeader, LogHeaderRight, LogItems } from '../../styled/logs';
import { formatDataTestId } from '../../utils/data-test-util';
import { CandidateCertificationsStore } from '../../stores/candidate-certifications-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { FormCertificationItemStore } from '../../stores/form-certification-item-store';
import CertificationForm from '../CertificationForm/CertificationForm';

import CertificationItem from './CertificationsItem';

const MAX_ENTRIES = 10;

const Certifications = ({ candidateId, certsState }) => (
  <StoreStateTreatment
    name="CERTIFICATIONS_TREATMENT"
    storeState={certsState}
    renderLoadedView={({ data: certifications }) => (
      <Gutter name="AWARDS_CONTAINER" top="2rem" bottom="2rem">
        <LogHeader>
          <Header4>Qualifications, Certifications, & Awards</Header4>
          <LogHeaderRight>
            <IconButton
              icon="plus"
              pattern="secondary"
              variation="minimal"
              name="ADDCERTIFICATIONBUTTON"
              disabled={certifications.length >= MAX_ENTRIES}
              onClick={() => FormCertificationItemStore.load({ candidateId })}
            />
          </LogHeaderRight>
        </LogHeader>
        {certifications.length > 0 ? (
          <>
            <LogItems data-test={formatDataTestId('award_cards')}>
              {certifications.map(certification => (
                <li key={certification.id}>
                  <CertificationItem certification={certification} />
                </li>
              ))}
            </LogItems>
            <Footnote>({MAX_ENTRIES} entries maximum)</Footnote>
          </>
        ) : (
          <NoContentPlaceholder
            image={<CertificationPlaceholderIcon />}
            title="No Certifications & Awards"
            text="Add your certifications and awards."
          />
        )}
        <CertificationForm />
      </Gutter>
    )}
  />
);

Certifications.defaultProps = {};

Certifications.propTypes = {
  candidateId: string.isRequired,
  certsState: CandidateCertificationsStore.getStateShape().isRequired,
};

export default connect(state => ({
  certsState: CandidateCertificationsStore.select(state),
}))(Certifications);
