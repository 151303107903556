import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Gutter, ModalSection, Suspense, TabsItem, Tabs } from '@axiom/ui';

import LawSchoolsManagementView from '../LawSchoolsManagementView/LawSchoolsManagementView';
import { loadUsers as loadUsersAction } from '../../redux/actions/users';
import { usersLastUpdatedSelector } from '../../redux/selectors/users';

import { AdminModalAreaStudy } from './AdminModalAreaStudy';
import UserManagementView from './AdminModalUserManagementView';
import TagManagementView from './AdminModalTagManagementView';

const UPDATE_INTERVAL = 120000; // 2 minutes
type Props = {
  loadUsers?: () => void;
  usersUpdated?: number;
};
const AdminFunctionsModalComponent = ({
  loadUsers = () => {},
  usersUpdated = 0,
}: Props) => {
  useEffect(() => {
    const now = Date.now();
    if (now - usersUpdated > UPDATE_INTERVAL) {
      loadUsers();
    }
  }, []);

  return (
    <ModalSection name="admin-func-section">
      <Tabs>
        <TabsItem name="Users" label="Users">
          <Gutter bottom="8px" />
          <UserManagementView />
        </TabsItem>
        <TabsItem name="Tags" label="Tags">
          <Gutter bottom="8px" />
          <TagManagementView />
        </TabsItem>
        <TabsItem name="Schools" label="Schools">
          <Gutter bottom="8px" />
          <LawSchoolsManagementView />
        </TabsItem>
        <TabsItem name="Area of Study" label="Area of Study">
          <Gutter bottom="8px" />
          <Suspense>
            <AdminModalAreaStudy />
          </Suspense>
        </TabsItem>
      </Tabs>
    </ModalSection>
  );
};

export const AdminModal = connect<Props>(
  // @ts-expect-error Old code
  createStructuredSelector({
    usersUpdated: usersLastUpdatedSelector,
  }),
  {
    loadUsersAction,
  }
)(AdminFunctionsModalComponent);
