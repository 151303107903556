import React from 'react';
import { string, number, func, bool, oneOfType } from 'prop-types';
import get from 'lodash/get';
import { DataTest, Label } from '@axiom/ui';

import { formatDataTestId } from '../../utils/data-test-util';
import {
  DropdownOptionsShape,
  DropdownSelectedValueShape,
} from '../../models/dropdown-options';

import {
  DropdownListWrapper,
  KendoDropdownListWrapper,
  LabelWrapper,
} from './DropdownListStyles';

const DropdownList = ({
  className,
  options,
  dataItemKey,
  disabled,
  id,
  label,
  name,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  onChangeAction,
  textField,
  valid,
  value,
  type,
}) => {
  const OptionComponent = (li, itemProps) => {
    const itemChildren = (
      <LabelWrapper data-value={itemProps.dataItem.value}>
        {li.props.children}
      </LabelWrapper>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleOnChange = event => {
    onChange(event.target.value.value);
    onChangeAction(event.target.value.value);
  };

  const handleOnBlur = event => {
    onBlur(get(event.target.value, 'value', null));
  };

  const getValueFromOptions = opt =>
    opt.find(option => option.value === value) || null;

  const val = getValueFromOptions(options);

  return (
    <DataTest value={id || name}>
      {!!label && (
        <Label invalid={!valid} htmlFor={id || name}>
          {label}
        </Label>
      )}
      <DropdownListWrapper
        disabled={disabled}
        invalid
        data-test={formatDataTestId('dropdownlist')}
        data-value={val?.value}
        id={id}
      >
        <KendoDropdownListWrapper
          type={type}
          name={name}
          placeholder={placeholder}
          valid={valid}
          className={className}
          data={options}
          dataItemKey={dataItemKey}
          defaultItem={{ label: placeholder }}
          disabled={disabled}
          itemRender={OptionComponent}
          onFocus={onFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          textField={textField}
          value={val}
          popupSettings={{ animate: false }}
        />
      </DropdownListWrapper>
    </DataTest>
  );
};

DropdownList.defaultProps = {
  className: null,
  options: [],
  dataItemKey: 'value',
  disabled: false,
  id: null,
  label: null,
  name: undefined,
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  onChangeAction: () => {},
  placeholder: '',
  textField: 'label',
  valid: true,
  value: undefined,
  type: undefined,
};

DropdownList.propTypes = {
  className: string,
  dataItemKey: string,
  disabled: bool,
  id: string,
  label: string,
  name: string,
  options: DropdownOptionsShape,
  placeholder: oneOfType([string, number]),
  textField: string,
  type: string,
  valid: bool,
  value: DropdownSelectedValueShape,
  onBlur: func,
  onChange: func,
  onChangeAction: func,
  onFocus: func,
};

export default DropdownList;
