/**
 *
 * EngagementsItemPlaceholder
 *
 */

import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  display: block;
  height: auto;
  margin: 10px 0;
  opacity: 0.5;
  width: 100%;

  path {
    fill: currentColor;
  }
`;

const EngagementsItemPlaceholder = () => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1010 142"
    role="presentation"
    aria-hidden="true"
  >
    <path d="M 0 0 L 0 1 L 0 101 L 0 142 L 1 142 L 1010 142 L 1010 101 L 1010 0 L 1 0 L 0 0 z M 1 1 L 1009 1 L 1009 101 L 1 101 L 1 1 z M 20 23 L 20 40 L 230 40 L 230 23 L 20 23 z M 542 23 L 542 40 L 752 40 L 752 23 L 542 23 z M 963 35 L 963 64 L 992 64 L 992 35 L 963 35 z M 20 57 L 20 80 L 325 80 L 325 57 L 20 57 z M 542 57 L 542 80 L 847 80 L 847 57 L 542 57 z " />
  </Svg>
);

export default EngagementsItemPlaceholder;
