import { string, number, shape, arrayOf } from 'prop-types';
import { Opportunity } from '@axiom/const';

import GenericStore from '../classes/generic-store';
import { postTalentsByOpportunityID } from '../api/opportunity-talent-api';
import { getDecoratedTalent } from '../redux/actions/talent';
import { getOpportunities } from '../api/opportunities-legacy-api';
import { CandidateOpportunityLegacyApi } from '../api/candidate-opportunities-legacy-api';

class AppFindOppForCandidateModalStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      candidateIds: arrayOf(string.isRequired),
      reloadCandidateId: string,
      searchTerm: string,
      results: arrayOf(shape({})),
      totalResultsCount: number,
      page: number,
    });
  }

  openModal(candidateIds, reloadCandidateIdOnSubmit) {
    return this.setState({
      candidateIds: candidateIds || [],
      reloadCandidateId: reloadCandidateIdOnSubmit,
      searchTerm: '',
      results: [],
      totalResultsCount: 0,
    });
  }

  findCandidates(searchTerm, storeData) {
    if (searchTerm.length > 1) {
      return this.setState(
        getOpportunities({
          filters: {
            stageCode: [
              Opportunity.STAGE_CODE_MAP[Opportunity.DEVELOP],
              Opportunity.STAGE_CODE_MAP[Opportunity.CRAFT],
              Opportunity.STAGE_CODE_MAP[Opportunity.COMPETE],
              Opportunity.STAGE_CODE_MAP[Opportunity.CONTRACT],
            ],
            isFulfillmentActive: true,
          },
          search: searchTerm,
          sort: 'relevance',
          page: storeData.page,
        }).then(data => ({
          searchTerm,
          page: data.meta.currentPage,
          results: [...storeData.results, ...(data.data || [])],
          totalResultsCount: data.meta.resultCount,
        }))
      );
    }

    return null;
  }

  submit(storeData, opportunityId) {
    if (opportunityId && storeData.candidateIds.length > 0) {
      const { candidateIds, reloadCandidateId } = storeData;

      return this.clearState(
        postTalentsByOpportunityID({ opportunityId, candidateIds })
      ).then(() => {
        if (reloadCandidateId) {
          this.dispatch(getDecoratedTalent(reloadCandidateId));
          CandidateOpportunityLegacyApi.refreshCandidateOpportunities(
            reloadCandidateId
          );
        }
      });
    }
    return null;
  }
}

export const AppFindOppForCandidateModalStore =
  new AppFindOppForCandidateModalStoreClass();
