import fOmit from 'lodash/fp/omit';

import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('language');

export const getLanguagesByTalentId = async candidateID => {
  const response = await api.GET(`/candidates/${candidateID}/languages`);
  return { data: response.data };
};

export const postLanguagesByTalentId = async (talentId, languageObjects) => {
  const safeLanguages = languageObjects.map(
    fOmit(['id', 'candidateId', 'createdAt', 'updatedAt', 'name'])
  );
  const { data } = await api.PUT(
    `/candidates/${talentId}/languages`,
    safeLanguages
  );
  return { data };
};
