import { arrayOf, exact, string } from 'prop-types';
import { CandidateProfileStatusStateMachine } from '@axiom/utils';

import GenericStore from '../classes/generic-store';
import { getOpportunitiesByTalentId } from '../api/talent-opportunities-api';
import { CandidateOpportunityShape } from '../models/candidate-opportunity';

class CandidateOpportunitiesStoreClass extends GenericStore {
  load(candidate) {
    return this.setState(
      getOpportunitiesByTalentId(candidate.id).then(response => {
        const candidateProfileStatusTransition =
          new CandidateProfileStatusStateMachine(candidate, response.data);

        const statuses =
          candidateProfileStatusTransition.allowedEndUserStatusTransitions();

        return {
          opportunities: response.data,
          nextAvailableStatuses: statuses.map(s => ({ label: s, value: s })),
        };
      })
    );
  }

  getNextAvailableStatusesShape() {
    return arrayOf(
      exact({
        label: string.isRequired,
        value: string.isRequired,
      }).isRequired
    );
  }

  getDataShape() {
    return exact({
      opportunities: arrayOf(CandidateOpportunityShape),
      nextAvailableStatuses: this.getNextAvailableStatusesShape(),
    });
  }

  selectData(state) {
    return this.select(state)?.data || [];
  }
}

export const CandidateOpportunitiesStore =
  new CandidateOpportunitiesStoreClass();
