import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flashy } from '@axiom/ui';

import SearchInput from '../SearchInput/SearchInput';
import { EnvUtil } from '../../utils/env-util';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { formatDataTestId } from '../../utils/data-test-util';

import {
  Root,
  StaticItem,
  DynamicScrollingItem,
  UserList,
  UserListItem,
} from './UserManagementListStyles';

const UserManagementList = ({ selected, onSelectedChange, users }) => {
  const [query, setQuery] = useState('');

  const handleQueryChange = e => setQuery(e.target.value);

  const queryRegExp = new RegExp(query, 'i');

  const filteredUsers = query
    ? users.filter(({ firstName, lastName }) =>
        queryRegExp.test(`${firstName} ${lastName}`)
      )
    : users;

  return (
    <Root>
      <StaticItem>
        <h3>User Management</h3>
      </StaticItem>
      <StaticItem>
        <SearchInput
          aria-label="Search Users"
          data-test={formatDataTestId('userMgmtSearch')}
          onChange={handleQueryChange}
          value={query}
        />
      </StaticItem>
      <DynamicScrollingItem>
        <UserList data-test={formatDataTestId('userList')} role="listbox">
          {filteredUsers.map(user => (
            <UserListItem
              className="UserListItem"
              key={user.id}
              isInactive={!user.isActive}
              isSelected={!!selected && selected.id === user.id}
              onClick={() => onSelectedChange(user)}
            >
              {user.firstName} {user.lastName}
            </UserListItem>
          ))}
        </UserList>
      </DynamicScrollingItem>
      <StaticItem divider>
        <Flashy bold>
          <a
            href={EnvUtil.reportAnIssueUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            To create a new user, contact us at Service Cloud
          </a>
        </Flashy>
      </StaticItem>
    </Root>
  );
};

UserManagementList.defaultProps = {
  users: [],
  selected: null,
};

UserManagementList.propTypes = {
  selected: PreloadedUserStore.getDataShape(),
  users: PropTypes.arrayOf(PreloadedUserStore.getDataShape()),
  onSelectedChange: PropTypes.func.isRequired,
};

export default UserManagementList;
