/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import styled from 'styled-components';
import { TaxonomyUtil, useApi } from '@axiom/ui';

import { FFDropdown } from '../FFDropdown/FFDropdown';
import { TaxonomyApi } from '../../api/taxonomy-api';

const StyledFFDropdown = styled(FFDropdown)`
  flex: 0 0 100%;
`;

export const FFIndustryDropdown = ({ ...rest }) => {
  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());
  const industryOptions = TaxonomyUtil.getIndustryOptions(taxonomy.industries);

  return (
    <StyledFFDropdown {...rest} label="Industry" options={industryOptions} />
  );
};

FFIndustryDropdown.propTypes = {};
