import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('collaborator');

export const getCollaboratorsByTalentId = async talentId => {
  const response = await api.GET(`/candidates/${talentId}/users`);
  return {
    data: response.data,
  };
};

export const postCollaboratorsByTalentId = async (talentId, collaborators) => {
  const response = await api.PUT(
    `/candidates/${talentId}/users`,
    collaborators
  );
  return {
    talentId: response.data,
    id: talentId,
  };
};

export default {
  getCollaboratorsByTalentId,
  postCollaboratorsByTalentId,
};
