/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment,global-require,unicorn/prefer-module  */
import React, { Component } from 'react';
import size from 'lodash/fp/size';
import 'draft-js/dist/Draft.css';
import { bool, func, node, number, string } from 'prop-types';
import { BulletsIcon, ResetIcon, TextareaReplacementUtil } from '@axiom/ui';

import InputCharRemaining from '../InputCharRemaining/InputCharRemaining';

import {
  ButtonUlToggleWrapper,
  DivButtonsWrapper,
  DivEditorWrapper,
  DivRichTextEditorWrapper,
  ResetButton,
} from './RichTextEditorStyles';

let draftConvert;
let draftJs;
let convertFromHTML;
let convertToRaw;
let Editor;
let EditorState;
let SelectionState;
let RichUtils;

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    this.editorRef = React.createRef();
    this.isFocused = false;
    this.state = {
      plainText: props.initialValue || '',
      editorState: null,
    };
  }

  componentDidMount() {
    draftConvert = require('draft-convert');
    draftJs = require('draft-js');
    convertFromHTML = draftConvert.convertFromHTML;
    convertToRaw = draftJs.convertToRaw;
    Editor = draftJs.Editor;
    EditorState = draftJs.EditorState;
    SelectionState = draftJs.SelectionState;
    RichUtils = draftJs.RichUtils;
    this.setState({
      editorState: this.newEditorState(this.props.initialValue),
    });
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.dirty && !this.props.dirty) ||
      (prevProps.initialValue !== this.props.initialValue &&
        this.props.initialValue !== this.state.plainText)
    ) {
      this.setState({
        plainText: this.props.initialValue,
        editorState: this.moveSelectionToEnd(
          this.newEditorState(this.props.initialValue)
        ),
      });
    }

    if (this.props.isFocused !== prevProps.isFocused) {
      if (this.props.isFocused) {
        if (!this.isFocused) {
          this.setState(
            state => ({
              editorState: this.moveSelectionToEnd(state.editorState),
            }),
            () => {
              this.isFocused = true;
            }
          );
        }
      } else {
        this.editorRef.current.blur();
        this.isFocused = false;
      }
    }
  }

  onChange = editorState => {
    const plainText = new TextareaReplacementUtil(
      this.buildPlainText(editorState)
    )
      .replaceFourOrMoreStartingSpaces()
      .getText();
    this.setState(
      {
        editorState,
        plainText,
      },
      () => {
        this.props.onChange(plainText);
      }
    );
  };

  onFocus = () => {
    this.isFocused = true;
  };

  onBlur = () => {
    this.isFocused = false;
    this.props.onChange(this.state.plainText);
    this.props.onBlur();
  };

  onKeyCommand = (command, editorState) => {
    const allowedCommands = new Set([
      'split-block',
      'backspace',
      'backspace-to-start-of-line',
      'backspace-word',
      'redo',
    ]);

    if (allowedCommands.has(command)) {
      const updatedState = RichUtils.handleKeyCommand(editorState, command);

      if (updatedState) {
        this.setState({ editorState: updatedState });
        return 'handled';
      }
    }

    return 'not-handled';
  };

  onUlToggleButtonClick = () => {
    const updatedState = RichUtils.toggleBlockType(
      this.state.editorState,
      'unordered-list-item'
    );

    if (updatedState) this.onChange(updatedState);
  };

  handleReset = () => {
    if (this.props.onReset) {
      this.props.onReset();
    } else {
      const editorState = this.newEditorState(this.props.initialValue);
      this.setState({ editorState });
    }
  };

  handleBeforeInput = () => {
    const {
      props: { maxLength },
    } = this;

    return maxLength && size(this.state.plainText) > maxLength - 1
      ? 'handled'
      : null;
  };

  handlePastedText = pastedText => {
    const {
      props: { maxLength },
    } = this;

    return maxLength &&
      size(this.state.plainText) + size(pastedText) > maxLength
      ? 'handled'
      : null;
  };

  moveSelectionToEnd = editorState => {
    // https://github.com/brijeshb42/medium-draft/issues/71#issuecomment-399411065
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });

    return EditorState.forceSelection(editorState, selection);
  };

  newEditorState = plainText => {
    const htmlText = (plainText || '')
      .split('\n')
      .map(plainTextLine => {
        let data = `<p>${plainTextLine}</p>`;

        if (plainTextLine.indexOf('- ') === 0) {
          data = `<ul><li>${plainTextLine.slice(2)}</li></ul>`;
        } else if (/^(\d+\.\s)/.test(plainTextLine)) {
          data = `<ol><li>${plainTextLine.slice(
            plainTextLine.indexOf('. ') + 2
          )}</li></ol>`;
        }

        return data;
      })
      .join('');

    const contentState = convertFromHTML(htmlText);
    const newEditorState = EditorState.createWithContent(contentState);

    return newEditorState;
  };

  buildPlainText = editorState => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    let listItemNumber = 1; // draft.js doesn't expose this

    return rawContent.blocks
      .map(block => {
        if (block.type === 'ordered-list-item') {
          return `${listItemNumber++}. ${block.text}`;
        }
        listItemNumber = 1;
        if (block.type === 'unordered-list-item') {
          return `- ${block.text}`;
        }

        return block.text;
      })
      .join('\n');
  };

  render() {
    const {
      readOnly,
      showButtons,
      initialValue,
      invalid,
      onChange,
      isFocused,
      onBlur,
      showResetButton,
      showCharsRemainingLabel,
      maxLength,
      errorMessageNode,
      placeholder,
      ...props
    } = this.props;

    let hidePlaceHolder = false;
    if (this.state.editorState) {
      const contentState = this.state.editorState.getCurrentContent();

      if (
        !contentState?.hasText() &&
        contentState?.getBlockMap().first().getType() !== 'unstyled'
      ) {
        hidePlaceHolder = true;
      }
    }

    return (
      <DivRichTextEditorWrapper {...props}>
        <DivEditorWrapper
          invalid={invalid}
          readOnly={readOnly}
          data-test={`RICHTEXTEDITOR${readOnly ? 'DISABLED' : ''}`}
          hidePlaceHolder={hidePlaceHolder}
        >
          {!!this.state.editorState && (
            <Editor
              ref={this.editorRef}
              editorState={this.state.editorState}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              handleKeyCommand={this.onKeyCommand}
              readOnly={readOnly}
              stripPastedStyles
              handleBeforeInput={this.handleBeforeInput}
              handlePastedText={this.handlePastedText}
              placeholder={placeholder}
            />
          )}
        </DivEditorWrapper>
        {!!showResetButton && (
          <ResetButton onClick={this.handleReset} type="button">
            <ResetIcon />
            Reset
          </ResetButton>
        )}
        {!readOnly && !!showButtons && (
          <DivButtonsWrapper>
            <ButtonUlToggleWrapper
              type="button"
              onClick={this.onUlToggleButtonClick}
              data-test="TOGGLEBULLETPOINTS"
            >
              <BulletsIcon />
            </ButtonUlToggleWrapper>
          </DivButtonsWrapper>
        )}
        {!readOnly && (
          <InputCharRemaining
            showCharsRemainingLabel={showCharsRemainingLabel}
            maxLength={maxLength}
            value={this.state.plainText}
            errorMessageNode={errorMessageNode}
          />
        )}
      </DivRichTextEditorWrapper>
    );
  }
}

RichTextEditor.defaultProps = {
  readOnly: false,
  showButtons: true,
  initialValue: '',
  invalid: false,
  onChange: () => {},
  onBlur: () => {},
  isFocused: false,
  showResetButton: false,
  showCharsRemainingLabel: true,
  maxLength: null,
  errorMessageNode: null,
  dirty: false,
  onReset: null,
  placeholder: null,
};

RichTextEditor.propTypes = {
  dirty: bool,
  errorMessageNode: node,
  initialValue: string,
  invalid: bool,
  isFocused: bool,
  maxLength: number,
  placeholder: string,
  readOnly: bool,
  showButtons: bool,
  showCharsRemainingLabel: bool,
  showResetButton: bool,
  onBlur: func,
  onChange: func,
  onReset: func,
};

export default RichTextEditor;
