import {
  getFormSyncErrors,
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  stopSubmit,
} from 'redux-form';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { addApiError } from '../actions/app';
import { TALENT_FORM } from '../../api/constants';
import { ClientError, BadRequestError } from '../../classes/legacy-api-errors';
import {
  ADD_EDUCATION,
  ADMIN_ADD_GROUP,
  ADMIN_ADD_SUBGROUP,
  ADMIN_EDIT_GROUP,
  EDIT_EDUCATION,
  OPPORTUNITY_CLOSED_WON,
  OPPORTUNITY_EDIT,
  SAVED_SEARCH_ENDPOINT,
  SUBMIT_FORM,
  TALENT_EDIT,
  WORKSPACE,
} from '../constants';

import {
  updateOpportunityGenerator,
  updateWorkspaceSaga,
} from './opportunities';
import {
  createEducationFormGenerator,
  updateEducationFormGenerator,
} from './education';
import { talentSagaGenerator, updateTalentGenerator } from './talent';
import { searchesSave } from './searches';
import { groupFormSaga } from './groups';

const formMap = new Map([
  [ADD_EDUCATION, createEducationFormGenerator],
  [ADMIN_ADD_GROUP, groupFormSaga],
  [ADMIN_ADD_SUBGROUP, groupFormSaga],
  [ADMIN_EDIT_GROUP, groupFormSaga],
  [EDIT_EDUCATION, updateEducationFormGenerator],
  [OPPORTUNITY_CLOSED_WON, updateOpportunityGenerator],
  [OPPORTUNITY_EDIT, updateOpportunityGenerator],
  [SAVED_SEARCH_ENDPOINT, searchesSave],
  [TALENT_EDIT, updateTalentGenerator],
  [TALENT_FORM, talentSagaGenerator],
  [WORKSPACE, updateWorkspaceSaga],
]);

export function* formSagaGenerator(action) {
  const {
    meta: { form },
    payload: { endpoint },
  } = action;

  try {
    // Sets redux form submitting prop to true
    yield put(startSubmit(form));

    // Check if there are sync errors
    const syncErrors = yield select(getFormSyncErrors(form));
    if (Object.values(syncErrors).some(Boolean)) {
      throw new ClientError(syncErrors);
    }

    // Determine if we should use a specified endpoint, or just
    // use the form name as a key in our key-to-endpoint map
    const task = formMap.get(endpoint || form);

    // Dev-friendly fail messaging
    if (!task) {
      const error = new Error(
        `Endpoint or form has no key in the map: endpoint: ${endpoint}, form: ${form}`
      );
      error.applicationError = {};
      console.error(error); // eslint-disable-line no-console
      throw error;
    }
    // Call API
    yield call(task, form);
    // Set redux form meta submitting to false, succeeded to true
    yield put(setSubmitSucceeded(form));
    // Deal with react/redux render bug - if task is synchronous, it appears
    // react is optimizing re-rendering too much to the point that it skips
    // a render cycle even though a boolean prop has changed. Symptom is derived
    // prop "hasSubmitted" flips from false to true to false quickly enough
    // that react skips the middle "true" render cycle.
    yield delay(0);
    // Set redux form meta submitting to undefined
    yield put(stopSubmit(form));
  } catch (e) {
    console.error('Error in form:', e); // eslint-disable-line no-console
    // Set form fields error prop
    let fieldErrors = {};
    if (e instanceof BadRequestError || e instanceof ClientError) {
      fieldErrors = e.getReduxFormErrors();
    } else {
      fieldErrors = { __error1: 'There was an error with your request' };
      yield put(addApiError(e.applicationError));
    }
    yield put(stopSubmit(form, fieldErrors));
    yield put(setSubmitFailed(form, ...Object.keys(fieldErrors)));
  }
}

function* formsSaga() {
  yield takeEvery(SUBMIT_FORM, formSagaGenerator);
}

export default formsSaga;
