import { CondensedMedium, SectionHeader, ToastUtil } from '@axiom/ui';
import React, { useEffect } from 'react';
// This is a one-off export for the sake of Envoy
// eslint-disable-next-line no-restricted-syntax
import { useWindowResize } from '@axiom/ui/src/hooks/useWindowResize';

import { EnvUtil } from '../utils/env-util';

export const lowestAHQSupportedWidth = 1820;
export const lowestAHQSupportedHeight = 800;
export const windowDimensionsToastName = 'WINDOW_DIMENSIONS_TOAST';

export const useScreenResolutionToast = () => {
  const windowDimensions = useWindowResize();
  const showToast = () => {
    return (
      windowDimensions.width < lowestAHQSupportedWidth ||
      windowDimensions.height < lowestAHQSupportedHeight
    );
  };

  const addToast = () => {
    ToastUtil.add({
      name: windowDimensionsToastName,
      type: 'error',
      dismissible: true,
      children: (
        <div>
          <SectionHeader>Unsupported display settings</SectionHeader>
          <CondensedMedium>
            Your current resolution is not within our supported range. This may
            result in unusable features within AHQ. Follow our guide to{' '}
            <a
              data-test="SEISMIC_LINK"
              target="_blank"
              rel="noreferrer"
              href={EnvUtil.axiomSeismicScreenResolutionInfoUrl}
            >
              fix it!
            </a>{' '}
            If you are unable to fix this yourself,{' '}
            <a
              data-test="SERVICEDESK_LINK"
              target="_blank"
              rel="noreferrer"
              href={EnvUtil.contactServicesdeskUrl}
            >
              contact ServiceDesk.
            </a>
          </CondensedMedium>
        </div>
      ),
    });
  };

  useEffect(() => {
    const toasts = ToastUtil.getAll();
    if (showToast()) {
      const toastArr = toasts.filter(toast => {
        return toast.name === windowDimensionsToastName;
      });

      if (toastArr.length === 0) {
        addToast();
      }
    } else {
      toasts.forEach(toast => {
        if (toast.name === windowDimensionsToastName) {
          ToastUtil.remove({ addedOn: toast.addedOn });
        }
      });
    }
  }, [windowDimensions.width, windowDimensions.height]);
};
