import { Api } from '@axiom/ui';

import { EnvUtil } from '../utils/env-util';
import browserCookie from '../utils/browser-cookies-util';

class AuthApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  async refreshToken() {
    const result = await super.write<{ data: string }>({
      endpoint: '/auth',
      method: 'POST',
      body: JSON.stringify({ token: browserCookie('user') }),
    });

    browserCookie('user', result.data, {
      path: '/',
      domain: this.getCookieDomain(),
    });
  }
}

export const AuthApi = new AuthApiClass();
