import React from 'react';
import { FormIcon, Suspense } from '@axiom/ui';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { formatDataTestId } from '../../utils/data-test-util';
import { WindowUtil } from '../../utils/window-util';

import { StyledSideDrawer, DrawerTab } from './SideDrawerStyles';
import { SideDrawerContents } from './SideDrawerContents';

export const SideDrawer = ({ expanded, setExpanded }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const toggleSideDrawerExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={{ position: 'relative', minHeight: '100%' }}>
      <StyledSideDrawer
        data-test={formatDataTestId('side drawer container')}
        expanded={expanded}
      >
        <Suspense stretched scrollable>
          <Routes>
            <Route
              path=":id/*"
              element={
                <SideDrawerContents
                  expanded={expanded}
                  openDrawer={() => setExpanded(true)}
                  closeDrawer={() => {
                    setExpanded(false);
                    navigate(WindowUtil.appendQueryParams(pathname));
                  }}
                />
              }
            />
            <Route
              path={pathname}
              element={
                <p data-teat={formatDataTestId('no side drawer content')}>
                  Please select a record from the list.
                </p>
              }
            />
          </Routes>
        </Suspense>
      </StyledSideDrawer>
      <DrawerTab
        expanded={expanded}
        onClick={toggleSideDrawerExpanded}
        data-test={formatDataTestId('DrawerTab')}
      >
        <FormIcon />
      </DrawerTab>
    </div>
  );
};

SideDrawer.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};
