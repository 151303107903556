import React from 'react';
import { string, func, shape, number } from 'prop-types';
import { DataTest, Gutter, IconButton, WarningIcon } from '@axiom/ui';

import { formatDataTestId } from '../../utils/data-test-util';

import { AppErrorsCard, AppErrorsMessage } from './AppErrorsStyles';

export const AppErrorsItem = ({ error, onDismiss }) => (
  <DataTest value="app-error">
    <AppErrorsCard>
      <DataTest value="icon">
        <WarningIcon />
      </DataTest>
      <AppErrorsMessage data-test={formatDataTestId('app-error-text')}>
        <DataTest value="Message">{error.errorMessage}</DataTest>
        {!!error.errorCode && (
          <DataTest value="errorcode">
            <b>Error Code: {error.errorCode}</b>
          </DataTest>
        )}
      </AppErrorsMessage>
      <Gutter right="8px">
        <IconButton
          icon="x-mark"
          pattern="secondary"
          variation="minimal"
          className="dismiss-error"
          onClick={() => onDismiss()}
          name="APP_ERROR_DISMISS"
        />
      </Gutter>
    </AppErrorsCard>
  </DataTest>
);

AppErrorsItem.propTypes = {
  error: shape({
    errorMessage: string.isRequired,
    errorCode: number,
  }).isRequired,
  onDismiss: func.isRequired,
};
