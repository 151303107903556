import React from 'react';
import { func, string, shape, bool, arrayOf, number } from 'prop-types';
import { Layout, LayoutItem } from '@axiom/ui';

import DropdownList from '../DropdownList/DropdownList';
import { DatePropType, DateUtil } from '../../utils/date-util';
import { formatDataTestId } from '../../utils/data-test-util';

const monthOptions = DateUtil.getMonthOptions();

class DateMonthYearPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getStateStructure(props.date);
  }

  componentDidUpdate(prevProps) {
    const { date } = this.props;
    /**
     * If the date prop changes outside the component we need to reset state
     * so that the dropdowns will re-render properly
     */
    if (date && !prevProps.date) {
      this.setState(this.getStateStructure(date));
    } else if (!date && prevProps.date) {
      this.setState(this.getStateStructure(null));
    } else if (
      DateUtil.formatAsDate(date) !== DateUtil.formatAsDate(prevProps.date)
    ) {
      this.setState(this.getStateStructure(date));
    }
  }

  getStateStructure(date) {
    date = date ? DateUtil.castAsMoment(date).clone().date(1) : null;

    return {
      date,
      month: date ? date.month().toString() : null, // Because the month option values are strings
      year: date ? date.year() : null,
    };
  }

  processChanges = ({ month, year, date }) => {
    if (month === null || year === null) {
      return {
        month,
        year,
        date,
      };
    }

    date = date
      ? date.date(1).month(month).year(year)
      : DateUtil.castAsMoment().date(1).month(month).year(year);

    return {
      month,
      year,
      date,
    };
  };

  fireDateChange = date => {
    if (date) {
      this.props.onDateChange(date.format('YYYY-MM-DD'));
    }
  };

  render() {
    const {
      onMonthChange,
      onYearChange,
      onFocus,
      onBlur,
      id,
      label,
      name,
      yearOptions,
      invalid,
      disabled,
    } = this.props;

    const displayId = id || name || `${label}DateMonthYearPicker`;
    const { month, year, date } = this.state;
    return (
      <Layout
        name={formatDataTestId(displayId)}
        position="middle"
        horizontalGutter="8px"
      >
        <LayoutItem fluid>
          <DropdownList
            name="MonthSelect"
            label={label ? `${label} Month` : null}
            value={month}
            options={monthOptions}
            valid={!invalid}
            disabled={disabled}
            onFocus={onFocus}
            onChange={newMonth => {
              const updatedProps = this.processChanges({
                month: newMonth,
                year,
                date,
              });
              this.setState(updatedProps, () => {
                onMonthChange(updatedProps.month);
                this.fireDateChange(updatedProps.date);
                onBlur();
              });
            }}
          />
        </LayoutItem>
        <LayoutItem fluid>
          <DropdownList
            name="YearSelect"
            label={label ? `${label} Year` : null}
            value={year}
            valid={!invalid}
            options={yearOptions}
            disabled={disabled}
            onFocus={onFocus}
            onChange={newYear => {
              const updatedProps = this.processChanges({
                month,
                year: newYear,
                date,
              });
              this.setState(updatedProps, () => {
                onYearChange(updatedProps.year);
                this.fireDateChange(updatedProps.date);
                onBlur();
              });
            }}
          />
        </LayoutItem>
      </Layout>
    );
  }
}

DateMonthYearPicker.propTypes = {
  date: DatePropType,
  disabled: bool,
  id: string,
  invalid: bool,
  label: string,
  name: string,
  yearOptions: arrayOf(
    shape({
      label: number.isRequired,
      value: number.isRequired,
    }).isRequired
  ).isRequired,
  onBlur: func,
  onDateChange: func,
  onFocus: func,
  onMonthChange: func,
  onYearChange: func,
};

DateMonthYearPicker.defaultProps = {
  date: null,
  id: null,
  label: null,
  name: null,
  invalid: false,
  disabled: false,
  onMonthChange: () => {},
  onYearChange: () => {},
  onDateChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

export default DateMonthYearPicker;
