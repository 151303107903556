import styled from 'styled-components';
import { Label } from '@axiom/ui';

import { getThemeProp } from '../../themes';
import { getEmploymentStylesByStatus } from '../../utils/color-util';
import { Col } from '../../themes/components';

export const OTCardWrapper = styled.div`
  background: ${getThemeProp('appBackground')};
`;

export const OTCardHeader = styled.div`
  background-color: ${({ status }) =>
    getEmploymentStylesByStatus(status).background};
  color: ${({ status }) => getEmploymentStylesByStatus(status).color};
  display: flex;
  align-items: flex-start;
  border: 2px solid
    ${({ status }) => getEmploymentStylesByStatus(status).border};
`;

export const OTCardHeaderLeft = styled.div`
  flex-basis: 48%;
  display: flex;
`;
export const OTCardHeaderLeftName = styled.div`
  margin: 0.5rem 2rem 0.5rem 0.75rem;

  li {
    line-height: 1.3;
  }
`;
export const OTCardHeaderRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const OTCardHeaderRightStatus = styled.div`
  flex: 1;
  margin: 0.5rem 1rem 0.5rem 0;
  align-items: center;
`;
export const OTCardHeaderRightContextMenu = styled.div`
  align-self: center;
  display: flex;
`;
export const OTCardBody = styled.div`
  border: 1px solid #000;
  border-top: none;
  padding: 0.75rem;
  display: flex;
  flex-wrap: wrap;
`;
export const OTCardBodyLeft = styled.div`
  flex-basis: 48%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const OTCardBodyFull = styled.div`
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const OTCardBodyItemCol = styled(Col)`
  & > * {
    display: inline-block;
  }
`;
export const OTCardBodyRight = styled.div`
  flex: 1;
  display: flex;
`;
export const OTCardRateWrapper = styled.div`
  & > *:first-child {
    margin-right: 0.5rem;
  }
`;

export const OTCardBodyAlignRight = styled.div`
  display: block;
  width: 100%;
  text-align: right;
`;

export const OTCardBodyLabel = styled(Label)`
  color: ${getThemeProp('primaryText')};
`;
