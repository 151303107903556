import React from 'react';
import { z } from 'zod';
import {
  ApiError,
  Button,
  CondensedLabel,
  Form,
  Gutter,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Textarea,
} from '@axiom/ui';
import { CandidateOpportunitiesConst } from '@axiom/const';
import { Candidate, Opportunity } from '@axiom/validation';

import { CandidateOpportunityLegacyApi } from '../../api/candidate-opportunities-legacy-api';
import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';
import { PreloadedAppErrorsStore } from '../../stores/preloaded-app-errors-store';

const { CandidateStatuses } = CandidateOpportunitiesConst;

export const OpportunityTalentWarmingModal = ({
  onClose,
  opportunityId,
  candidateId,
}: {
  onClose: () => void;
  opportunityId: Opportunity['id'];
  candidateId: Candidate['id'];
}) => {
  const initialValues = {
    candidateStatusMessage:
      "We think you'd be a great fit for an opportunity we're working on. Please review the information below and indicate if you'd like us to share your profile with the client.",
  };

  return (
    <Form
      name="WARMING_MODAL_FORM"
      schema={z.object({ candidateStatusMessage: z.string() })}
      initialValues={initialValues}
      onSubmit={async changedData => {
        try {
          await OpportunityLegacyApi.updateOpportunityCandidate(
            opportunityId,
            candidateId,
            {
              candidateStatus: CandidateStatuses.Warmed,
              candidateStatusMessage:
                changedData.candidateStatusMessage ??
                initialValues.candidateStatusMessage,
            }
          );
        } catch (e) {
          PreloadedAppErrorsStore.show(
            ((e as ApiError).getErrors() as { message: string })?.message
          );
        }
        await Promise.all([
          OpportunityLegacyApi.refreshOpportunityCandidates(opportunityId),
          OpportunityLegacyApi.refreshOpportunityPositions(opportunityId),
          CandidateOpportunityLegacyApi.refreshCandidateOpportunities(
            candidateId
          ),
        ]);
        onClose();
      }}
    >
      {({ fireSubmit }) => (
        <Modal name="WARMING_MODAL">
          <ModalHeader
            name="WARMING_MODAL_HEADER"
            background="blue"
            onClose={onClose}
          >
            Warming Talent
          </ModalHeader>

          <ModalSection>
            <div data-test="AUTO_WARMING_FORM">
              <Gutter bottom="16px">
                <CondensedLabel>
                  We'll send the following message to this individual to let
                  them know they've been warmed. To customize the message, edit
                  the text below before you click Send. Message text cannot be
                  blank.
                </CondensedLabel>
              </Gutter>
              <Textarea name="candidateStatusMessage" />
            </div>
          </ModalSection>
          <ModalFooter>
            <Button
              className="warming-talent-action"
              onClick={onClose}
              name="WARMING_MODAL_CANCEL_BUTTON"
              variation="outline"
            >
              Cancel
            </Button>
            <Button
              name="WARMING_MODAL_SAVE_BUTTON"
              className="warming-talent-action"
              onClick={fireSubmit}
            >
              Send
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Form>
  );
};
