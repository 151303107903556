import keyBy from 'lodash/keyBy';
import { actionTypes } from 'redux-form';

import {
  DECORATED_TALENT_LOADED,
  DELETE_EDUCATION_SUCCESS,
  SINGLE_TALENT_LOADED,
  TALENT_ERROR,
  TALENT_LOADED,
  TALENT_SAVE_SUCCESS,
  USER_TALENT_ACTIVITY_CREATED,
  USER_TALENT_ACTIVITY_UPDATED,
  USER_TALENT_LOADED,
} from '../constants';
import { TALENT_FORM } from '../../api/constants';

export const INITIAL_STATE = {
  loading: false,
  lastLocalUpdate: 0,
  error: undefined,
  currentPage: 1,
  totalPages: undefined,
  totalResults: undefined,
  data: [],
  byId: {},
  certificationsById: {},
  degreesById: {},
  experiencesById: {},
};

const reducer = (state = INITIAL_STATE, { type, payload, meta }) => {
  switch (type) {
    case actionTypes.START_SUBMIT: {
      return meta.form === TALENT_FORM ? { ...state, loading: true } : state;
    }

    case actionTypes.STOP_SUBMIT: {
      return meta.form === TALENT_FORM ? { ...state, loading: false } : state;
    }

    case TALENT_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case TALENT_LOADED: {
      const currentPage =
        payload && payload.meta ? payload.meta.currentPage : 1;
      const newIds = payload.data.map(talent => talent.id);

      const withCerts = payload.data.filter(talent => talent.certifications);
      const certificationsById =
        withCerts.length === 0
          ? state.certificationsById
          : {
              ...state.certificationsById,
              ...withCerts.reduce((obj, talent) => {
                obj[talent.id] = talent.certifications;
                return obj;
              }, {}),
            };

      const withDegrees = payload.data.filter(talent => talent.degrees);
      const degreesById =
        withDegrees.length === 0
          ? state.degreesById
          : {
              ...state.degreesById,
              ...withDegrees.reduce((obj, talent) => {
                obj[talent.id] = talent.degrees;
                return obj;
              }, {}),
            };

      const withExperiences = payload.data.filter(talent => talent.experiences);
      const experiencesById =
        withExperiences.length === 0
          ? state.experiencesById
          : {
              ...state.experiencesById,
              ...withExperiences.reduce((obj, talent) => {
                obj[talent.id] = talent.experiences.map(experience => {
                  return experience;
                });
                return obj;
              }, {}),
            };

      payload.data.forEach(talent => {
        delete talent.certifications;
        delete talent.degrees;
        delete talent.experiences;
      });

      return {
        ...state,
        certificationsById,
        currentPage,
        degreesById,
        experiencesById,
        lastLocalUpdate: Date.now(),
        loading: false,
        totalPages: payload.meta ? payload.meta.pageCount : undefined,
        totalResults: payload.meta ? payload.meta.resultCount : undefined,
        data:
          currentPage === 1 || !currentPage
            ? newIds
            : [...state.data, ...newIds],
        byId: {
          ...state.byId,
          ...keyBy(payload.data, 'id'),
        },
      };
    }

    case USER_TALENT_LOADED: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(payload.data, 'id'),
        },
        certificationsById: {
          ...state.certificationsById,
          [payload.data.id]:
            payload.data.certifications ||
            state.certificationsById[payload.data.id],
        },
        degreesById: {
          ...state.degreesById,
          [payload.data.id]:
            payload.data.degrees || state.degreesById[payload.data.id],
        },
      };
    }

    case TALENT_SAVE_SUCCESS: {
      return {
        ...state,
        loading: false,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        certificationsById: {
          ...state.certificationsById,
          [payload.id]:
            payload.certifications || state.certificationsById[payload.id],
        },
        degreesById: {
          ...state.degreesById,
          [payload.id]: payload.degrees || state.degreesById[payload.id],
        },
      };
    }

    case DECORATED_TALENT_LOADED: {
      return {
        ...state,
        byId: { ...state.byId, [payload.talentID]: payload.talent.data },
        certificationsById: {
          ...state.certificationsById,
          [payload.talentID]:
            payload.talent.data.certifications ||
            state.certificationsById[payload.talentID],
        },
        degreesById: {
          ...state.degreesById,
          [payload.talentID]:
            payload.talent.data.degrees || state.degreesById[payload.talentID],
        },
        experiencesById: {
          ...state.experiencesById,
          [payload.talentID]:
            payload.talent.data.experiences ||
            state.experiencesById[payload.talentID],
        },
      };
    }

    case SINGLE_TALENT_LOADED: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.data.id]: payload.data,
        },
        certificationsById: {
          ...state.certificationsById,
          [payload.data.id]:
            payload.data.certifications ||
            state.certificationsById[payload.data.id],
        },
        degreesById: {
          ...state.degreesById,
          [payload.data.id]:
            payload.data.degrees || state.degreesById[payload.data.id],
        },
        experiencesById: {
          ...state.experiencesById,
          [payload.data.id]:
            payload.data.experiences || state.experiencesById[payload.data.id],
        },
      };
    }

    case DELETE_EDUCATION_SUCCESS: {
      const { educationId } = payload;

      const candidateId = Object.keys(state.degreesById).find(canId =>
        state.degreesById[canId].find(degree => degree.id === educationId)
      );

      if (!candidateId) return state;

      return {
        ...state,
        degreesById: {
          ...state.degreesById,
          [candidateId]: state.degreesById[candidateId].filter(
            degree => degree.id !== educationId
          ),
        },
      };
    }

    case USER_TALENT_ACTIVITY_CREATED:
    case USER_TALENT_ACTIVITY_UPDATED: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.candidateId]: {
            ...state.byId[payload.candidateId],
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
