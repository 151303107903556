import GenericStore from '../classes/generic-store';
import { patchTalent } from '../api/talent-api';
import { talentShape } from '../models/talent';
import { getDecoratedTalent } from '../redux/actions/talent';

class CandidateStoreClass extends GenericStore {
  load(candidate) {
    return this.setState(candidate.data);
  }

  updateCandidate({ id, ...candidateUpdate }) {
    return this.watchState(patchTalent(id, candidateUpdate)).then(() => {
      this.dispatch(getDecoratedTalent(id));
    });
  }

  getDataShape() {
    return talentShape;
  }
}

export const CandidateStore = new CandidateStoreClass();
