import React from 'react';
import { useApi } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { TaxonomyApi } from '../../api/taxonomy-api';
import { CandidateExperiencesApi } from '../../api/candidate-experiences-api';
import { CandidateIndustriesApi } from '../../api/candidate-industries-api';
import { CandidateExperienceForm } from '../CandidateExperienceForm/CandidateExperienceForm';

import { CandidateExperienceIndustrySummary } from './CandidateExperienceIndustrySummary';
import CandidateExperienceList from './CandidateExperienceList';
import { CandidateExperienceUtil } from './candidate-experience-util';

export const CandidateExperience = ({
  candidate,
}: {
  candidate: Candidate;
}) => {
  const [
    { data: taxonomy },
    { data: candidateExperiences },
    { data: candidateIndustries },
  ] = useApi(
    TaxonomyApi.readTaxonomy(),
    CandidateExperiencesApi.readExperiences(candidate.id),
    CandidateIndustriesApi.readIndustries(candidate.id)
  );

  const experiences =
    CandidateExperienceUtil.sortExperiences(candidateExperiences);

  return (
    <div data-test="TALENT_EXPERIENCE_TAB_CONTAINER">
      {candidateIndustries?.length > 0 && (
        <CandidateExperienceIndustrySummary
          candidateIndustries={candidateIndustries}
        />
      )}
      <CandidateExperienceList
        candidateId={candidate.id}
        experiences={experiences.axiom}
        axiomExperiences
        title="Axiom Experience"
        taxonomy={taxonomy}
      />
      <CandidateExperienceList
        candidateId={candidate.id}
        experiences={experiences.other}
        title="Other Experience"
        taxonomy={taxonomy}
        canAddNew
      />
      <CandidateExperienceForm />
    </div>
  );
};
