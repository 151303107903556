import React, { useEffect, useState } from 'react';

import LawSchoolsManagementList from '../LawSchoolsManagementList/LawSchoolsManagementList';
import LawSchoolManagementForm from '../LawSchoolManagementForm/LawSchoolManagementForm';
import { formatDataTestId } from '../../utils/data-test-util';
import { LawSchoolsStore } from '../../stores/law-schools-store';
import { FormLawSchoolManagementStore } from '../../stores/form-law-school-management-store';

import {
  LawSchoolsManagementWrapper,
  LawSchoolsManagementLeft,
  LawSchoolsManagementRight,
  LawSchoolsManagementViewText,
  LawSchoolsManagementViewEmpty,
} from './LawSchoolsManagementViewStyles';

const ManageAccountsView = () => {
  useEffect(() => {
    LawSchoolsStore.load();
  }, []);

  const [selected, setSelected] = useState();

  const onSelect = lawSchool => {
    FormLawSchoolManagementStore.load(lawSchool);
    setSelected(lawSchool);
  };

  return (
    <LawSchoolsManagementWrapper
      data-test={formatDataTestId('MANAGE_LAW_SCHOOLS')}
    >
      <LawSchoolsManagementLeft>
        <LawSchoolsManagementList selected={selected} onSelect={onSelect} />
      </LawSchoolsManagementLeft>
      <LawSchoolsManagementRight>
        {selected ? (
          <LawSchoolManagementForm />
        ) : (
          <LawSchoolsManagementViewEmpty>
            <LawSchoolsManagementViewText>
              Select a school.
            </LawSchoolsManagementViewText>
          </LawSchoolsManagementViewEmpty>
        )}
      </LawSchoolsManagementRight>
    </LawSchoolsManagementWrapper>
  );
};

export default ManageAccountsView;
