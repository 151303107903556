import { call, put, takeLatest } from 'redux-saga/effects';

import { addApiError } from '../actions/app';
import { DECORATED_TALENT_LOADED, TALENT_SAVE_SUCCESS } from '../constants';
import { getHomeOffice } from '../../api/home-offices-api';
import { DEFAULT_MAX_HOURS } from '../../api/constants';
import {
  homeOfficeLoaded,
  homeOfficeLoadedError,
} from '../actions/home-offices';

export function* talentLoadedHomeOfficeGenerator({
  payload: { talentID, talent },
}) {
  try {
    if (talent && talent.data && talent.data.homeOfficeId) {
      const { data } = yield call(getHomeOffice, talent.data.homeOfficeId);
      yield put(
        homeOfficeLoaded({
          talentID,
          data,
        })
      );
    } else {
      yield put(
        homeOfficeLoaded({
          talentID,
          data: DEFAULT_MAX_HOURS,
        })
      );
    }
  } catch (e) {
    yield put(homeOfficeLoadedError(talent.data.homeOfficeId));
    yield put(addApiError(e.applicationError));
  }
}

export function* updateTalentHomeOfficeGenerator({
  payload: { id: talentID, homeOfficeId },
}) {
  try {
    if (homeOfficeId) {
      const { data } = yield call(getHomeOffice, homeOfficeId);
      yield put(
        homeOfficeLoaded({
          talentID,
          data,
        })
      );
    } else {
      yield put(
        homeOfficeLoaded({
          talentID,
          data: DEFAULT_MAX_HOURS,
        })
      );
    }
  } catch (e) {
    yield put(homeOfficeLoadedError(homeOfficeId));
    yield put(addApiError(e.applicationError));
  }
}

function* homeOfficesSaga() {
  yield takeLatest(DECORATED_TALENT_LOADED, talentLoadedHomeOfficeGenerator);

  // TODO don't reload unless home office or desired hours change
  yield takeLatest(TALENT_SAVE_SUCCESS, updateTalentHomeOfficeGenerator);
}

export default homeOfficesSaga;
