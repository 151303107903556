import React, { FC } from 'react';
import { CondensedMedium, Flashy, Icon, Layout } from '@axiom/ui';

import { MatchChecklistItem } from './OpportunityTalentMatchChecklistUtil';

export const OpportunityTalentMatchChecklistItem: FC<MatchChecklistItem> = ({
  id,
  isMatched,
  children,
}) => {
  return (
    <Layout horizontalGutter="8px" position="left middle" name={id}>
      <CondensedMedium name="checklist-item">
        <Flashy
          color={isMatched ? 'dataVizGreen' : 'dataVizYellow'}
          name={`checklist-item-${isMatched ? 'matched' : 'unmatched'}`}
        >
          <Layout position="middle">
            <Icon name={isMatched ? 'circle-check-filled' : 'alert-filled'} />
          </Layout>
        </Flashy>
      </CondensedMedium>
      <CondensedMedium>{children}</CondensedMedium>
    </Layout>
  );
};
