import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Gutter, TabsItem, Tabs } from '@axiom/ui';

import { UserOpportunityFiltersStore } from '../../stores/user-opportunity-filters-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { SavedSearches } from '../SavedSearches/SavedSearches';
import { OpportunitiesStore } from '../../stores/opportunities-store';
import { EditSavedOpportunityFilterStore } from '../../stores/edit-saved-opportunity-filter-store';

import OpportunityFiltersForm from './OpportunityFiltersForm';

const OpportunityFiltersComponent = ({
  user,
  userOppFiltersState,
  opportunitiesState,
}) => {
  useEffect(() => {
    UserOpportunityFiltersStore.load(user.id);
  }, [user.id]);

  const handlerRenderFilter = userFilter => (
    <OpportunityFiltersForm filters={userFilter?.filters || {}} />
  );
  return (
    <StoreStateTreatment
      allowOverflow
      name="OppFiltersTreatment"
      storeState={[userOppFiltersState, opportunitiesState]}
      showRefetchLoader={false}
      renderLoadedView={({ data: [userOppFilters, opportunitiesData] }) => (
        <Gutter top="16px">
          <Tabs fluid>
            <TabsItem label="New" name="New">
              <OpportunityFiltersForm
                isEditable
                filters={OpportunitiesStore.getFilters()}
                handleSaveFilters={filters => {
                  if (filters.sort) {
                    delete filters.sort;
                  }
                  EditSavedOpportunityFilterStore.save(user.id, {
                    ...opportunitiesData.meta.searchConfig,
                    ...filters,
                  });
                }}
                handleFilterChange={filters => {
                  OpportunitiesStore.changeFilters(filters);
                }}
                resetFilters={() => {
                  OpportunitiesStore.resetFilters();
                }}
              />
            </TabsItem>
            <TabsItem label="Saved" name="Saved">
              <SavedSearches
                userFilters={userOppFilters}
                handleFilterLoad={filters => {
                  OpportunitiesStore.loadByConfig({
                    meta: {
                      searchConfig: {
                        ...filters,
                      },
                    },
                  });
                }}
                handleRenderFilter={handlerRenderFilter}
              />
            </TabsItem>
          </Tabs>
        </Gutter>
      )}
    />
  );
};

OpportunityFiltersComponent.propTypes = {
  opportunitiesState: OpportunitiesStore.getStateShape().isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
  userOppFiltersState: UserOpportunityFiltersStore.getStateShape().isRequired,
};

export const OpportunityFilters = connect(state => ({
  user: PreloadedUserStore.select(state),
  userOppFiltersState: UserOpportunityFiltersStore.select(state),
  opportunitiesState: OpportunitiesStore.select(state),
}))(OpportunityFiltersComponent);
