import React from 'react';
import {
  Checkbox,
  Form,
  GridColumn,
  GridRow,
  ParagraphHeader,
} from '@axiom/ui';
import { z } from 'zod';
import { UserSchema, ContactSchema } from '@axiom/validation';

import { ContactsApi } from '../../api/contacts-api';
import { ContactStore } from '../../stores/contact-store';

const PricingFormSchema = ContactSchema.pick({
  canViewPricing: true,
})
  .merge(UserSchema.pick({ contactId: true }))
  .partial({
    canViewPricing: true,
  });

type PricingFormType = z.infer<typeof PricingFormSchema>;

export const ManageContactFormPricing = ({
  canViewPricing,
  contactId,
}: PricingFormType) => {
  const handleOnSubmit = async (changeData: PricingFormType) => {
    await ContactsApi.updateContacts(contactId, changeData);
    return ContactStore.load(contactId);
  };

  return (
    <GridRow gutterBottom="24px">
      <GridColumn width={5}>
        <ParagraphHeader name="SECTION_HEADER">Pricing</ParagraphHeader>
      </GridColumn>
      <GridColumn data-test="PRICING_PREF" width={7}>
        <Form
          schema={PricingFormSchema}
          name="PRICING_FORM"
          initialValues={{ canViewPricing }}
          onSubmit={handleOnSubmit}
          submitOnChange
        >
          {() => (
            <Checkbox
              name="canViewPricing"
              displayValue="Can see pricing on ALT"
            />
          )}
        </Form>
      </GridColumn>
    </GridRow>
  );
};
