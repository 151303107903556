import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Layout,
  LayoutItem,
  Suspense,
  useApi,
} from '@axiom/ui';

import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';
import { CandidateUtil } from '../../utils/candidate-util';
import { PositionUtil } from '../../utils/position-util';
import { OpportunitiesContextMenu } from '../OpportunitiesContextMenu/OpportunitiesContextMenu';
import { UserSettingsUtil } from '../../utils/user-settings-util';

import { OpportunityTalentCandidateWrapper } from './OpportunityTalentStyles';
import { OpportunityTalentParentPosition } from './OpportunityTalentParentPosition';
import { OpportunityTalentParentRemovedTalent } from './OpportunityTalentParentRemovedTalent';
import { OpportunityTalentEmptyProfile } from './OpportunityTalentEmptyProfile';
import { OpportunityTalentCandidate } from './OpportunityTalentCandidate';
import { OpportunityTalentCandidateProfile } from './OpportunityTalentCandidateProfile';

export const OpportunityTalentCandidateParent: FC<PropsWithChildren> = () => {
  const { id: opportunityId, viewId: candidateId } = useParams();

  const [{ data: opportunity }, { data: candidates }, { data: positions }] =
    useApi(
      OpportunityLegacyApi.readOpportunity(opportunityId),
      OpportunityLegacyApi.readOpportunityCandidates(opportunityId, true),
      OpportunityLegacyApi.readOpportunityPositions(opportunityId)
    );

  const talentPool = useMemo(
    () => candidates.filter(c => !('positionId' in c) || !c.positionId),
    [candidates]
  );

  const showCandidateProfile =
    candidateId && candidates.some(c => c.candidateId === candidateId);

  return (
    <OpportunityTalentCandidateWrapper>
      <Gutter top="16px" />
      <Grid columns={12}>
        <GridRow>
          <GridColumn width={4}>
            <Gutter bottom="16px">
              <Layout direction="horizontal">
                <LayoutItem>
                  <OpportunitiesContextMenu
                    opportunityPositions={positions}
                    opportunity={opportunity}
                    showAnchorButton
                  />
                </LayoutItem>
                <LayoutItem position="right" fluid>
                  <Button
                    name="PENDO_LEGACY_TALENT_VIEW_BUTTON"
                    onClick={async () =>
                      UserSettingsUtil.updateShowNewOpportunityTalentViewSetting(
                        false
                      )
                    }
                  >
                    LEGACY TALENT VIEW
                  </Button>
                </LayoutItem>
              </Layout>
            </Gutter>
            <Suspense>
              <Layout direction="vertical" verticalGutter="16px">
                {PositionUtil.sortPositions(positions).map(
                  (position, index) => (
                    <OpportunityTalentParentPosition
                      key={position.id}
                      name={position.id}
                      positions={positions}
                      title={`Position ${index + 1}${
                        position.name ? `: ${position.name}` : ''
                      }`}
                      opportunity={opportunity}
                      candidates={CandidateUtil.filterRemovedCandidates(
                        position.candidates
                      )}
                      isFilled={PositionUtil.isPositionFulfilled(
                        position.candidates
                      )}
                    />
                  )
                )}

                <OpportunityTalentParentPosition
                  name="talent-pool"
                  title="Talent Pool"
                  opportunity={opportunity}
                  candidates={CandidateUtil.filterRemovedCandidates(talentPool)}
                  isTalentPool
                />

                <OpportunityTalentParentRemovedTalent
                  opportunity={opportunity}
                  candidates={CandidateUtil.getRemovedCandidates(candidates)}
                />
              </Layout>
            </Suspense>
          </GridColumn>
          <GridColumn width={8}>
            {showCandidateProfile ? (
              <OpportunityTalentCandidate>
                <OpportunityTalentCandidateProfile candidateId={candidateId} />
              </OpportunityTalentCandidate>
            ) : (
              <OpportunityTalentEmptyProfile />
            )}
          </GridColumn>
        </GridRow>
      </Grid>
    </OpportunityTalentCandidateWrapper>
  );
};
