import React, { useState } from 'react';
import { Text, useApi } from '@axiom/ui';
import { AreaOfStudy } from '@axiom/validation';

import { AreasOfStudyApi } from '../../api/areas-of-study-api';

import { AdminModalAreasStudiesList } from './AdminModalAreasStudiesList';
import { AdminModalAreasStudies } from './AdminModalAreasStudies';
import { Root, List, Form, Empty } from './AdminModalGroupManagementUserStyles';

export const AdminModalAreaStudy = () => {
  const [{ data: areasOfStudy }] = useApi(AreasOfStudyApi.readAreasOfStudy());
  const [selected, setSelected] = useState<AreaOfStudy>(null);

  return (
    <Root data-test="MANAGE_AREAS_OF_STUDY">
      <List>
        <AdminModalAreasStudiesList
          areasOfStudy={areasOfStudy}
          selected={selected}
          onSelect={setSelected}
        />
      </List>
      <Form>
        {selected ? (
          <AdminModalAreasStudies
            areasOfStudy={areasOfStudy}
            selected={selected}
            onClose={() => setSelected(null)}
          />
        ) : (
          <Empty>
            <Text name="NO_AREA_OF_STUDY_SELECTED">
              Select an area of study.
            </Text>
          </Empty>
        )}
      </Form>
    </Root>
  );
};
