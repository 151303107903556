import React from 'react';
import { bool, string } from 'prop-types';

import Spinner from '../Spinner/Spinner';

import {
  LoadingCurtainCoverWrapper,
  LoadingCurtainSpinnerWrapper,
} from './LoadingCurtainStyles';

export const LoadingCurtain = ({ showSpinner, isFullscreen, loadingText }) => (
  <LoadingCurtainCoverWrapper isFullscreen={isFullscreen}>
    <LoadingCurtainSpinnerWrapper>
      <div>{!!showSpinner && <Spinner color="primary" />}</div>
      {loadingText}
    </LoadingCurtainSpinnerWrapper>
  </LoadingCurtainCoverWrapper>
);

LoadingCurtain.propTypes = {
  isFullscreen: bool,
  loadingText: string,
  showSpinner: bool,
};

LoadingCurtain.defaultProps = {
  loadingText: 'Loading...',
  showSpinner: true,
  isFullscreen: false,
};
