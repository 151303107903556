import styled, { css } from 'styled-components';
import { Label } from '@axiom/ui';

import { getThemeProp } from '../themes';
import { GENERAL_STYLES_MAP } from '../themes/constants';

export * from './filter-header';

export const withMarginBottomProp = ({ marginBottom }) =>
  marginBottom
    ? css`
        margin-bottom: ${marginBottom}px;
      `
    : '';

export const Footnote = styled.em`
  font-style: italic;
`;

export const LabelWrapper = styled(Label)`
  margin-bottom: 1rem;
`;

export const Tag = styled.span`
  background: ${props =>
    props.tagType === 'skills'
      ? GENERAL_STYLES_MAP.SKILLS.background
      : GENERAL_STYLES_MAP.AXIOM.background};
  border-radius: 7px;
  color: ${getThemeProp('contentBackground')};
  display: inline-block;
  font-weight: bold;
  line-height: 1.5;
  margin-right: 0.25em;
  padding: 3px 6px;
`;

export const TagRow = styled.span`
  display: block;
  ${withMarginBottomProp};
  ${props => (props.skillWidth ? 'padding-right: 63px;' : '')};

  ${Tag} {
    margin-bottom: 0.25em;
  }
`;

export const UnstyledButton = styled.button`
  background: none;
  border: 0;
  color: inherit;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  font: inherit;
  line-height: normal;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  overflow: visible;
  padding: 0;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: 2px solid transparent;
  }
`;

UnstyledButton.defaultProps = {
  type: 'button',
};

export const UnstyledOL = styled.ol`
  list-style: none;
  padding: 0;
`;

export const UnstyledUL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const TextCenter = styled.div`
  text-align: center;
`;
