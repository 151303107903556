import { UserConst } from '@axiom/const';
import { Api, UserDto } from '@axiom/ui';
import { User } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';
import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('user');
const { UserRoles, Expansions } = UserConst;

export const getLoggedUser = async () => {
  const data = await api.GET('/user/me', {
    expand: [Expansions.settingsInfo],
  });

  return data ? data.data : null;
};

export const getUsers = async (
  body = {
    roles: [UserRoles.EnvoyAdmin, UserRoles.EnvoyUser, UserRoles.EnvoySales],
  }
) => {
  const { data } = await api.GET('/users', body);
  return { data };
};

export const updateUser = async (user: User) => {
  const requestBody = new UserDto(user);
  const { data } = await api.PATCH(`/users/${user.id}`, requestBody.export());
  return { data };
};

export const getUserGroups = async (user: User) => {
  const { data } = await api.GET(`/users/${user.id}/groups`);
  return { data };
};

export const setUserGroups = async (
  user: User,
  groups: { isActive: boolean; roles: User['roles'] }
) => {
  const { data } = await api.PUT(`/users/${user.id}/groups`, groups);
  return { data };
};

export default {
  getUsers,
  updateUser,
  setUserGroups,
};

class UsersApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  getSessionUser() {
    return super.read<{ data: User }>({
      endpoint: '/user/me',
      method: 'GET',
      query: {
        expand: [Expansions.settingsInfo],
      },
      encodeQuery: false,
    });
  }

  refreshSessionUser() {
    return super.invalidate('/user/me');
  }

  getUserById(id: User['id']) {
    return super.read<{ data: User }>({
      endpoint: `/users/${id}/`,
      method: 'GET',
    });
  }
}

export const UsersApi = new UsersApiClass();
