// eslint-disable-next-line project-structure/folder-structure
import styled from 'styled-components';

import { getThemeProp } from '../themes';
import { HEADER_HEIGHT } from '../themes/constants';

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
`;

export const InnerPage = styled.div`
  background: ${getThemeProp('appBackground')};
  color: ${getThemeProp('primaryText')};
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const WorkArea = styled.div`
  margin: 8px 0 8px 20px;
  max-width: 100%;
  flex: 1;
  overflow-y: hidden;
`;

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const AppDetailWrapper = styled(AppWrapper)`
  overflow: hidden;
`;

export const DetailWrapper = styled.div`
  width: 100%;
  max-width: ${props => (props.wide ? 'unset' : '1200px')};
  padding: ${props => (props.wide ? '0 24px' : 'unset')};
  margin: 0 auto;
  height: calc(100% - ${HEADER_HEIGHT}px);
`;

export const DetailArea = styled.div`
  background-color: ${props =>
    props.showGreyBackground ? 'panelGray' : '#fff'};
  height: 100%;

  // necessary because the immediate child of this component
  // is a storeStateTreatment component
  > div {
    height: 100%;
  }
`;
