import React, { FC } from 'react';
import { Gutter, Layout, ParagraphHeader, Well } from '@axiom/ui';
import {
  Candidate,
  CandidateOpportunity,
  Opportunity,
  OpportunityCandidate,
  Taxonomy,
} from '@axiom/validation';

import { OpportunityTalentMatchChecklistItem } from './OpportunityTalentMatchChecklistItem';
import {
  OpportunityTalentMatchChecklistUtil,
  MatchChecklistItem,
} from './OpportunityTalentMatchChecklistUtil';

interface OpportunityTalentMatchChecklistProps {
  candidate: Candidate;
  opportunity: Opportunity;
  opportunityCandidate: OpportunityCandidate;
  candidateOpportunities: Array<CandidateOpportunity>;
  taxonomy: Taxonomy;
}

export const OpportunityTalentMatchChecklist: FC<
  OpportunityTalentMatchChecklistProps
> = ({
  candidate,
  opportunity,
  opportunityCandidate,
  candidateOpportunities,
  taxonomy,
}) => {
  const checklistItems: Array<MatchChecklistItem> = [
    OpportunityTalentMatchChecklistUtil.getAvailabilityHoursChecklistItem(
      candidate,
      opportunity,
      opportunityCandidate
    ),
    OpportunityTalentMatchChecklistUtil.getOpenToLowerEscItem(
      opportunityCandidate
    ),
    OpportunityTalentMatchChecklistUtil.getCrossFulfillmentChecklistItem(
      opportunity,
      candidateOpportunities
    ),
    OpportunityTalentMatchChecklistUtil.getEngagedHoursChecklistItem(
      candidate,
      opportunity,
      candidateOpportunities
    ),
    OpportunityTalentMatchChecklistUtil.getCountryChecklistItem(
      candidate,
      opportunity
    ),
    OpportunityTalentMatchChecklistUtil.getBarredToWorkOnsiteChecklistItem(
      candidate,
      opportunity,
      opportunityCandidate
    ),
    OpportunityTalentMatchChecklistUtil.getBarredToWorkRemotelyChecklistItem(
      candidate,
      opportunity,
      opportunityCandidate
    ),
    OpportunityTalentMatchChecklistUtil.getStateChecklistItem(
      candidate,
      opportunity
    ),
    OpportunityTalentMatchChecklistUtil.getPracticeAreaChecklistItem(
      candidate,
      opportunity
    ),
    OpportunityTalentMatchChecklistUtil.getIndustryChecklistItem(
      candidate,
      opportunity,
      taxonomy.industries
    ),
  ]
    .filter(Boolean)
    .sort((a, b) => +a.isMatched - +b.isMatched);

  return (
    <Well>
      <Layout name="checklist" direction="vertical" verticalGutter="4px">
        <Gutter bottom="8px">
          <ParagraphHeader>Match Checklist</ParagraphHeader>
        </Gutter>

        {checklistItems.map(({ id, isMatched, children }) => (
          <OpportunityTalentMatchChecklistItem
            key={id}
            id={id}
            isMatched={isMatched}
          >
            {children}
          </OpportunityTalentMatchChecklistItem>
        ))}
      </Layout>
    </Well>
  );
};
