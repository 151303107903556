import React from 'react';
import { z } from 'zod';
import {
  Button,
  Gutter,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalSection,
  Form,
} from '@axiom/ui';

import { CandidateFiltersForm as FiltersFormSchema } from '../../schema/candidate-filters-schema';

const formSchema = z.object({ name: z.string() });
export type FormSchema = z.infer<typeof formSchema>;

export const CandidateFiltersSaveFormModal = ({
  filters,
  handleSaveFilters,
  onClose,
}: {
  filters: FiltersFormSchema;
  handleSaveFilters: (
    saveFilters: FormSchema & { filters: FiltersFormSchema }
  ) => void;
  onClose: () => void;
}) => {
  return (
    <Form
      name="SAVE_CANDIDATE_FILTERS_FORM"
      schema={formSchema}
      onSubmit={async (formData: FormSchema) => {
        handleSaveFilters({ ...formData, filters });
        onClose();
      }}
    >
      {({ fireSubmit }) => (
        <Modal>
          <ModalHeader onClose={onClose}>Saved Search</ModalHeader>
          <ModalSection>
            <Gutter bottom="16px">
              Enter a search name as you want it to appear on the search Saved
              tab.
            </Gutter>
            <Input name="name" label="Search Name" />
          </ModalSection>
          <ModalFooter>
            <Button onClick={onClose} variation="outline">
              Cancel
            </Button>
            <Button onClick={fireSubmit}>Save</Button>
          </ModalFooter>
        </Modal>
      )}
    </Form>
  );
};
