import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  APP_REQUEST_INITIAL_DATA,
  APP_RECEIVE_INITIAL_DATA,
} from '../constants';
import {
  BANNER_MESSAGE_API_NAME,
  HOME_OFFICES_API_NAME,
  JOB_API_NAME as OPPORTUNITY_API_NAME,
  NOTIFICATIONS,
  TALENT_API_NAME,
} from '../../api/constants';
import { getBannerMessages } from '../../api/banner-messages-api';
import { getHomeOffices } from '../../api/home-offices-api';
import { getNotifications } from '../../api/notifications-api';
import {
  bannerMessagesLoaded,
  bannerMessagesLoadedError,
} from '../actions/banner-messages';
import { opportunitiesError } from '../actions/opportunities';
import {
  homeOfficesLoaded,
  homeOfficesLoadedError,
} from '../actions/home-offices';
import {
  getNotificationsSuccess,
  getNotificationsError,
} from '../actions/notifications';
import { talentError } from '../actions/talent';
import { addApiError } from '../actions/app';
import { PreloadedBarredLocationsStore } from '../../stores/preloaded-barred-locations-store';
import { PreloadedFeatureFlagsStore } from '../../stores/preloaded-feature-flags-store';
import { PreloadedGroupsStore } from '../../stores/preloaded-groups-store';
import { PreloadedLanguagesStore } from '../../stores/preloaded-languages-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { PreloadedTagsStore } from '../../stores/preloaded-tags-store';
import { PreloadedUsersStore } from '../../stores/preloaded-users-store';
import { PreloadedLawSchoolRanksStore } from '../../stores/preloaded-law-school-ranks-store';
import { PreloadedCandidateListFilters } from '../../stores/preloaded-candidate-list-filters';

const errorActions = {
  [BANNER_MESSAGE_API_NAME]: bannerMessagesLoadedError,
  [HOME_OFFICES_API_NAME]: homeOfficesLoadedError,
  [OPPORTUNITY_API_NAME]: opportunitiesError,
  [NOTIFICATIONS]: getNotificationsError,
  [TALENT_API_NAME]: talentError,
};

export function* initialAppDataSagaGenerator(props) {
  const {
    payload: { id, firstName, lastName, email, roles },
  } = props;

  try {
    const [bannerMessages, homeOffices, notifications] = yield all([
      call(getBannerMessages, 'envoy'),
      call(getHomeOffices),
      call(getNotifications, {
        userId: id,
        page: 1,
      }),
    ]);
    yield put(bannerMessagesLoaded(bannerMessages));
    yield put(homeOfficesLoaded(homeOffices));
    yield put(getNotificationsSuccess({ notifications, userId: id }));

    PreloadedFeatureFlagsStore.bootstrap({
      id,
      firstName,
      lastName,
      email,
      roles,
    });

    Promise.all([
      PreloadedBarredLocationsStore.load(),
      PreloadedFeatureFlagsStore.load(),
      PreloadedGroupsStore.load(),
      PreloadedLanguagesStore.load(),
      PreloadedPracticeAreasStore.load(),
      PreloadedTagsStore.load(),
      PreloadedUsersStore.load(),
      PreloadedLawSchoolRanksStore.load(),
      PreloadedCandidateListFilters.load(),
    ]);

    yield put({ type: APP_RECEIVE_INITIAL_DATA });
  } catch (e) {
    const appErr = e && e.applicationError ? e.applicationError : e;
    yield put(addApiError(appErr));
    if (appErr && appErr.apiName && errorActions[appErr.apiName]) {
      yield put(errorActions[appErr.apiName](e));
    }
  }
}

function* formsSaga() {
  yield takeLatest(APP_REQUEST_INITIAL_DATA, initialAppDataSagaGenerator);
}

export default formsSaga;
