import { Api, CandidateOpportunitiesDto } from '@axiom/ui';
import { Candidate, CandidateOpportunity } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';
import LegacyApiHelper from '../classes/legacy-api-helper';

/**
 * This is displayed to user so needs to be talent, not candidate
 */
const api = new LegacyApiHelper('talent opportunity');

export const getOpportunitiesByTalentId = async (
  candidateId: Candidate['id'],
  queryParams = {}
) => {
  const response = await api.GET(
    `/candidates/${candidateId}/opportunities`,
    queryParams
  );
  return { data: response.data };
};

export const postOpportunitiesByTalentId = async ({
  candidateId,
  opportunityIds,
}: {
  candidateId: Candidate['id'];
  opportunityIds: CandidateOpportunity['opportunityId'][];
}) => {
  const { data } = await api.POST(`/candidates/${candidateId}/opportunities`, [
    // Spreading this as it is a Set. Will still be safe if its an array
    ...opportunityIds,
  ]);
  return { data };
};

export const updateOpportunityStatusByTalent = async ({
  opportunityId,
  candidateId,
  candidateStatus,
  candidateStatusMessage,
  rejectionLossCode,
}: {
  opportunityId: CandidateOpportunity['opportunityId'];
  candidateId: Candidate['id'];
  candidateStatus: Candidate['profileStatus'];
  candidateStatusMessage: string;
  rejectionLossCode: string;
}) => {
  const payload = {
    candidateStatus,
    candidateStatusMessage,
    rejectionLossCode,
  };
  const requestBody = new CandidateOpportunitiesDto(payload);

  const { data } = await api.PATCH(
    `/candidates/${candidateId}/opportunities/${opportunityId}`,
    requestBody.export()
  );
  return { data };
};

class TalentOpportunitiesApiClass extends Api {
  constructor() {
    super({ domain: EnvUtil.clientApiBase });
  }

  readCandidateOpportunities(candidateId: Candidate['id'], query = {}) {
    return super.read<{ data: CandidateOpportunity[] }>({
      endpoint: `/candidates/${candidateId}/opportunities`,
      method: 'GET',
      query,
    });
  }
}

export const TalentOpportunitiesApi = new TalentOpportunitiesApiClass();
