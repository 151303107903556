import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { AppFindContactForAccountModalStore } from '../../stores/app-find-contact-for-account-modal-store';
import TypeaheadModal from '../TypeaheadModal/TypeaheadModal';

import { StyledListButton } from './AppFindContactForAccountModalStyles';

const AppFindContactForAccountModal = ({ findContactState }) => {
  const [confirmContactId, setConfirmContactId] = useState(null);

  const onSelect = (storeData, contactId) => {
    if (confirmContactId) {
      setConfirmContactId(null);
      AppFindContactForAccountModalStore.submit(storeData, contactId);
    } else {
      setConfirmContactId(contactId);
    }
  };

  const onCancel = () => {
    setConfirmContactId(null);
    AppFindContactForAccountModalStore.clearState();
  };

  return (
    <StoreStateTreatment
      isModalState
      showInitialLoader={false}
      showRefetchLoader={false}
      name="FIND_CONTACT_FOR_ACCOUNT_STATE"
      storeState={findContactState}
      renderLoadedView={({ data }) => {
        return (
          <>
            <TypeaheadModal
              storeState={findContactState}
              onSearch={(searchTerm, page) =>
                AppFindContactForAccountModalStore.findContacts(searchTerm, {
                  ...data,
                  page,
                  results: page === 1 ? [] : data.results,
                })
              }
              onCancel={onCancel}
              totalResultsCount={data.totalResultsCount}
              itemRender={contact => (
                <StyledListButton
                  key={data.id}
                  type="button"
                  onClick={() => {
                    onSelect(data, contact.id);
                  }}
                >
                  <p className="search-result">
                    {contact.firstName} {contact.lastName}
                  </p>
                  <p className="search-result">{contact.email}</p>
                </StyledListButton>
              )}
              title="Add Associated User"
              searchTerm={data.searchTerm}
              results={data.results}
              page={data.page}
            />
            {!!confirmContactId && (
              <Modal size="confirm">
                <ModalHeader onClose={onCancel}>Assign Contact</ModalHeader>
                <ModalSection>
                  <Paragraph>
                    Are you sure you would like to assign this contact to this
                    account?
                  </Paragraph>
                </ModalSection>
                <ModalFooter>
                  <Button variation="outline" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button onClick={() => onSelect(data, confirmContactId)}>
                    Assign
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </>
        );
      }}
    />
  );
};

AppFindContactForAccountModal.propTypes = {
  findContactState:
    AppFindContactForAccountModalStore.getStateShape().isRequired,
};

export default connect(state => ({
  findContactState: AppFindContactForAccountModalStore.select(state),
}))(AppFindContactForAccountModal);
