import { all } from 'redux-saga/effects';

import bannerMessagesSaga from './banner-messages';
import candidateCollaborators from './candidate-collaborators';
import decoratedOpportunitySaga from './decorated-opportunity';
import decoratedTalentSaga from './decorated-talent';
import educationSaga from './education';
import formsSaga from './forms';
import groupsSaga from './groups';
import homeOfficesSaga from './home-offices';
import initialDataSaga from './initial-application-data';
import opportunityCandidates from './opportunity-candidates';
import opportunityCollaborators from './opportunity-collaborators';
import opportunitiesSaga from './opportunities';
import searchesSaga from './searches';
import notificationsSaga from './notifications';
import talentSaga from './talent';
import userOpportunities from './user-opportunities';
import usersSaga from './users';
import userTalent from './user-talent';

export default function* rootSaga() {
  yield all([
    bannerMessagesSaga(),
    candidateCollaborators(),
    decoratedOpportunitySaga(),
    decoratedTalentSaga(),
    educationSaga(),
    formsSaga(),
    groupsSaga(),
    homeOfficesSaga(),
    initialDataSaga(),
    opportunityCandidates(),
    opportunityCollaborators(),
    opportunitiesSaga(),
    searchesSaga(),
    notificationsSaga(),
    talentSaga(),
    userOpportunities(),
    usersSaga(),
    userTalent(),
  ]);
}
