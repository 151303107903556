import React from 'react';
import { ContextMenuItem } from '@axiom/ui';

import { WorkspaceFilterKeys, WorkspaceFilters } from './WorkspaceTypes';

export const WorkspaceContextMenuItems = ({
  filters,
  setFilters,
  values,
  updateKey,
}: {
  filters: WorkspaceFilters;
  setFilters: (newFilters: WorkspaceFilters) => void;
  values: Array<string>;
  updateKey: WorkspaceFilterKeys;
}) => {
  return (
    <>
      {values.map(value => (
        <ContextMenuItem
          name={`${value.replace(' ', '_').toUpperCase()}_FILTER_OPTION`}
          key={value}
          onClick={() => {
            const changedData = { ...filters, [updateKey]: value };
            setFilters(changedData);
          }}
        >
          {value}
        </ContextMenuItem>
      ))}
    </>
  );
};
