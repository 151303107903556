import React, { Component } from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import { ExperiencePlaceholderIcon, IconButton } from '@axiom/ui';

import NoContentPlaceholder from '../NoContentPlaceholder/NoContentPlaceholder';
import {
  LogContainer,
  LogHeader,
  LogHeaderLeft,
  LogHeaderRight,
  LogItems,
} from '../../styled/logs';
import { experienceShape } from '../../models/experience';
import { formatDataTestId } from '../../utils/data-test-util';
import { FormTalentExperienceStore } from '../../stores/form-talent-experience-store';
import { WindowUtil } from '../../utils/window-util';

import { CandidateExperienceUtil } from './candidate-experience-util';
import CandidateExperienceItem from './CandidateExperienceItem';

class CandidateExperienceList extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    expanded: {},
    expandedAll: false,
  };

  componentDidUpdate(prevProps) {
    this.expandSearchMatchedExperiences(prevProps);
  }

  expandSearchMatchedExperiences(prevProps) {
    const { experiences: prevExperiences } = prevProps;
    const { experiences: currExperiences } = this.props;

    if (prevExperiences !== currExperiences) {
      this.setState(state => {
        const expanded = {
          ...state.expanded,
          ...Object.values(currExperiences).reduce(
            (expandedOverrides, experience) => {
              if (
                WindowUtil.getUrlQuery().search &&
                CandidateExperienceUtil.doesExperienceContainSearch(
                  experience,
                  WindowUtil.getUrlQuery().search
                )
              ) {
                expandedOverrides[experience.id] = true;
              }
              return expandedOverrides;
            },
            {}
          ),
        };
        const expandedAll =
          currExperiences.length > 0 &&
          currExperiences.every(e => expanded[e.id]);

        return {
          expanded,
          expandedAll,
        };
      });
    }
  }

  openAddExperienceForm = () => {
    FormTalentExperienceStore.load(this.props.candidateId, {});
  };

  toggleAllExpanded = () => {
    this.setState(state => {
      const { experiences } = this.props;
      const expandedAll = experiences.length > 0 && !state.expandedAll;
      const expanded = experiences.reduce((expand, experience) => {
        expand[experience.id] = expandedAll;
        return expand;
      }, {});

      return {
        expandedAll,
        expanded,
      };
    });
  };

  toggleItemExpanded = id => {
    this.setState(state => {
      const { experiences } = this.props;

      const expanded = {
        ...state.expanded,
        [id]: !state.expanded[id],
      };
      const expandedAll =
        experiences.length > 0 && experiences.every(e => expanded[e.id]);

      return {
        expanded,
        expandedAll,
      };
    });
  };

  render() {
    const { expanded, expandedAll } = this.state;
    const { canAddNew, experiences, title, taxonomy } = this.props;

    return (
      <LogContainer data-test={formatDataTestId(title)}>
        <LogHeader>
          <LogHeaderLeft>{title}</LogHeaderLeft>
          <LogHeaderRight>
            {!!canAddNew && (
              <IconButton
                icon="plus"
                pattern="secondary"
                variation="minimal"
                name="PRIOREXPERIENCEPLUSICON"
                onClick={this.openAddExperienceForm}
              />
            )}
            <IconButton
              icon={expandedAll ? 'collapse-all' : 'expand-all'}
              pattern="secondary"
              variation="minimal"
              name="EXPANDALLBUTTON"
              onClick={this.toggleAllExpanded}
            />
          </LogHeaderRight>
        </LogHeader>

        {experiences.length > 0 ? (
          <LogItems>
            {experiences.map(experience => (
              <li key={experience.id}>
                <CandidateExperienceItem
                  expanded={expanded[experience.id]}
                  experience={experience}
                  form={experience.id}
                  toggleExpanded={this.toggleItemExpanded}
                  initialValues={{
                    ...experience,
                  }}
                  taxonomy={taxonomy}
                />
              </li>
            ))}
          </LogItems>
        ) : (
          <NoContentPlaceholder
            image={<ExperiencePlaceholderIcon />}
            title="No Experience"
            text={
              canAddNew ? 'Press the add button to enter other experience' : ''
            }
          />
        )}
      </LogContainer>
    );
  }
}

CandidateExperienceList.defaultProps = {
  canAddNew: false,
  experiences: [],
};

CandidateExperienceList.propTypes = {
  canAddNew: bool,
  candidateId: string.isRequired,
  experiences: arrayOf(experienceShape),
  taxonomy: shape({ industries: shape({}) }).isRequired,
  title: node.isRequired,
};

export default CandidateExperienceList;
