import React, { useState } from 'react';
import { ContextMenu, ContextMenuItem } from '@axiom/ui';

import { OpportunityStore } from '../../stores/opportunity-store';
import ManageClientsModal from '../ManageClientsModal/ManageClientsModal';
import { AccountStore } from '../../stores/account-store';
import {
  generateBenchUrl,
  generateAFCProjectsMatchesUrl,
} from '../../utils/link-util';

const ManageAccountContextMenu = ({ opportunity }) => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  return (
    <>
      <ContextMenu
        name="OPPORTUNITY_CONTEXT_MENU_MANAGE_ACCOUNT"
        direction="left"
      >
        <ContextMenuItem
          onClick={() => {
            if (opportunity.accountId) {
              AccountStore.load(opportunity.accountId);
            }
            setShowModal(true);
          }}
        >
          Manage Account
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            window.open(
              generateAFCProjectsMatchesUrl(
                opportunity.accountId,
                opportunity.submissionId
              )
            );
          }}
        >
          Open AFC Project
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            window.open(generateBenchUrl(opportunity.accountId));
          }}
        >
          Open AFC Bench
        </ContextMenuItem>
      </ContextMenu>
      {!!showModal && <ManageClientsModal onClose={closeModal} />}
    </>
  );
};

ManageAccountContextMenu.propTypes = {
  opportunity: OpportunityStore.getDataShape().isRequired,
};

export default ManageAccountContextMenu;
