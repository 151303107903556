import React, { useState } from 'react';
import { Field } from 'react-final-form';
import {
  Button,
  DataTest,
  Gutter,
  Header3,
  Header5,
  Layout,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { FFDropdown } from '../FFDropdown/FFDropdown';
import {
  USER_STATUSES_MAP,
  USER_STATUS_ACTIVE,
  userStatusOptions,
  userTypeOptions,
} from '../../models/user';
import {
  USER_FIELD_EMAIL,
  USER_FIELD_FIRST_NAME,
  USER_FIELD_HOME_OFFICE,
  USER_FIELD_IS_ACTIVE,
  USER_FIELD_LAST_NAME,
  USER_FIELD_ROLES,
  userFieldLabels as labels,
} from '../../models/user-fields-const';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { FormUserManagementStore } from '../../stores/form-user-management-store';

import { Row, Column, FormHeader, Text } from './UserManagementFormStyles';

export const UserManagementFormComponent = ({ user }) => {
  const [shouldConfirmDeactivation, setShouldConfirmDeactivation] =
    useState(false);

  return (
    <FormStateTreatment
      formStore={FormUserManagementStore}
      renderLoadedView={({ formData, fireSubmit }) => {
        const handleConfirmDeactivation = () => {
          setShouldConfirmDeactivation(false);
          fireSubmit();
        };

        const handleDismissConfirmation = () =>
          setShouldConfirmDeactivation(false);

        const handleSubmit = event => {
          event.preventDefault();

          const wasActive = user && user[USER_FIELD_IS_ACTIVE];
          const isActive =
            formData[USER_FIELD_IS_ACTIVE] ===
            USER_STATUSES_MAP[USER_STATUS_ACTIVE];
          if (wasActive && !isActive) {
            setShouldConfirmDeactivation(true);
          } else {
            handleConfirmDeactivation();
          }
        };

        return (
          <DataTest value="userManagementForm">
            <FormHeader>
              <Header3>
                {`${user[USER_FIELD_FIRST_NAME]} ${user[USER_FIELD_LAST_NAME]}`}
              </Header3>
            </FormHeader>
            <Row divider>
              <Column>
                <Header5>{labels[USER_FIELD_IS_ACTIVE]}</Header5>
              </Column>
              <Column>
                <Field
                  name={USER_FIELD_IS_ACTIVE}
                  component={FFDropdown}
                  options={userStatusOptions}
                />
              </Column>
            </Row>
            <Row divider>
              <Column>
                <Header5>{labels[USER_FIELD_HOME_OFFICE]}</Header5>
              </Column>
              <Column>
                <Text>No data available</Text>
              </Column>
            </Row>
            <Row divider>
              <Column>
                <Header5>{labels[USER_FIELD_ROLES]}</Header5>
              </Column>
              <Column>
                <Field
                  name={USER_FIELD_ROLES}
                  component={FFDropdown}
                  options={userTypeOptions}
                />
              </Column>
            </Row>
            <Row divider>
              <Column>
                <Header5>{labels[USER_FIELD_EMAIL]}</Header5>
              </Column>
              <Column>
                <Text>{user[USER_FIELD_EMAIL] || 'No data available'}</Text>
              </Column>
            </Row>

            <Gutter vertical="24px" horizontal="24px">
              <Layout position="right">
                <Button onClick={handleSubmit}>Save</Button>
              </Layout>
            </Gutter>
            {shouldConfirmDeactivation ? (
              <Modal size="confirm">
                <ModalHeader onClose={handleDismissConfirmation}>
                  User Status
                </ModalHeader>
                <ModalSection>
                  <Paragraph>
                    You have selected to deactivate this User. Doing so will
                    restrict their access to Envoy. Please make sure to reassign
                    any records owned by this user.
                  </Paragraph>
                </ModalSection>
                <ModalFooter>
                  <Button
                    variation="outline"
                    onClick={handleDismissConfirmation}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleConfirmDeactivation}>Save</Button>
                </ModalFooter>
              </Modal>
            ) : null}
          </DataTest>
        );
      }}
    />
  );
};

UserManagementFormComponent.propTypes = {
  user: PreloadedUserStore.getDataShape().isRequired,
};

export default UserManagementFormComponent;
