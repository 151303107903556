/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { bool, number, node, string } from 'prop-types';

import InputCharRemaining from '../InputCharRemaining/InputCharRemaining';
import { formatDataTestId } from '../../utils/data-test-util';
import { FFError, isErroring } from '../FFError/FFError';

import { StyledFFTextArea } from './FFTextAreaStyles';

export const FFTextArea = ({
  input,
  meta,
  name,
  maxLength,
  placeholder,
  disabled,
  showCharsRemainingLabel,
  placeholderComponent,
  ...rest
}) => {
  const value = input.value || '';
  const showPlaceholder = !value && disabled && placeholderComponent;
  return showPlaceholder ? (
    placeholderComponent
  ) : (
    <>
      <StyledFFTextArea
        name={name}
        invalid={isErroring(meta)}
        data-test={formatDataTestId(
          (name || 'text area') + (disabled ? '_disabled' : '')
        )}
        type={rest.type || 'textarea'}
        disabled={disabled}
        {...input}
        {...rest}
        maxLength={maxLength}
        value={value}
        placeholder={placeholder}
        onChange={event => input.onChange(event.target.value)}
        showCharsRemainingLabel={!disabled && showCharsRemainingLabel}
        errorMessageNode={<FFError finalFormElementMeta={meta} /> || null}
      />
      {!disabled && !!maxLength && (
        <InputCharRemaining
          maxLength={maxLength}
          value={value}
          showCharsRemainingLabel={showCharsRemainingLabel}
        />
      )}
    </>
  );
};

FFTextArea.defaultProps = {
  name: null,
  parentValue: null,
  disabled: false,
  placeholder: null,
  showCharsRemainingLabel:
    InputCharRemaining.defaultProps.showCharsRemainingLabel,
  maxLength: InputCharRemaining.defaultProps.maxLength,
  placeholderComponent: null,
};

FFTextArea.propTypes = {
  disabled: bool,
  maxLength: number,
  name: string,
  parentValue: string,
  placeholder: string,
  placeholderComponent: node,
  showCharsRemainingLabel: bool,
  ...StyledFFTextArea.propTypes,
};
