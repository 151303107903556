import React, { FC, PropsWithChildren } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ApiError,
  Badge,
  Brief,
  Card,
  CardHeader,
  CardSection,
  CondensedLabel,
  CondensedMedium,
  Flashy,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  SmallHeader,
  Suspense,
  useApiWithErrors,
  Well,
} from '@axiom/ui';
import {
  CandidateOpportunitiesConst,
  DisplayPronouns,
  EventsConst,
} from '@axiom/const';
import { OpportunityCandidate } from '@axiom/validation';

import { LegacyTalentApi } from '../../api/talent-api';
import { DateUtil } from '../../utils/date-util';
import { OpportunityTalentMatchChecklist } from '../OpportunityTalentMatchChecklist/OpportunityTalentMatchChecklist';
import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';
import { TalentActivitiesApi } from '../../api/talent-activities-api';
import { TalentOpportunitiesApi } from '../../api/talent-opportunities-api';
import { TaxonomyApi } from '../../api/taxonomy-api';

import { OTFlex, OTTalentSubmissionNote } from './OpportunityTalentStyles';
import { OpportunityTalentCandidateContextMenu } from './OpportunityTalentCandidateContextMenu';
import { OpportunityTalentRateMargin } from './OpportunityTalentRateMargin';

const {
  AllStatusLabels,
  CandidateStatuses,
  CandidateRejectionLossCodesLabels,
} = CandidateOpportunitiesConst;

const allLabels: Record<string, string> = AllStatusLabels;

const lossCodeLabels: Record<string, string> =
  CandidateRejectionLossCodesLabels;

export const OpportunityTalentCandidate: FC<PropsWithChildren> = ({
  children,
}) => {
  const { id: opportunityId, viewId: candidateId } = useParams();
  const navigate = useNavigate();

  const requests = useApiWithErrors(
    LegacyTalentApi.readCandidate(candidateId),
    OpportunityLegacyApi.readOpportunityCandidates(opportunityId, true),
    TalentActivitiesApi.readActivities(candidateId),
    OpportunityLegacyApi.readOpportunity(opportunityId),
    TalentOpportunitiesApi.readCandidateOpportunities(candidateId),
    TaxonomyApi.readTaxonomy()
  );

  const [
    { data: candidate },
    { data: opportunityCandidates },
    { data: candidateEvents },
    { data: opportunity },
    { data: candidateOpportunities },
    { data: taxonomy },
  ] = requests;

  const redirect = () =>
    navigate(`/opportunity-detail/${opportunityId}/talent`);

  if (requests.some(d => d instanceof ApiError)) {
    redirect();
    // @ts-expect-error Needs new type
    return;
  }

  const opportunityCandidate: OpportunityCandidate =
    opportunityCandidates?.find(co => co.candidateId === candidateId);

  if (opportunityCandidates && !opportunityCandidate) {
    redirect();
    // @ts-expect-error Needs new type
    return;
  }

  let talentSubmissionNote: string;
  const sortedEvents = candidateEvents
    .sort(
      (a, b) =>
        new Date(b.updatedAt).getDate() - new Date(a.updatedAt).getDate()
    )
    .filter(event => {
      return (
        event.purpose === EventsConst.Purposes.ExpressedInterest &&
        event.opportunityId === opportunityCandidate.opportunityId
      );
    });

  if (sortedEvents.length > 0 && sortedEvents[0].description) {
    talentSubmissionNote = sortedEvents[0].description;
  }

  let passReason: string;
  if (
    opportunityCandidate.candidateStatus === CandidateStatuses.Rejected &&
    opportunityCandidate.rejectionLossCode
  ) {
    passReason = lossCodeLabels[opportunityCandidate.rejectionLossCode];
  }

  return (
    <Suspense>
      <Card name="opportunity-talent-candidate">
        <CardHeader background="blue">
          <Layout direction="horizontal" position="space-between">
            <Layout position="left middle" horizontalGutter="16px">
              <SmallHeader>{candidate.calculatedDisplayName}</SmallHeader>
              {candidate.pronouns && (
                <Paragraph name="OPPORTUNITY_TALENT_PRONOUNS">
                  {DisplayPronouns[candidate.pronouns]}
                </Paragraph>
              )}
              <Flashy color="textDefault">
                <Layout position="left middle" horizontalGutter="10px">
                  {!!candidate.isFlagged && (
                    <Badge background="light orange">Flagged</Badge>
                  )}
                  <Badge background="gray">{candidate.profileStatus}</Badge>
                </Layout>
              </Flashy>
            </Layout>
            <OpportunityTalentCandidateContextMenu
              candidate={candidate}
              opportunityCandidate={opportunityCandidate}
              opportunity={opportunity}
            />
          </Layout>
        </CardHeader>

        <CardSection>
          <Layout direction="vertical" verticalGutter="16px">
            <Layout
              direction="horizontal"
              horizontalGutter="16px"
              position="stretch center"
            >
              <OTFlex fullHeight>
                <OpportunityTalentMatchChecklist
                  candidate={candidate}
                  opportunity={opportunity}
                  opportunityCandidate={opportunityCandidate}
                  candidateOpportunities={candidateOpportunities}
                  taxonomy={taxonomy}
                />
              </OTFlex>
              <OTFlex fullHeight>
                <Well>
                  <ParagraphHeader>Talent Summary Bio</ParagraphHeader>
                  <Brief briefMaxHeight="120px" pattern="secondary">
                    {candidate.candidateSummary?.length > 0 ? (
                      <CondensedMedium>
                        {candidate.candidateSummary}
                      </CondensedMedium>
                    ) : (
                      <CondensedLabel>Talent has no summary bio</CondensedLabel>
                    )}
                  </Brief>
                  {!!talentSubmissionNote && (
                    <Gutter top="16px">
                      <ParagraphHeader>Talent Submission Note</ParagraphHeader>
                      <Brief briefMaxHeight="20px" pattern="secondary">
                        <CondensedMedium>
                          <OTTalentSubmissionNote>
                            {talentSubmissionNote}
                          </OTTalentSubmissionNote>
                        </CondensedMedium>
                      </Brief>
                    </Gutter>
                  )}
                </Well>
              </OTFlex>
            </Layout>
            <Well>
              <Layout position="stretch space-between">
                <OTFlex>
                  <Layout direction="horizontal" horizontalGutter="6px">
                    <LayoutItem>
                      <CondensedMedium maxLines={1}>
                        <Flashy bold>Rate & margin:</Flashy>
                      </CondensedMedium>
                    </LayoutItem>
                    <LayoutItem>
                      <OpportunityTalentRateMargin
                        opportunityCandidate={opportunityCandidate}
                        opportunity={opportunity}
                      />
                    </LayoutItem>
                  </Layout>
                </OTFlex>
                <OTFlex>
                  <CondensedMedium>
                    <Flashy bold>Previous status:</Flashy>{' '}
                    {allLabels[opportunityCandidate.previousCandidateStatus]}
                  </CondensedMedium>
                </OTFlex>
                <OTFlex>
                  <CondensedMedium>
                    <Flashy bold>Status last updated:</Flashy>{' '}
                    {DateUtil.displayDate(
                      opportunityCandidate.candidateStatusUpdatedAt
                    )}
                  </CondensedMedium>
                </OTFlex>
              </Layout>
              {passReason?.length > 0 && (
                <CondensedMedium>
                  <Flashy bold>Client-Pass:</Flashy> {passReason}
                </CondensedMedium>
              )}
            </Well>
            {children}
          </Layout>
        </CardSection>
      </Card>
    </Suspense>
  );
};
