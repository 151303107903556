import { bool, number, shape, string } from 'prop-types';

import { opportunityShape } from './opportunities-const';

export const experienceShape = shape({
  candidateId: string,
  client: string,
  description: string,
  endDate: string,
  externalOpportunityName: string,
  id: string,
  isAxiom: bool,
  isConfidential: bool,
  locationAddressComponents: shape({}),
  locationLatitude: number,
  locationLongitude: number,
  locationName: string,
  locationPlaceId: string,
  opportunity: opportunityShape,
  opportunityId: string,
  startDate: string,
});
