import React from 'react';
import { func, bool } from 'prop-types';
import { Field } from 'react-final-form';
import {
  Gutter,
  DataTest,
  InfoIcon,
  Button,
  FluidButtonLayout,
} from '@axiom/ui';

import { FFInput } from '../FFInput/FFInput';
import { FFCheckbox } from '../FFCheckbox/FFCheckbox';

import {
  ManageAccountsConfidentialAccount,
  ManageAccountsConfidentialAccountPublicDisplayNameLabel,
  ManageAccountsColumn,
} from './ManageAccountsConfidentialStyles';

export const ManageAccountsConfidentialItem = ({
  onValueChange,
  fireSubmit,
  isInputDisabled,
}) => {
  return (
    <ManageAccountsConfidentialAccount divider>
      <ManageAccountsColumn>
        <Gutter top="2rem">
          <Field
            name="isAccountConfidential"
            id="isAccountConfidential"
            label="Confidential Account"
            component={FFCheckbox}
            type="checkbox"
            onValueChange={onValueChange}
          />
        </Gutter>
      </ManageAccountsColumn>
      <ManageAccountsColumn className="text-align-right">
        <Field
          name="confidentialAccountName"
          disabled={isInputDisabled}
          id="confidentialAccountName"
          label={
            <ManageAccountsConfidentialAccountPublicDisplayNameLabel>
              <span>Public Display Name</span>
              <DataTest value="PUBLIC_DISPLAY_NAME_LABEL_INFO" inline>
                <InfoIcon />
              </DataTest>
            </ManageAccountsConfidentialAccountPublicDisplayNameLabel>
          }
          showCharsRemainingLabel={false}
          component={FFInput}
        />
        <Gutter bottom="16px" />
        <FluidButtonLayout>
          <Button name="SAVE_CONFIDENTIAL_ACCOUNT" onClick={fireSubmit}>
            Save
          </Button>
        </FluidButtonLayout>
      </ManageAccountsColumn>
    </ManageAccountsConfidentialAccount>
  );
};

ManageAccountsConfidentialItem.propTypes = {
  fireSubmit: func.isRequired,
  isInputDisabled: bool.isRequired,
  onValueChange: func.isRequired,
};
