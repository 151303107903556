import { Api } from '@axiom/ui';
import { HomeOffice } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';
import LegacyApiHelper from '../classes/legacy-api-helper';

const api = new LegacyApiHelper('home office');

export const getHomeOffice = async (homeOfficeId: string) => {
  const response = await api.GET(`/homeOffices/${homeOfficeId}`);
  return {
    data: response.data,
  };
};

export const getHomeOffices = async () => {
  const { data } = await api.GET('/homeOffices');
  return {
    data,
  };
};

class HomeOfficesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readHomeOffices() {
    return super.read<{ data: Array<HomeOffice> }>({
      endpoint: '/homeOffices',
      method: 'GET',
    });
  }
}

export const HomeOfficeApi = new HomeOfficesApiClass();

export default {
  getHomeOffice,
  getHomeOffices,
};
